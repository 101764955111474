import React, { useEffect } from "react"
import * as actions from "../../../../redux/actions/liquidAction"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Card, CardBody } from 'reactstrap';
import LiquidTools from './LiquidTools';
import LiquidList from './LiquidList';

const LiquidsPage = ({
  liquids,
  loadLiquids,
  deleteLiquid,
  history
}) => {

  useEffect(() => {
    loadLiquids();
  }, [])

  function handleDelete(liquidId) {
    return  deleteLiquid(liquidId)
      .then(() => toast.success("Płyn został usunięty"))
  }
  
  return ( <>
      <LiquidTools/>
      <Card>
        <CardBody>
          <LiquidList
            liquids={liquids}
            onDelete={handleDelete}
            history={history}
          />
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = state => ({
  liquids: state.liquids
})

const mapDispatchToProps = dispatch => {
  return {
    loadLiquids: bindActionCreators(actions.loadLiquids, dispatch),
    deleteLiquid: bindActionCreators(actions.deleteLiquid, dispatch)
  }
}

LiquidsPage.propTypes = {
  liquids: PropTypes.array.isRequired,
  loadLiquids: PropTypes.func.isRequired,
  deleteLiquid: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiquidsPage);
