import React, { useState, useEffect } from 'react'
import { PropTypes } from "prop-types"
import { withRouter } from 'react-router-dom';
import { Button, Card, CardBody, Input, Label, ListGroup, ListGroupItem, Table } from 'reactstrap';
import TdAmount from '../../../common/TdAmount';
import DeleteButton from '../../../common/DeleteButton';
import GridEditButton from '../../../common/GridEditButton';

const OrderList = ({
  orders,
  onDelete,
  onClose,
  history
}) => {

  const [filterNumber, setFilterNumber] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    setFilteredOrders([...orders]);
  }, [orders])

  const handleFilter = event => {
    const { value } = event.target;
    setFilterNumber(value);
    setFilteredOrders(orders.filter(o => o.number.includes(value)));
  }

  const getStatus = value => {
    switch (value) {
      case "New":
        return "Nowy";
      case "InProgress":
        return "W trakcie";
      case "Done":
        return "Zrealizowany";
      default:
        return "Nowy";
    }
  }

  return <Card>
    <CardBody>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Label style={{ width: 160 }}>Numer zlecenia:</Label>
        <Input onChange={handleFilter} value={filterNumber}/>
      </div>
      <hr/>
      <Table>
        <thead>
        <tr style={{ background: "#787d80", color: "white" }}>
          <th width={300}>Płyn</th>
          <th width={300}>Mechanik</th>
          <th width={100}>Ilość</th>
          <th width={100}>Status</th>
          <th width={120}/>
        </tr>
        </thead>
      </Table>
      <ListGroup>
        { filteredOrders.map(order => {
          return (
            <div key={order.id}>
              <ListGroupItem color="primary">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    Numer:&nbsp;&nbsp;<b>{order.number}</b>
                  </div>
                  <div  style={{ marginLeft: "auto" }}>
                    <Button
                      color="success"
                      onClick={() => history.push("/order/" + order.number)}
                      style={{marginRight: 15}}
                    >
                      <i className="fa fa-plus" style={{paddingRight: 8}}/>
                      Dodaj pozycję
                    </Button>
                    <Button
                      color="warning"
                      onClick={() => onClose(order.id)}
                    >
                      <i className="fa fa-lock" style={{paddingRight: 8}}/>
                      Zamknij
                    </Button>
                  </div>
                </div>
              </ListGroupItem>
              <Table hover>
                <tbody>
                {order.order_lines.map(line => {
                  return (
                    <tr key={line.id}>
                      <td width={300}>{line.liquid.name}</td>
                      <td width={300}>
                        {line.mechanic.id === 0
                          ? "<dowolny>"
                          : line.mechanic.last_name + " " + line.mechanic.first_name
                        }
                      </td>
                      <TdAmount amount={line.amount}/>
                      <td width={100}>{ getStatus(line.status) }
                      </td>
                      <td width={120}>
                        <GridEditButton
                          id={line.id}
                          url={"/order/" + order.number}
                          history={history}
                        />
                        &nbsp;
                        <DeleteButton
                          onDelete={()=>onDelete(line.id)}
                          deletingItemId={line.id}
                        />
                      </td>
                    </tr>
                  )
                })}
                </tbody>
              </Table>
            </div>
          )
        })}
      </ListGroup>
    </CardBody>
  </Card>
}

OrderList.propTypes = {
  orders: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(OrderList);
