import React from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';
import { Avatar } from '../vibe';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';

const HeaderNav = ({
  onLogout,
  history
}) => {

  const handleLogout = (event) => {
    let localStore = require('store');
    let user = localStore.get('user');
    user.authenticated = false;
    localStore.set('user', user);
    onLogout(event);
  }

  const getAvatarNick = () => {
    let localStore = require('store');
    let user = localStore.get('user');

    if (!user) return "";

    return user.nick_name;
  }

  function handleChangePwd() {
    let localStore = require('store');
    let user = localStore.get('user');
    history.push("/user/pwd/" + user.id);
  }

  return (
    <React.Fragment>
      {/*<NavItem>*/}
      {/*  <form className="form-inline">*/}
      {/*    <input className="form-control mr-sm-1" type="search" placeholder="Search" aria-label="Search" />*/}
      {/*    <Button type="submit" className="d-none d-sm-block">*/}
      {/*      <i className="fa fa-search" />*/}
      {/*    </Button>*/}
      {/*  </form>*/}
      {/*</NavItem>*/}
      {/*<UncontrolledDropdown nav inNavbar>*/}
      {/*  <DropdownToggle nav caret>*/}
      {/*    New*/}
      {/*  </DropdownToggle>*/}
      {/*  <DropdownMenu right>*/}
      {/*    <DropdownItem>Project</DropdownItem>*/}
      {/*    <DropdownItem>User</DropdownItem>*/}
      {/*    <DropdownItem divider />*/}
      {/*    <DropdownItem>*/}
      {/*      Message <Badge color="primary">10</Badge>*/}
      {/*    </DropdownItem>*/}
      {/*  </DropdownMenu>*/}
      {/*</UncontrolledDropdown>*/}
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav>
          <Avatar
            size="small"
            color="blue"
            initials={getAvatarNick()}
          />
        </DropdownToggle>
        <DropdownMenu right>
          {/*<DropdownItem>Szczegóły</DropdownItem>*/}
          <DropdownItem onClick={handleChangePwd}>Zmień hasło</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={handleLogout}>Wyloguj</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  );
}

HeaderNav.propTypes = {
  onLogout: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings
  }
}

export default connect(
  mapStateToProps,
  null
)(withRouter(HeaderNav));
