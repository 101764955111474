import React from 'react';
import { PropTypes } from 'prop-types';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';

const CancelButton = ({
  path,
  history,
  disabled
}) => {
  return (
    <Button
      className="pull-right"
      color="secondary"
      onClick={path
        ? () => history.push(path)
        : history.goBack
      }
      disabled={disabled}
    >
      <i className="fa fa-close" />&nbsp;&nbsp;
      Anuluj
    </Button>
  );
}

CancelButton.propTypes = {
  path: PropTypes.string,
  history: PropTypes.object.isRequired,
  disabled: PropTypes.bool
}

export default withRouter(CancelButton);
