import moment from 'moment';
import * as db from './db';

const MESSAGE_BASE_ID = 300;
const TANK_BASE_ID = 500;

let liquids = [];
let vehicles = [];
let mechanics = [];
let users = [];
let containers = [];
let containerStates = [];
let messages = [];
let tanks = [];
let orders =[];

export const initialize = () => {
  let date = '';
  liquids = db.liquids;
  vehicles = db.vehicles;
  mechanics = db.mechanics;
  users = db.users;
  containers = db.containers;
  messages = db.messages;
  tanks = db.tanks;
  orders = db.orders;
  containerStates = db.containerStates.map(state => {
    switch(state.id) {
    // Service 1
      case 1:
        return {...state, change_date: dateToStr(service1Date(), 10, 1, 0)};
      case 2:
        return {...state, change_date: dateToStr(service1Date(), 10, 5, 0)};
      case 3:
        return {...state, change_date: dateToStr(service1Date(), 10, 8, 0)};
      case 4:
        date = dateToStr(service1Date(), 12, 36, 0);
        correctMessage(state.id, date);
        return {...state, change_date: date};
      case 5:
        return {...state, change_date: dateToStr(service1Date(), 12, 55, 0)};
    // Service 2
      case 6:
        date = dateToStr(service2Date(), 9, 20, 0);
        correctTank(state.id, date);
        correctMessage(state.id, date);
        return {...state, change_date: date};
      case 7:
        date = dateToStr(service2Date(), 10, 25, 0);
        correctTank(state.id, date);
        correctMessage(state.id, date);
        return {...state, change_date: date};
    // Service 3
      case 8:
        date = dateToStr(service3Date(), 8, 28, 0);
        correctTank(state.id, date);
        correctMessage(state.id, date);
        return {...state, change_date: date};
      case 9:
        date = dateToStr(service3Date(), 9, 30, 0);
        correctTank(state.id, date);
        correctMessage(state.id, date);
        return {...state, change_date: date};
    // Service 4
      case 10:
        return {...state, change_date: dateToStr(service4Date(), 10, 12, 0)};
      case 11:
        return {...state, change_date: dateToStr(service4Date(), 10, 22, 0)};
      case 12:
        return {...state, change_date: dateToStr(service4Date(), 14, 31, 0)};
    // Service 5
      case 13:
        date = dateToStr(service5Date(), 11, 41, 0);
        correctTank(state.id, date);
        return {...state, change_date: date};
      case 14:
        date = dateToStr(service5Date(), 13, 43, 0);
        correctTank(state.id, date);
        correctMessage(state.id, date);
        return {...state, change_date: date};
    // Service 6
      case 15:
        date = dateToStr(service6Date(), 8, 44, 0);
        correctTank(state.id, date);
        return {...state, change_date: date};
      case 16:
        date = dateToStr(service6Date(), 10, 46, 0);
        correctTank(state.id, date);
        return {...state, change_date: date};
    // Service Today
      case 17:
        date = dateToStr(serviceTodayDate(), 9, 17, 0);
        correctTank(state.id, date);
        return {...state, change_date: date};
      case 18:
        date = dateToStr(serviceTodayDate(), 10, 49, 0);
        correctTank(state.id, date);
        correctMessage(state.id, date);
        return {...state, change_date: date};
      case 19:
        date = dateToStr(serviceTodayDate(), 10, 55, 0);
        correctTank(state.id, date);
        correctMessage(state.id, date);
        return {...state, change_date: date};
      case 20:
        date = dateToStr(serviceTodayDate(), 12, 21, 0);
        correctTank(state.id, date);
        correctMessage(state.id, date);
        return {...state, change_date: date};
      case 21:
        date = dateToStr(serviceTodayDate(), 13, 24, 0);
        correctTank(state.id, date);
        return {...state, change_date: date};
      case 22:
        date = dateToStr(serviceTodayDate(), 15, 53, 0);
        return {...state, change_date: date};
      default:
        return state;
    }
  });

  correctMessage(321, dateToStr(service6Date(), 14, 32, 0));
  correctMessage(322, dateToStr(service6Date(), 12, 51, 0));
}

const convertDate = date => {
  const dateArr = date.split('-');
  return moment(dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0])
}

const dateToStr = (date, hours, minutes, seconds) => {
  return date.getFullYear() + "-" +
    String(date.getMonth() + 1).padStart(2, '0') + "-" +
    String(date.getDate()).padStart(2, '0') + " " +
    String(hours).padStart(2, '0') + ":" +
    String(minutes).padStart(2, '0') + ":" +
    String(seconds).padStart(2, '0');
}

const createNow = () => new Date();

const now = () => {
  const date = createNow();
  return dateToStr(date, date.getHours(), date.getMinutes(), date.getSeconds());
}

const backDate = days => {
  let date = createNow();
  date.setDate(date.getDate() - days)
  return date;
}

const service1Date = () => backDate(14);
const service2Date = () => backDate(12);
const service3Date = () => backDate(10);
const service4Date = () => backDate(9);
const service5Date = () => backDate(5);
const service6Date = () => backDate(3);
const serviceTodayDate = () => createNow();

const correctMessage = (containerStateId, date) => {
  messages = messages.map(message => {
    return message.id === MESSAGE_BASE_ID + containerStateId
      ? {...message, create_date: date}
      : message;
  })
}

const correctTankTrace = (tankTrace, serviceDate) => {
  let dates = tankTrace.substring(1, tankTrace.length-1).split(",");
  const date = serviceDate.substring(0, 14);
  const newDates = dates.map(d => {
    const d2 = d.substring(0, d.length - 1).trim();
    return date + d2.substring(15);
  })
  console.log(newDates);
  return "[" + newDates.join(", ") + "]";
}

const correctTank = (containerStateId, date) => {
  tanks = tanks.map(tank => {
    return tank.id === TANK_BASE_ID + containerStateId
      ? { ...tank, date: date, trace: correctTankTrace(tank.trace, date)}
      : tank;
  })
}

export const getTanks = (start, end) => {
  return tanks
    .filter(tank =>
      moment(tank.date).isSameOrAfter(convertDate(start)) &&
      moment(tank.date).isSameOrBefore(convertDate(end))
    )
    .sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
}

export const getContainerStates = (containerId, start, end) => {
  let ret = containerStates
    .filter(state =>
      state.container.id === containerId &&
      moment(state.change_date).isSameOrAfter(convertDate(start)) &&
      moment(state.change_date).isSameOrBefore(convertDate(end))
    )
    .sort((a, b) => {
      return new Date(b.change_date) - new Date(a.change_date);
    });

  return ret;
}

const clearMessages = containerId => {
  messages = messages.map(message => {
    return message.container_id === containerId
      ? {...message, received: 1}
      : message;
  })
}

const createContainerLevelAlarm = containerId => {
  messages = [...messages, {
    id: messages.length + 100,
    type: "CONTAINER_LEVEL_ALARM",
    create_date: now(),
    level: "warning",
    content: "Przekroczony poziom alarmowy",
    received: 0,
    container_id: containerId,
    container: containers.find(c => c.id === containerId)
  }]
}

export const addContainerState = (containerId, data) => {
  const user = users.find(u => u.id === data.user_id);

  let newState = {
    id: containerStates.length + 100,
    container: {
      id: containerId
    },
    liquid: {
      id: data.liquid_id,
      name: liquids.find(l => l.id === data.liquid_id).name
    },
    change_date: now(),
    comments: data.comments,
    user: {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name
    }
  };

  let container = containers.find(c => c.id === containerId);
  let liquidAmount = data.liquid_amount;
  let changeAmount = data.change_amount;

  clearMessages(containerId);

  if (data.type === "Add") {
    liquidAmount = container.liquid_amount + data.change_amount
    newState = {...newState, type: "Add" };
    if (liquidAmount <= container.alarm_level)
      createContainerLevelAlarm(containerId);
  }
  else if(data.type === "Refill") {
    liquidAmount = container.capacity;
    changeAmount = container.capacity - liquidAmount;
    newState = {...newState, type: "Refill" };
  }
  else if(data.type === "Correction") {
    changeAmount = liquidAmount - container.liquid_amount;
    newState = {...newState, type: "Correction" };
    if (liquidAmount <= container.alarm_level)
      createContainerLevelAlarm(containerId);
  }
  else if(data.type === "Liquid") {
    changeAmount = liquidAmount - container.liquid_amount;
    container.liquid_id = data.liquid_id;
    container.liquid = liquids.find(l => l.id === data.liquid_id);
    container.liquid_amount = liquidAmount;
  }

  container.liquid_amount = liquidAmount;
  containers = containers.map(c => {
    return c.id === container.id
      ? container
      : c;
  })

  newState = {
    ...newState,
    liquid_amount: liquidAmount,
    change_amount: changeAmount
  }
  containerStates = [...containerStates, newState];

  return containerStates;
}

export const getNewMessages = () => {
  return messages
    .filter(message => message.received === 0)
    .sort((a, b) => {
      return new Date(b.create_date) - new Date(a.create_date);
    });
}

export const getMessages = (start, end) => {
  return messages
    .filter(message =>
      moment(message.create_date).isSameOrAfter(convertDate(start)) &&
      moment(message.create_date).isSameOrBefore(convertDate(end))
    )
    .sort((a, b) => {
      return new Date(b.create_date) - new Date(a.create_date);
    });
}

export const receiveAllMessages = () => {
  messages = messages.map(message => {
    return {...message, received: 1 }
  });
  return messages;
}

export const receiveMessage = messageId => {
  messages = messages.map(message => {
    return message.id === messageId
      ? {...message, received: 1 }
      : message
  })
  return messages;
}

export const getLiquids = () => {
  return liquids;
}

export const saveLiquid = liquid => {
  if (liquid.id) {
    liquids = liquids.map(l => {
      return l.id === liquid.id
        ? liquid
        : l;
    })
  } else {
    liquid.id = liquids.length + 1;
    liquids = [...liquids, liquid];
  }
  return liquid;
}

export const deleteLiquid = liquidId => {
  liquids = liquids.filter(l => l.id !== liquidId);
}

export const getVehicles = () => {
  return vehicles;
}

export const saveVehicle = vehicle => {
  if (vehicle.id) {
    vehicles = vehicles.map(v => {
      return v.id === vehicle.id
        ? vehicle
        : v;
    })
  } else {
    vehicle.id = vehicles.length + 1;
    vehicles = [...vehicles, vehicle];
  }
  return vehicle;
}

export const deleteVehicle = vehicleId => {
  vehicles = vehicles.filter(v => v.id !== vehicleId);
}

export const getMechanics = () => {
  return mechanics;
}

export const saveMechanic = mechanic => {
  if (mechanic.id) {
    mechanics = mechanics.map(m => {
      return m.id === mechanic.id
        ? mechanic
        : m;
    })
  } else {
    mechanic.id = mechanics.length + 1;
    mechanics = [...mechanics, mechanic];
  }
  return mechanic;
}

export const deleteMechanic = mechanicId => {
  mechanics = mechanics.filter(m => m.id !== mechanicId);
}

export const getUsers = () => {
  return users;
}

export const saveUser = user => {
  if (user.id) {
    users = users.map(u => {
      return u.id === user.id
        ? user
        : u;
    })
  } else {
    user.id = users.length + 1;
    users = [...users, user];
  }
  return user;
}

export const deleteUser = userId => {
  users = users.filter(u => u.id !== userId);
}

export const getContainers = () => {
  return containers;
}

export const saveContainer = container => {
  if (container.id) {
    containers = containers.map(c => {
      return c.id === container.id
        ? container
        : c;
    })
  } else {
    container.id = containers.length + 1;
    containers = [...containers, container];
  }
  return container;
}

export const deleteContainer = containerId => {
  containers = containers.filter(c => c.id !== containerId);
}

export const getOrders = () => {
  return orders;
}
