import { apiUrl, getData, deleteData, postHeaders, handleResponse, handleError } from './apiUtils';
import * as demoRepo from './demo/repository';

const orderUrl = apiUrl + "/warehouse/open_orders/";
const orderLineUrl = apiUrl + "/warehouse/order_lines/";
const closeOrderUrl = apiUrl + "/warehouse/close_orders/"

export function getOrders() {
  return getData(orderUrl, () => demoRepo.getOrders());
}

export function saveOrder(order) {
  return fetch(orderUrl, {
    method: "POST",
    headers: postHeaders(),
    body: JSON.stringify(order)
  })
    .then(handleResponse)
    .catch(handleError);
}

export function closeOrder(orderId) {
  return fetch(closeOrderUrl + orderId, {
    method: "PUT",
    headers: postHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveOrderLine(orderLine) {
  return fetch(orderLineUrl + (orderLine.order_line_id || ""), {
    method: orderLine.order_line_id ? "PUT" : "POST",
    headers: postHeaders(),
    body: JSON.stringify(orderLine)
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteOrderLine(orderLineId) {
  return deleteData(orderLineUrl, orderLineId, () => {});
}
