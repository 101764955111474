import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import * as containerActions from "../../../../redux/actions/containerAction";
import * as statesActions from '../../../../redux/actions/containerStatesActions'
import * as dashboardActions from '../../../../redux/actions/dashboardAction'
import { newContainer } from "../../../../redux/reducers/initialState";
import { Col, Row } from 'reactstrap';
import ContainerStateList from './ContainerStatesList';
import Tools from './Tools';
import ContainerInfoCard from '../common/ContainerInfoCard';
import moment from 'moment';
import { dateToString } from '../../../../common/utils';
import ContainerChartCard from '../common/ContainerChartCard';

const ContainerDetailsPage = ({
  containers,
  loadContainers,
  containerStates,
  loadContainerStates,
  loadDashboard,
  ...props
}) => {

  const initStartDate = moment().subtract(6, 'days');
  const initEndDate = moment().add(1, 'days');

  const [container, setContainer] = useState({...props.container});

  useEffect(() => {
    if (containers.length === 0) {
      loadContainers();
    }
    else {
      setContainer({...props.container});
      loadContainerStates(props.container.id, dateToString(initStartDate), dateToString(initEndDate));
      loadDashboard();
    }
  },[props.container])

  const handleFilterChanged = (startDate, endDate) => {
    loadContainerStates(container.id, dateToString(startDate), dateToString(endDate));
  }

  return (
    <>
      <Row>
        <Col>
          <Tools containerId={container.id} />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <ContainerInfoCard
            container={container}
          />
        </Col>
        <Col md={6}>
          <ContainerChartCard
            container={container}
            showNewAmount={false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ContainerStateList
            containerStates={containerStates}
            onFilterChanged={handleFilterChanged}
            initStartDate={initStartDate}
            initEndDate={initEndDate}
          />
        </Col>
      </Row>
    </>
  )
}

ContainerDetailsPage.propTypes = {
  containers: PropTypes.array.isRequired,
  container: PropTypes.object.isRequired,
  containerStates: PropTypes.array.isRequired,
  loadContainers: PropTypes.func.isRequired,
  loadContainerStates: PropTypes.func.isRequired
}

const getContainerById = (containers, containerId) => {
  return containers.find(container => container.id === containerId);
}

const mapStateToProps = (state, ownProps) => {
  const containerId = ownProps.match.params.id;
  const container =
    state.containers.length > 0 && containerId
      ? getContainerById(state.containers, parseInt(containerId))
      : newContainer;
  return {
    container: container,
    containers: state.containers,
    containerStates: state.containerStates
  };
}

const mapDispatchToProps = {
  loadContainers: containerActions.loadContainers,
  loadContainerStates: statesActions.loadContainerStates,
  loadDashboard: dashboardActions.loadDashboard
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerDetailsPage);
