import * as types from "./actionTypes"
import * as api from "../../api/vehicleApi"
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function loadVehiclesSuccess(vehicles) {
  return { type: types.LOAD_VEHICLES_SUCCESS, vehicles };
}

export function createVehicleSuccess(vehicle) {
  return { type: types.CREATE_VEHICLE_SUCCESS, vehicle }
}

export function updateVehicleSuccess(vehicle) {
  return { type: types.UPDATE_VEHICLE_SUCCESS, vehicle }
}

export function deletedVehicleSuccess(vehicleId) {
  return { type: types.DELETE_VEHICLE_SUCCESS, vehicleId }
}

export function loadVehicles() {
  return function(dispatch) {
    dispatch(beginApiCall());
    return api
      .getVehicles()
      .then(vehicles => {
        dispatch(loadVehiclesSuccess(vehicles));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveVehicle(vehicle) {
  return dispatch => {
    dispatch(beginApiCall());
    return api
      .saveVehicle(vehicle)
      .then(savedVehicle => {
        vehicle.id
          ? dispatch(updateVehicleSuccess(vehicle))
          : dispatch(createVehicleSuccess(savedVehicle))
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  }
}

export function deleteVehicle(vehicleId) {
  return dispatch => {
    return api
      .deleteVehicle(vehicleId)
      .then(() => {
        dispatch(deletedVehicleSuccess(vehicleId));
      })
  }
}
