import * as types from "./actionTypes";
import * as api from "../../api/containersApi";
import * as statesApi from "../../api/containerStateApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadContainersSuccess(containers) {
  return {type: types.LOAD_CONTAINERS_SUCCESS, containers};
}

export function createContainerSuccess(container) {
  return {type: types.CREATE_CONTAINER_SUCCESS, container};
}

export function updateContainerSuccess(container) {
  return {type: types.UPDATE_CONTAINER_SUCCESS, container};
}

export function deletedContainerSuccess(containerId) {
  return { type: types.DELETE_CONTAINER_SUCCESS, containerId };
}

export function changeLiquidSuccess(liquidId, amount, comments) {
  return { type: types.CHANGE_LIQUID_SUCCESS, liquidId, amount, comments };
}

export function loadContainers() {
  return dispatch => {
    dispatch(beginApiCall());
    api.getContainers()
      .then((containers) => {
        dispatch(loadContainersSuccess(containers));
      })
      .catch(() => {
        dispatch(apiCallError());
      })
  }
}

export function saveContainer(container) {
  return dispatch => {
    dispatch(beginApiCall());
    return api
      .saveContainer(container)
      .then(savedContainer => {
        container.id
          ? dispatch(updateContainerSuccess(container))
          : dispatch(createContainerSuccess(savedContainer))
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  }
}

export function deleteContainer(containerId) {
  return dispatch => {
    return api
      .deleteContainer(containerId)
      .then(() => {
        dispatch(deletedContainerSuccess(containerId));
      })
  }
}

export function changeLiquid(container, liquidId, comments) {
  return dispatch => {
    let localStore = require('store');
    const user = localStore.get('user');
    dispatch(beginApiCall());
    return statesApi.postContainerStates(container.id, {
      liquid_amount: container.capacity,
      change_amount: 0,
      type: "Liquid",
      liquid_id: liquidId,
      user_id: user.id,
      comments: comments
    })
      .then(() => {
        container.liquid_id = liquidId;
        dispatch(updateContainerSuccess(container));
      })
      .catch(() => {
        dispatch(apiCallError());
      })
  }
}

export function correctContainer(container, newAmount, comments) {
  return dispatch => {
    let localStore = require('store');
    const user = localStore.get('user');
    dispatch(beginApiCall());
    return statesApi.postContainerStates(container.id, {
      liquid_amount: newAmount,
      change_amount: 0,
      type: "Correction",
      liquid_id: container.liquid_id,
      user_id: user.id,
      comments: comments
    })
      .then(() => {
        container.liquid_amount = newAmount;
        dispatch(updateContainerSuccess(container));
      })
      .catch(() => {
        dispatch(apiCallError());
      })
  }
}

export function tankContainer(container, amount, comments) {
  return dispatch => {
    let localStore = require('store');
    const user = localStore.get('user');
    dispatch(beginApiCall());
    return statesApi.postContainerStates(container.id, {
      liquid_amount: container.liquid_amount,
      change_amount: amount,
      type: "Add",
      liquid_id: container.liquid_id,
      user_id: user.id,
      comments: comments
    })
      .then(() => {
        container.liquid_amount += amount;
        dispatch(updateContainerSuccess(container));
      })
      .catch(() => {
        dispatch(apiCallError());
      })
  }
}

export function refillContainer(container, comments) {
  return dispatch => {
    let localStore = require('store');
    const user = localStore.get('user');
    dispatch(beginApiCall());
    return statesApi.postContainerStates(container.id, {
      liquid_amount: 0,
      change_amount: 0,
      type: "Refill",
      liquid_id: container.liquid_id,
      user_id: user.id,
      comments: comments
    })
      .then(() => {
        container.liquid_amount = container.capacity;
        dispatch(updateContainerSuccess(container));
      })
      .catch(() => {
        dispatch(apiCallError());
      })
  }
}
