import { handleResponse, handleError, apiUrl, postHeaders, demo, getData } from './apiUtils';
import * as demoRepo from './demo/repository';
const baseUrl = apiUrl + "/warehouse/messages/";

export function getNewMessages() {
  return getData(baseUrl + "new", () => demoRepo.getNewMessages());
}

export function getMessages(start, end) {
  return getData(baseUrl + start + "/" + end, () => demoRepo.getMessages(start, end));
}

export function receiveAllMessages() {
   if (demo)
     return Promise.resolve(demoRepo.receiveAllMessages());

  return fetch(baseUrl + "receive/all", {
    method: "POST",
    headers: postHeaders(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function receiveMessage(messageId) {
  if (demo)
    return Promise.resolve(demoRepo.receiveMessage(messageId));

  return fetch(baseUrl + "receive/" + messageId, {
    method: "PUT",
    headers: postHeaders(),
  })
    .then(handleResponse)
    .catch(handleError);
}
