import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import * as liquidActions from '../../../../redux/actions/liquidAction';
import * as containerActions from '../../../../redux/actions/containerAction';
import * as mechanicActions from '../../../../redux/actions/mechanicActions';
import * as orderActions from '../../../../redux/actions/orderActions';
import { Button, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import BackButton from '../../../common/BackButton';
import OrderList from './OrderList';

const OrdersPage = ({
  orders,
  loadLiquids,
  loadContainers,
  loadMechanics,
  loadOrders,
  closeOrder,
  deleteOrderLine,
  history
}) => {

  useEffect(() => {
    loadLiquids();
    loadMechanics();
    loadContainers();
    loadOrders();
  }, [])

  const handleDelete = orderLineId => {
    return  deleteOrderLine(orderLineId)
      .then(() => {
        loadOrders();
        toast.success("Pozycja została usunięta")
      })
      .catch(() => {
        loadOrders();
        toast.error("Nie można usunąć pozycji")
      })
  }

  const handleClose = orderId => {
    return  closeOrder(orderId)
      .then(() => {
        loadOrders();
        toast.success("Zlecenie zostało usunięte")
      })
      .catch(() => {
        loadOrders();
        toast.error("Nie można zamknąć zlecenia")
      })
  }

  return (<>
      <Card>
        <CardBody>
          <Button
            color="primary"
            onClick={() => history.push("/order")}
          >
            <i className="fa fa-plus" style={{ paddingRight: 8 }}/>
            Dodaj zlecenie
          </Button>
          <BackButton />
        </CardBody>
      </Card>
      <OrderList
        orders={orders}
        onDelete={handleDelete}
        onClose={handleClose}
      />
    </>
  );
};

OrdersPage.propTypes = {
  orders: PropTypes.array.isRequired,
  loadOrders: PropTypes.func.isRequired,
  loadLiquids: PropTypes.func.isRequired,
  loadMechanics: PropTypes.func.isRequired,
  loadContainers: PropTypes.func.isRequired,
  deleteOrderLine: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  orders: state.orders
})

const mapDispatchToProps = {
  loadLiquids: liquidActions.loadLiquids,
  loadMechanics: mechanicActions.loadMechanics,
  loadContainers: containerActions.loadContainers,
  loadOrders: orderActions.loadOrders,
  closeOrder: orderActions.closeOrder,
  deleteOrderLine: orderActions.deleteOrderLine
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersPage);
