export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

export const LOAD_SETTINGS_SUCCESS = "LOAD_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";

export const LOAD_LIQUIDS_SUCCESS = "LOAD_LIQUIDS_SUCCESS";
export const CREATE_LIQUID_SUCCESS = "CREATE_LIQUID_SUCCESS";
export const UPDATE_LIQUID_SUCCESS = "UPDATE_LIQUID_SUCCESS";
export const DELETE_LIQUID_SUCCESS = "DELETE_LIQUID_SUCCESS";

export const LOAD_CONTAINERS_SUCCESS = "LOAD_CONTAINERS_SUCCESS";
export const CREATE_CONTAINER_SUCCESS = "CREATE_CONTAINER_SUCCESS";
export const UPDATE_CONTAINER_SUCCESS = "UPDATE_CONTAINER_SUCCESS";
export const DELETE_CONTAINER_SUCCESS = "DELETE_CONTAINER_SUCCESS";
export const CHANGE_LIQUID_SUCCESS = "CHANGE_LIQUID_SUCCESS";
export const LOAD_CONTAINERSTATES_SUCCESS = "LOAD_CONTAINERSTATES_SUCCESS";

export const LOAD_MECHANICS_SUCCESS = "LOAD_MECHANICS_SUCCESS";
export const CREATE_MECHANIC_SUCCESS = "CREATE_MECHANIC_SUCCESS";
export const UPDATE_MECHANIC_SUCCESS = "UPDATE_MECHANIC_SUCCESS";
export const DELETE_MECHANIC_SUCCESS = "DELETE_MECHANIC_SUCCESS";

export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const LOAD_STATIONS_SUCCESS = "LOAD_STATIONS_SUCCESS";
export const CREATE_STATION_SUCCESS = "CREATE_STATION_SUCCESS";
export const UPDATE_STATION_SUCCESS = "UPDATE_STATION_SUCCESS";
export const DELETE_STATION_SUCCESS = "DELETE_STATION_SUCCESS";
export const ADD_PUMP = "ADD_PUMP";
export const UPDATE_PUMP = "UPDATE_PUMP";

export const LOAD_TANKS_SUCCESS = "LOAD_TANKS_SUCCESS";
export const CREATE_TANK_SUCCESS = "CREATE_TANK_SUCCESS";
export const UPDATE_TANK_SUCCESS = "UPDATE_TANK_SUCCESS";
export const DELETE_TANK_SUCCESS = "DELETE_TANK_SUCCESS";

export const LOAD_VEHICLES_SUCCESS = "LOAD_VEHICLES_SUCCESS";
export const CREATE_VEHICLE_SUCCESS = "CREATE_VEHICLE_SUCCESS";
export const UPDATE_VEHICLE_SUCCESS = "UPDATE_VEHICLE_SUCCESS";
export const DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS";

export const LOAD_MESSAGES_SUCCESS = "LOAD_MESSAGES_SUCCESS";
export const RECEIVE_ALL_MESSAGES_SUCCESS = "RECEIVE_ALL_MESSAGES_SUCCESS";
export const RECEIVE_MESSAGE_SUCCESS = "RECEIVE_MESSAGE_SUCCESS";

export const LOAD_DASHBOARD_SUCCESS = "LOAD_DASHBOARD_SUCCESS";
export const RECEIVE_ALL_DASHBOARD_MESSAGES_SUCCESS = "RECEIVE_ALL_DASHBOARD_MESSAGES_SUCCESS";
export const RECEIVE_DASHBOARD_MESSAGE_SUCCESS = "RECEIVE_DASHBOARD_MESSAGE_SUCCESS";

export const LOAD_ORDERS_SUCCESS = "LOAD_ORDERS_SUCCESS";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CLOSE_ORDER_SUCCESS = "CLOSE_ORDER_SUCCESS";
export const CREATE_ORDERLINE_SUCCESS = "CREATE_ORDERLINE_SUCCESS";
export const DELETE_ORDERLINE_SUCCESS = "DELETE_ORDERLINE_SUCCESS";
