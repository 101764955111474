import {
    apiUrl,
    getData,
    saveData,
    deleteData,
} from './apiUtils';
import * as demoRepo from './demo/repository';
const baseUrl = apiUrl + "/admin/liquids/";

export function getLiquids() {
    return getData(baseUrl, () => demoRepo.getLiquids());
}

export function saveLiquid(liquid) {
    return saveData(baseUrl, liquid, () => demoRepo.saveLiquid(liquid));
}

export function deleteLiquid(liquidId) {
    return deleteData(baseUrl, liquidId, () => demoRepo.deleteLiquid(liquidId));
}
