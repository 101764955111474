import React, { useEffect, useState } from "react";
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { newContainer } from '../../../../redux/reducers/initialState';
import { Loader } from '../../../../vibe';
import { Col, Row } from 'reactstrap';
import ContainerChartCard from '../common/ContainerChartCard';
import ContainerInfoCard from '../common/ContainerInfoCard';
import { toast } from 'react-toastify';
import * as containerActions from '../../../../redux/actions/containerAction';
import * as liquidActions from '../../../../redux/actions/liquidAction';
import ChangeLiquidForm from './ChangeLiquidForm';

const ContainerLiquidPage = ({
  containers,
  loadContainers,
  liquids,
  loadLiquids,
  changeLiquid,
  history,
  ...props
}) => {

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({})

  const [container, setContainer] = useState({...props.container});

  const [newLiquidId, setNewLiquidId] = useState(0);
  const [tankAmount, setTankAmount] = useState(0);
  const [comments, setComments] = useState('');

  useEffect(() => {
    if (containers.length === 0) {
      setLoading(true);
      loadContainers()
      loadLiquids();
      setLoading(false);
    }
    else {
      setContainer({...props.container});
      setNewLiquidId(props.container.liquidId);
      setTankAmount(0);
    }

    if (liquids.length === 0) {
      loadLiquids();
    }
  }, [props.container])

  const handleChangeLiquid = (event) => {
    const { value } = event.target;
    if (value !== 0)
      setTankAmount(container.capacity - container.liquid_amount);
    setNewLiquidId(parseInt(value, 10));
  }

  const handleChangeComments = (event) => {
    const { value } = event.target;
    setComments(value);
  }

  function formIsValid() {
    const errors = {};
    if (!newLiquidId)
      errors.liquidId = "Musisz wybrać nowy płyn";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function handleSave(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    setSaving(true);
    changeLiquid(container, newLiquidId, comments)
      .then(() => {
        history.goBack();
        toast.success("Płyn został zmieniony");
      })
      .catch(error => {
        setSaving(false);
        setErrors( { onSave: error.message });
      })
  }

  return (
    loading
      ? <Loader type="bars" />
      : (<>
        <Row>
          <Col md={{ size: 9, offset: 1 }}>
            <ContainerChartCard
              container={container}
              tankAmount={tankAmount}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 5, offset: 1 }}>
            <ChangeLiquidForm
              liquids={liquids}
              liquidId={newLiquidId}
              comments={comments}
              onChangeLiquid={handleChangeLiquid}
              onChangeComments={handleChangeComments}
              isSaving={saving}
              onSave={handleSave}
              errors={errors}
            />
          </Col>
          <Col md={4}>
            <ContainerInfoCard
              container={container}
              newAmount={container.liquid_amount + tankAmount}
            />
          </Col>
        </Row>
      </>)
  );
}

ContainerLiquidPage.propTypes = {
  container: PropTypes.object.isRequired,
  containers: PropTypes.array.isRequired,
  loadContainers: PropTypes.func.isRequired,
  changeLiquid: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const containerId = ownProps.match.params.id;
  const container = containerId && state.containers.length > 0
    ? state.containers.find(c => c.id === parseInt(containerId))
    : newContainer;
  return {
    container: container,
    containers: state.containers,
    liquids: state.liquids.filter(liquid => liquid.id !== container.liquid_id)
  }
}

const mapDispatchToProps = {
  loadContainers: containerActions.loadContainers,
  changeLiquid: containerActions.changeLiquid,
  loadLiquids: liquidActions.loadLiquids
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerLiquidPage);
