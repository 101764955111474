import React from 'react';
import { PropTypes } from 'prop-types';
import { toAmount } from '../../common/utils';

const TdAmount = ({
  amount
}) => {
  return <td width={100}><div className="pull-right">{toAmount(amount)}</div></td>
}

TdAmount.propTypes = {
  amount: PropTypes.number.isRequired
}

export default TdAmount;
