import React from 'react';
import { PropTypes } from 'prop-types';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
const ContainerInfoCard = ({
  container,
  newAmount = 0
}) => {
  return (
    <Card>
      <CardHeader>
        <h4>{container.name}</h4>
      </CardHeader>
      <CardBody>
        <Table className="table">
          <tbody>
            <tr>
              <td>Rodzaj płynu</td>
            <td>
              <strong>
                {container.liquid.name}
              </strong>
            </td>
            </tr>
            <tr>
              <td>
                Pojemność
              </td>
              <td>
                <strong>
                  {container.capacity.toFixed(2)}
                </strong>
              </td>
            </tr>
            <tr>
              <td>
                Poziom alarmowy
              </td>
              <td>
                <strong>
                  <p className="text-warning">
                    {container.alarm_level.toFixed(2)}
                  </p>
                </strong>
              </td>
            </tr>
            <tr>
              <td>
                Poziom minimalny
              </td>
              <td>
                <strong>
                  <p className="text-danger">
                    {container.min_level.toFixed(2)}
                  </p>
                </strong>
              </td>
            </tr>
            <tr>
              <td>Aktualna ilość:</td>
              <td>
                <strong>
                  <p className="text-primary">
                    {container.liquid_amount.toFixed(2)}
                  </p>
                </strong>
              </td>
            </tr>
            {newAmount > 0 && (
              <tr>
                <td>Ilość po zmianie:</td>
                <td>
                  <strong>
                    <p className="text-success">
                      {newAmount.toFixed(2)}
                    </p>
                  </strong>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}

ContainerInfoCard.propTypes = {
  container: PropTypes.object.isRequired,
  newAmount: PropTypes.number
}

export default ContainerInfoCard;
