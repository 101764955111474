import React, { useEffect } from "react";
import UserList from "./UserList";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import * as actions from "../../../../redux/actions/userAction";
import { Button, Card, CardBody } from 'reactstrap';
import { isAdmin } from '../../../../common/utils';
import UserTools from './UserTools';

const UsersPage = ({
  users,
  loadUsers,
  deleteUser,
  history,
  match
}) => {

  useEffect(() => {
    loadUsers();
  }, [])

  function handleDelete(liquidId) {
    return deleteUser(liquidId)
      .then(() => toast.success("Użytkownik został usunięty"))
  }

  return (
    <>
      <UserTools mode={match.params.mode}/>
      <Card>
        <CardBody>
          <UserList
            users={users}
            onDelete={handleDelete}
            mode={match.params.mode}
            admin={isAdmin()}
            history={history}
          />
        </CardBody>
      </Card>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  users: state.users.filter(u =>
    u.role === (ownProps.match.params.mode === "admin"
      ? "Admin"
      : "Warehouse")
  )
})

const mapDispatchToProps = {
  loadUsers: actions.loadUsers,
  deleteUser: actions.deleteUser
}

UsersPage.propTypes = {
  users: PropTypes.array.isRequired,
  loadUsers: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersPage);
