import React, { useState } from 'react';
import TextInput from '../../../common/TextInput';
import { PropTypes } from 'prop-types';
import { Card, CardBody, Col, Form, Row } from 'reactstrap';
import FormButtons from '../../../common/FormButtons';

const UserForm = ({
  user,
  onChange,
  onSave,
  isSaving,
  isEditMode,
  errors = {}
}) => {

  const [password2, setPassword2] = useState("");

  const handleChangePassword2 = event => {
    const { value } = event.target;
    setPassword2(value);
  }

  const handleSave = event => {
    event.preventDefault();

    const errors = {};
    if(!isEditMode && user.password !== password2) {
      errors.password = "Hasła nie zgadzają się";
      errors.password2 = "Hasła nie zgadzają się";
    }
    onSave(event, errors);
  }

  return (
    <Row>
      <Col md={{ size: 8, offset: 2 }}>
        <Card>
          <CardBody>
            <Form>
              {errors.onSave && (
                <div className="alert alert-danger" role="alert">
                  {errors.onSave}
                </div>
              )}
              <TextInput
                name="first_name"
                label="Imie"
                value={user.first_name}
                onChange={onChange}
                error={errors.first_name}
              />
              <TextInput
                name="last_name"
                label="Nazwisko"
                value={user.last_name}
                onChange={onChange}
                error={errors.last_name}
              />
              <TextInput
                name="pin_code"
                label="Pin"
                value={user.pin_code}
                onChange={onChange}
                error={errors.pin_code}
              />
              <TextInput
                name="username"
                label="Login"
                value={user.username}
                onChange={onChange}
                error={errors.username}
              />
              { !isEditMode && (
                <>
                  <TextInput
                    name="password"
                    label="Hasło"
                    value={user.password}
                    onChange={onChange}
                    error={errors.password}
                    isPassword={true}
                  />
                  <TextInput
                    name="password2"
                    label="Hasło (powtórzone)"
                    value={password2}
                    onChange={handleChangePassword2}
                    error={errors.password2}
                    isPassword={true}
                  />
                </>
              )}
            </Form>
          </CardBody>
        </Card>
        <FormButtons
          onSave={handleSave}
          isSaving={isSaving}
        />
      </Col>
    </Row>
  );
}

UserForm.propTypes = {
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  errors: PropTypes.object
}

export default UserForm;
