import * as types from "./actionTypes"
import * as api from "../../api/liquidApi"
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function loadLiquidsSuccess(liquids) {
  return { type: types.LOAD_LIQUIDS_SUCCESS, liquids: liquids };
}

export function createLiquidSuccess(liquid) {
  return { type: types.CREATE_LIQUID_SUCCESS, liquid }
}

export function updateLiquidSuccess(liquid) {
  return { type: types.UPDATE_LIQUID_SUCCESS, liquid }
}

export function deletedLiquidSuccess(liquidId) {
  return { type: types.DELETE_LIQUID_SUCCESS, liquidId }
}

export function loadLiquids() {
  return function(dispatch) {
    dispatch(beginApiCall());
    return api
      .getLiquids()
      .then(liquids => {
        dispatch(loadLiquidsSuccess(liquids));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveLiquid(liquid) {
  return dispatch => {
    dispatch(beginApiCall());
    return api
      .saveLiquid(liquid)
      .then(savedLiquid => {
        liquid.id
          ? dispatch(updateLiquidSuccess(liquid))
          : dispatch(createLiquidSuccess(savedLiquid))
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  }
}

export function deleteLiquid(liquidId) {
  return dispatch => {
    return api
      .deleteLiquid(liquidId)
      .then(() => {
        dispatch(deletedLiquidSuccess(liquidId));
      })
  }
}
