import React from 'react'
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { toAmount, toDateTime } from '../../../../common/utils';
import { PropTypes } from 'prop-types';
import DateRangeSelect from '../../../common/DateRangeSelect';

const ContainerStateList = ({
  containerStates,
  initStartDate,
  initEndDate,
  onFilterChanged
}) => {

  const getStateType = type => {
    switch (type) {
      case "Add": return "Dolanie";
      case "Tank": return "Tankowanie";
      case "Liquid": return "Wymiana płynu";
      case "Refill": return "Uzupełnienie";
      case "Correction": return "Korekta";
      default: return "";
    }
  }

  return (
    <Card>
      <CardHeader>
        <h4>Historia zbiornika</h4>
        <hr />
      </CardHeader>
      <CardBody>
        <div style={{ marginLeft: 20, marginBottom: 20 }}>
          <DateRangeSelect
            initStart={initStartDate}
            initEnd={initEndDate}
            onRangeChanged={onFilterChanged}
          />
        </div>
        <Table className="table">
          <thead>
            <tr style={{ background: "#787d80", color: "white" }}>
              <th>Data</th>
              <th>Operacja</th>
              <th>Płyn</th>
              <th>Po zmianie</th>
              <th>Zmiana</th>
              <th>Osoba</th>
              <th>Komentarz</th>
            </tr>
          </thead>
          <tbody>
          {containerStates
            ? containerStates.map(state => {
              return (
                <tr key={state.id}>
                  <td>{toDateTime(state.change_date)}</td>
                  <td>{getStateType(state.type)}</td>
                  <td>{state.liquid.name}</td>
                  <td>{toAmount(state.liquid_amount)}</td>
                  <td>{toAmount(state.change_amount)}</td>
                  <td>{state.user ? state.user.last_name + ' ' + state.user.first_name : ''}</td>
                  <td>{state.comments}</td>
                </tr>
              )
            })
            : (<></>)}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

ContainerStateList.propTypes = {
  containerStates: PropTypes.array.isRequired,
  onFilterChanged: PropTypes.func.isRequired,
  initStartDate: PropTypes.object.isRequired,
  initEndDate: PropTypes.object.isRequired
}

export default ContainerStateList;
