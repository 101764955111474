import { apiCallError, beginApiCall } from './apiStatusActions';
import * as messageApi from '../../api/messageApi';
import * as containerApi from '../../api/containersApi';
import * as tankApi from '../../api/tankApi';

import * as types from './actionTypes';
import moment from 'moment';
import { dateToString } from '../../common/utils';

export function loadDashboardSuccess(dashboard) {
  return { type: types.LOAD_DASHBOARD_SUCCESS, dashboard };
}

export function loadDashboard() {

  const startDate = moment().add(-1, 'days');;
  const endDate = moment().add(1, 'days');

  return function(dispatch) {
    dispatch(beginApiCall());
    return containerApi
      .getContainers()
      .then(containers => {
        messageApi.getNewMessages()
          .then(messages => {
            tankApi.getTanks(dateToString(startDate), dateToString(endDate))
              .then(tanks => {
                dispatch(loadDashboardSuccess({
                  containers,
                  messages,
                  tanks
                }));
              })
          })
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
