import * as types from "./actionTypes"
import * as api from "../../api/userApi"
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function loadUsersSuccess(users) {
  return { type: types.LOAD_USERS_SUCCESS, users };
}

export function createUserSuccess(user) {
  return { type: types.CREATE_USER_SUCCESS, user }
}

export function updateUserSuccess(user) {
  return { type: types.UPDATE_USER_SUCCESS, user }
}

export function deletedUserSuccess(userId) {
  return { type: types.DELETE_USER_SUCCESS, userId }
}

export function loadUsers() {
  return function(dispatch) {
    dispatch(beginApiCall());
    return api
      .getUsers()
      .then(users => {
        dispatch(loadUsersSuccess(users));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveUser(user) {
  return dispatch => {
    dispatch(beginApiCall());
    return api
      .saveUser(user)
      .then(savedUser => {
        user.id
          ? dispatch(updateUserSuccess(savedUser))
          : dispatch(createUserSuccess(savedUser))
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  }
}

export function deleteUser(userId) {
  return dispatch => {
    return api
      .deleteUser(userId)
      .then(() => {
        dispatch(deletedUserSuccess(userId));
      })
  }
}
