import React from "react";
import { PropTypes } from "prop-types";
import SelectInput from "../../../common/SelectInput";
import { Card, CardBody, Col, Form, Row } from 'reactstrap';
import NumericInput from "../../../common/NumericInput";
import FormButtons from '../../../common/FormButtons';

const TankForm = ({
  tank,
  vehicles,
  liquids,
  mechanics,
  onChange,
  onSave,
  saving,
  errors = {}
}) => {

  return (
    <>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <Card>
            <CardBody>
              <Form>
                {errors.onSave && (
                  <div className="alert alert-danger" role="alert">
                    {errors.onSave}
                  </div>
                )}
                <SelectInput
                  name="vehicle_id"
                  label="Pojazd"
                  value={tank.vehicle_id || ""}
                  options={vehicles.map(vehicle => ({
                    value: vehicle.id,
                    text: vehicle.name
                  }))}
                  onChange={onChange}
                  error={errors.vehicle_id}
                />
                <SelectInput
                  name="liquid_id"
                  label="Pyn"
                  value={tank.liquid_id || ""}
                  options={liquids.map(liquid => ({
                    value: liquid.id,
                    text: liquid.name
                  }))}
                  onChange={onChange}
                  error={errors.liquid_id}
                />
                <SelectInput
                  name="user_id"
                  label="Mechanik"
                  value={tank.user_id || ""}
                  options={mechanics.map(user => ({
                    value: user.id,
                    text: user.first_name + ' ' + user.last_name
                  }))}
                  onChange={onChange}
                  error={errors.user_id}
                />
                <NumericInput
                  label="Ilość"
                  name="amount"
                  value={tank.amount}
                  onChange={onChange}
                  error={errors.amount}
                />
              </Form>
            </CardBody>
          </Card>
          <FormButtons
            onSave={onSave}
            isSaving={saving}
          />
        </Col>
      </Row>
    </>
  )
}

TankForm.propTypes = {
  tank: PropTypes.object.isRequired,
  vehicles: PropTypes.array.isRequired,
  liquids: PropTypes.array.isRequired,
  mechanics: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  errors: PropTypes.object
}

export default TankForm;
