import {
  handleResponse,
  handleError,
  apiUrl,
  postHeaders,
  getData,
  saveData,
  deleteData,
} from './apiUtils';
import * as demoRepo from './demo/repository';
const baseUrl = apiUrl + "/admin/users/";

export function getUsers() {
  return getData(baseUrl, () => demoRepo.getUsers());
}

export function saveUser(user) {
  return saveData(baseUrl, user, () => demoRepo.saveUser(user));
}

export function deleteUser(userId) {
  return deleteData(baseUrl, userId, () => demoRepo.deleteUser(userId));
}

export function changePwd(userId, password) {
  return fetch(baseUrl + "pwd/" + userId, {
    method: "PUT",
    headers: postHeaders(),
    body: JSON.stringify({ password })
  })
    .then(handleResponse)
    .catch(handleError);
}
