import * as types from "../actions/actionTypes"
import initialState from "./initialState";

export default function mechanicReducer(state = initialState.mechanics, action) {
  switch (action.type) {
    case types.LOAD_MECHANICS_SUCCESS:
      return action.mechanics;
    case types.CREATE_MECHANIC_SUCCESS:
      return [...state, {...action.mechanic}];
    case types.UPDATE_MECHANIC_SUCCESS:
      return state.map(mechanic => {
        return mechanic.id === action.mechanic.id
          ? action.mechanic
          : mechanic
      })
    case types.DELETE_MECHANIC_SUCCESS:
      return state.filter(mechanic => mechanic.id !== action.mechanicId);
    default:
      return state;
  }
}
