import * as types from "./actionTypes"
import * as api from "../../api/stationApi"
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function loadStationsSuccess(stations) {
  return { type: types.LOAD_STATIONS_SUCCESS, stations };
}

export function createStationSuccess(station) {
  return { type: types.CREATE_STATION_SUCCESS, station }
}

export function updateStationSuccess(station) {
  return { type: types.UPDATE_STATION_SUCCESS, station }
}

export function deletedStationSuccess(stationNumber) {
  return { type: types.DELETE_STATION_SUCCESS, stationNumber }
}

export function loadStations() {
  return dispatch => {
    dispatch(beginApiCall());
    api.getStations()
      .then(liquids => {
        dispatch(loadStationsSuccess(liquids));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveStation(station) {
  return dispatch => {
    dispatch(beginApiCall());
    return api
      .saveStation(station)
      .then(savedStation => {
        if (station.id)
          dispatch(updateStationSuccess(station));
        else {
          dispatch(createStationSuccess(savedStation));
        }
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  }
}

export function deleteStation(stationNumber) {
  return dispatch => {
    return api
      .deleteStation(stationNumber)
      .then(() => {
        dispatch(deletedStationSuccess(stationNumber));
      })
  }
}

export function addPump(stationNumber, pump) {
  return dispatch => {
    dispatch({ type: types.ADD_PUMP, stationNumber, pump });
  }
}

export function updatePump(stationNumber, pump) {
  return dispatch => {
    dispatch({ type: types.UPDATE_PUMP, stationNumber, pump });
  }
}
