import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import ContainersCard from './containers/ContainersCard';
import MessagesCard from './messages/MessagesCard';
import TanksCard from './tanks/TanksCard';

const HomePage = ({
  dashboard
}) => {
    return (
      <>
        { dashboard && (
          <div>
            <Row>
              <Col md={6}>
                <ContainersCard
                  containers={dashboard.containers}
                />
              </Col>
              <Col md={6}>
                <MessagesCard
                  messages={dashboard.messages}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <TanksCard
                  tanks={dashboard.tanks}
                />
              </Col>
            </Row>
          </div>
        )}
      </>
    );
}

HomePage.propTypes = {
  dashboard: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    dashboard: state.dashboard
  };
}

export default connect(
  mapStateToProps,
  null
)(HomePage);
