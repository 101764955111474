import React, { useState, useEffect } from "react";
import ContainerForm from "./ContainerForm";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { newContainer } from "../../../../redux/reducers/initialState";
import * as containerActions from "../../../../redux/actions/containerAction";
import * as liquidActions from "../../../../redux/actions/liquidAction";
import { toast } from "react-toastify";
import { Loader } from '../../../../vibe';

const ContainerEditPage = ({
  containers,
  loadContainers,
  liquids,
  loadLiquids,
  saveContainer,
  history,
  ...props
}) => {

  const isEditMode = !!props.match.params.id;

  const [container, setContainer] = useState({...props.container});
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (containers.length === 0) {
      setLoading(true);
      loadContainers();
      setLoading(false);
    }
    else {
      setContainer({...props.container});
    }

    if (liquids.length === 0) {
      loadLiquids();
    }
  }, [props.container])

  function handleChange(event) {
    const { name, value, type } = event.target;
    if (name === "liquid_id") {
      const liquidId = parseInt(value, 10);
      const liquid = liquids.find(l => l.id === liquidId);
      setContainer(prevState => ({
        ...prevState,
        "liquid_id": liquidId,
        "name": "Zbiornik " + liquid.name
      }))
    }
    else {
      setContainer(prevState => ({
        ...prevState,
        [name]: type === "number"
          ? parseFloat(value)
          : value
      }))
    }
  }

  function formIsValid() {
    const errors = {};

    if (!container.liquid_id)
      errors.liquid_id = "Płyn jest wymagany";
    if (!container.name)
      errors.name = "Nazwa jest wymagana";
    if (containers.find(c => c.name === container.name && c.id !== container.id))
      errors.name = "Nazwa musi być unikatowa";
    if (container.capacity <= 0)
      errors.capacity = "Pojemność zbiornika musi być wartością dodatnią";
    if (container.capacity < container.liquid_amount)
      errors.capacity = "Pojemność zbiornika musi być większa niż aktualna ilość";
    if (container.capacity >= 100000)
      errors.capacity = "Pojemność zbiornika nie może być większa niż 99999";
    if (container.liquid_amount < container.min_level)
      errors.liquid_amount = "Aktualna ilość nie może być mniejsza niż poziom minimalny";
    if (container.liquid_amount > container.capacity)
      errors.liquid_amount = "Aktualna ilość nie może być większa od pojemności zbiornika";
    if (container.min_level <= 0)
      errors.min_level = "Poziom minimalny musi być wartością dodatnią";
    if (container.min_level >= container.liquid_amount)
      errors.min_level = "Minimalny poziom musi być mniejszy od aktualnej ilości";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function handleSave(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    setSaving(true);

    let localStore = require('store');
    const user = localStore.get('user');
    container.user_id = user.id;

    saveContainer(container)
      .then(() => {
        history.push("/containers");
        toast.success("Dane zbiornika zostały zapisane.");
      })
      .catch(error => {
        setSaving(false);
        setErrors( { onSave: error.message });
      })
  }

  return (
    <>
      { loading
       ? <Loader type="bars"/>
       : <ContainerForm
          container={container}
          liquids={liquids}
          onSave={handleSave}
          onChange={handleChange}
          errors={errors}
          isSaving={saving}
          isEditMode={isEditMode}
        />
      }
    </>
  );
}

ContainerEditPage.propTypes = {
  containers: PropTypes.array.isRequired,
  loadContainers: PropTypes.func.isRequired,
  container: PropTypes.object.isRequired,
  liquids: PropTypes.array.isRequired,
  loadLiquids: PropTypes.func.isRequired,
  saveContainer: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const getContainerById = (containers, containerId) => {
  return containers.find(container => container.id === containerId);
}

const mapStateToProps = (state, ownProps) => {
  const containerId = ownProps.match.params.id;
  const container =
    containerId && state.containers.length > 0
      ? getContainerById(state.containers, parseInt(containerId))
      : newContainer;
  return {
    container: container,
    containers: state.containers,
    liquids: state.liquids
  };
}

const mapDispatchToProps = {
  loadLiquids: liquidActions.loadLiquids,
  loadContainers: containerActions.loadContainers,
  saveContainer: containerActions.saveContainer
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerEditPage);
