import React from 'react';
import { ListGroup, ListGroupItem, Table } from 'reactstrap';
import { toAmount } from '../../../../common/utils';
import TdDate from '../../../common/TdDate';
import TdAmount from '../../../common/TdAmount';
import TraceChart from './TraceChart';
import { PropTypes } from 'prop-types';

const TanksLiquidList = ({
  tanks,
  showTankTrace
}) => {

  const getTanksGroupedByLiquid = () => {
    let liquids = tanks.map(tank => {
      return tank.order_line.liquid.name;
    })
    liquids = [...new Set(liquids)];
    return liquids.map(liquid => {
      return {
        liquid: liquid,
        sum: tanks
          .filter(tank => tank.order_line.liquid.name === liquid)
          .map(tank => tank.amount)
          .reduce((prev, next) => prev + next),
        rows: tanks
          .filter(tank => tank.order_line.liquid.name === liquid)
          .map(tank => {
            return {
              id: tank.id,
              date: tank.date,
              vehicle: tank.order_line.order.number,
              liquid: tank.order_line.liquid.name,
              mechanic: tank.order_line.mechanic.first_name + ' ' + tank.order_line.mechanic.last_name,
              amount: tank.amount,
              trace: tank.trace
            }
          })
      }
    })
  }

  return (
    <>
      <Table>
        <thead>
        <tr style={{ background: "#787d80", color: "white" }}>
          <th width={170}>Data</th>
          <th width={300}>Pojazd</th>
          <th width={300}>Mechanik</th>
          <th width={100}>Ilość</th>
        </tr>
        </thead>
      </Table>
      <ListGroup id="tankByVehicleList">
        {getTanksGroupedByLiquid().map((group, index) => {
          return (
            <div key={index}>
              <ListGroupItem color="primary">
                {group.liquid}
                <div className="pull-right">Suma: <b>{toAmount(group.sum)}</b></div>
              </ListGroupItem>
              <Table hover>
                <tbody>
                {group.rows.map(tank => {
                  return (
                    <React.Fragment key={tank.id}>
                      <tr>
                        <TdDate date={tank.date} />
                        <td width={300}>{tank.vehicle}</td>
                        <td width={300}>{tank.mechanic}</td>
                        <TdAmount amount={tank.amount}/>
                      </tr>
                      { showTankTrace &&
                      <TraceChart trace={tank.trace} />
                      }
                    </React.Fragment>
                  )
                })}
                </tbody>
              </Table>
            </div>
          )
        })}
      </ListGroup>
    </>
  )
}

TanksLiquidList.propTypes = {
  tanks: PropTypes.array.isRequired,
  showTankTrace: PropTypes.bool.isRequired
}

export default TanksLiquidList;
