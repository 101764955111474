import React from 'react';
import { PropTypes } from 'prop-types';
import { Button } from 'reactstrap';

const PumpListRow = ({
  pump,
  containers,
  disabled,
  onEdit,
  onDelete,
  showDelete
}) => {
  return (
    <tr>
      <td>{pump.number}</td>
      <td>{ containers.length > 0  && containers.find(c => c.id === pump.container_id).name }</td>
      <td>{pump.wk}</td>
      <td>{pump.bs}</td>
      <td width={190}>
        { !disabled && (
          <>
            <Button
              color="primary"
              outline
              onClick={() => onEdit(pump.number)}
              disabled={disabled}
            >
              Edytuj
            </Button>
            {' '}
            {showDelete &&
              <Button
                color="danger"
                outline
                onClick={onDelete}
              >
                Usuń
              </Button>
            }
          </>
        )}
      </td>
    </tr>
  )
}

PumpListRow.propTypes = {
  pump: PropTypes.object.isRequired,
  containers: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  showDelete: PropTypes.bool.isRequired
}

export default PumpListRow;
