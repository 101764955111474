import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { newStation } from '../../../../redux/reducers/initialState';
import { PropTypes } from "prop-types"
import { toast } from "react-toastify";
import StationForm from "./StationForm";
import { Loader } from '../../../../vibe';
import { Col, Row } from 'reactstrap';
import PumpList from './PumpList/PumpList';
import * as containerActions from '../../../../redux/actions/containerAction';
import * as stationActions from "../../../../redux/actions/stationAction";
import FormButtons from '../../../common/FormButtons';

const StationEditPage =
({
  stations,
  containers,
  loadStations,
  saveStation,
  loadContainers,
  history,
  ...props
}) => {

  const [station, setStation] = useState({...props.station});
  const [errors, setErrors] = useState({})
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (stations.length === 0) {
      setLoading(true);
      loadStations();
      setLoading(false);
    }
    else {
      setStation({...props.station});
    }

    if (containers.length === 0) {
      loadContainers();
    }
  }, [props.station]);

  function handleChange(event) {
    const { name, value } = event.target;
    setStation(prevStation => ({
       ...prevStation,
       [name]: value
     }));
  }

  const handleAddPump = pump => {
    setStation( prevStation => ({
      ...prevStation,
      pumps: [...prevStation.pumps, pump]
    }));
  }

  const handleEditPump = pumpEdited => {
    setStation( prevStation => ({
      ...prevStation,
      pumps: prevStation.pumps.map(pump => {
        return pump.id === pumpEdited.id
          ? pumpEdited
          : pump
      })
    }));
  }

  const handleDeletePump = pumpDeleted => {
    setStation( prevStation => ({
      ...prevStation,
      pumps: prevStation.pumps.filter(pump => pump.id !== pumpDeleted.id)
    }));
  }

  function formIsValid() {
    const errors = {};

    if (!station.name)
      errors.name = "Nazwa jest wymagana";
    if(stations.find(l => l.name === station.name && l.number !== station.number))
      errors.name = "Nazwa musi być unikatowa";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function handleSave(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    setSaving(true);
    saveStation(station)
      .then(() => {
        history.push("/stations")
        toast.success("Dane zostały zapisane");
      })
      .catch(error => {
        setSaving(false);
        setErrors( { onSave: error.message });
      })
  }

  return (
    <>
      {loading
        ? <Loader type="bars"/>
        : (
          <>
            <Row>
              <Col md={{ size: 8, offset: 2 }}>
                <StationForm
                  station={station}
                  onChange={handleChange}
                  errors={errors}
                />
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 8, offset: 2 }}>
                <PumpList
                  station={station}
                  pumps={station.pumps}
                  containers={containers}
                  onAdd={handleAddPump}
                  onEdit={handleEditPump}
                  onDelete={handleDeletePump}
                />
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 8, offset: 2 }}>
                <FormButtons
                  onSave={handleSave}
                  isSaving={saving}
                />
              </Col>
            </Row>
          </>
        )}
    </>
  );
}

function getStationByNumber(stations, stationNumber) {
  return stations.find(station => station.number === stationNumber) || null;
}

function mapStateToProps(state, ownProps) {
  const stationNumber = ownProps.match.params.stationNumber;
  const station =
    stationNumber && state.stations.length > 0
      ? getStationByNumber(state.stations, parseInt(stationNumber))
      : newStation;
  if (!stationNumber)
    station.number = Math.max(...state.stations.map(o => o.number), 0) + 1;

  return {
    station,
    stations: state.stations,
    containers: state.containers
  }
}

const mapDispatchToProps = {
  loadStations: stationActions.loadStations,
  saveStation: stationActions.saveStation,
  loadContainers: containerActions.loadContainers
}

StationEditPage.propTypes = {
  stations: PropTypes.array.isRequired,
  containers: PropTypes.array.isRequired,
  loadStations: PropTypes.func.isRequired,
  saveStation: PropTypes.func.isRequired,
  loadContainers: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  station: PropTypes.object.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StationEditPage);
