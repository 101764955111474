import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as actions from "../../../../redux/actions/vehicleAction"
import { newVehicle } from "../../../../redux/reducers/initialState"
import { PropTypes } from "prop-types"
import { toast } from "react-toastify";
import VehicleForm from "./VehicleForm";
import { Loader } from '../../../../vibe';
import { demo } from '../../../../api/apiUtils';

const VehicleEditPage =
({
  vehicles,
  loadVehicles,
  saveVehicle,
  history,
  ...props
}) => {

  const [vehicle, setVehicle] = useState({...props.vehicle});
  const [errors, setErrors] = useState({})
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardAssigning, setCardAssigning] = useState(false);

  useEffect(() => {
    if (vehicles.length === 0) {
      setLoading(true);
      loadVehicles();
      setLoading(false);
    }
    else {
      setVehicle({...props.vehicle});
    }
  }, [props.vehicle]);

  const handleCardAssign = () => {
    setVehicle({...vehicle, rfid_code: ''});
    setCardAssigning(true);
  }

  const handleCancelCardAssign = () => {
    setCardAssigning(false);

    if (demo)
      setVehicle({...vehicle, rfid_code: Math.random().toString(36).substring(3)});
  }

  function handleChange(event) {
    const { name, value } = event.target;
     setVehicle(prev => ({
       ...prev,
       [name]: value
     }));
  }

  function formIsValid() {
    const errors = {};

    if (!vehicle.name)
      errors.name = "Nazwa jest wymagana";
    if(vehicles.find(l => l.name === vehicle.name && l.id !== vehicle.id))
      errors.name = "Nazwa musi być unikatowa";

    if (!vehicle.rfid_code)
      errors.rfid_code = "Nie przypisałeś karty";
    if(vehicles.find(l => l.rfid_code === vehicle.rfid_code && l.id !== vehicle.id))
      errors.rfid_code = "Karta jest już używana";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function handleSave(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    setSaving(true);
    saveVehicle(vehicle)
      .then(() => {
        history.push("/vehicles")
        toast.success("Dane pojazdu zostały zapisane");
      })
      .catch(error => {
        setSaving(false);
        setErrors( { onSave: error.message });
      })
  }

  return (
    <>
      {loading
        ? <Loader type="bars"/>
        : <VehicleForm
            onSave={handleSave}
            vehicle={vehicle}
            onChange={handleChange}
            isSaving={saving}
            errors={errors}
            cardAssigning={cardAssigning}
            onCardAssign={handleCardAssign}
            onCancelCardAssign={handleCancelCardAssign}
        />
      }
    </>
  );
}

function getVehicleById(vehicles, vehicleId) {
  return vehicles.find(vehicle => vehicle.id === vehicleId) || null;
}

function mapStateToProps(state, ownProps) {
  const vehicleId = ownProps.match.params.id;
  const vehicle =
    vehicleId && state.vehicles.length > 0
      ? getVehicleById(state.vehicles, parseInt(vehicleId))
      : newVehicle;
  return {
    vehicle,
    vehicles: state.vehicles
  }
}

const mapDispatchToProps = {
  loadVehicles: actions.loadVehicles,
  saveVehicle: actions.saveVehicle
}

VehicleEditPage.propTypes = {
  vehicles: PropTypes.array.isRequired,
  loadVehicles: PropTypes.func.isRequired,
  saveVehicle: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  vehicle: PropTypes.object.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleEditPage);
