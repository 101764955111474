import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Form, Row, Col } from 'reactstrap';
import "./LoginPage.css";
import TextInput from '../views/common/TextInput';
import * as authApi from '../api/authApi';
import { Loader } from '../vibe';
import { PropTypes } from 'prop-types';
import { demo } from '../api/apiUtils';

const LoginPage = ({
  history
}) => {

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("")
  const [pwdError, setPwdError] = useState("")
  const [apiError, setApiError] = useState("")
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let localStore = require('store');
    if (!localStore.get('user'))
      localStore.set('user', {
        id: null,
        full_name: '',
        nick_name: '',
        user_name: '',
        authenticated: false,
        admin: false
      });
    let user = localStore.get('user');
    setLogin(user.user_name);

    if (demo) {
      setLogin('demo');
      setPassword('demo');
    }
  }, [])

  function validateForm() {
    if (login.length === 0)
      setLoginError("Musisz podać login");
    else
      setLoginError("");
    if (password.length === 0)
      setPwdError("Musisz podać hasło");
    else
      setPwdError("");

    return login.length > 0 && password.length > 0;
  }

  function loginUser(user) {
    let localStore = require('store');
    localStore.set('user', {
      id: user.id,
      full_name: user.first_name + ' ' + user.last_name,
      nick_name: user.first_name[0] + user.last_name[0],
      user_name: user.username,
      password: user.password,
      authenticated: true,
      admin: user.role === 'Admin'
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    if(!validateForm()) return;
    setLoading(true);
    authApi.loginUser(login, password)
      .then(loggedUser => {
        loginUser(loggedUser);
        history.push("/");
      })
      .catch(error => {
        setLoading(false);
        setLogin("");
        setPassword("");
        setApiError(error.toString());
      });
  }

  return (
    <div className="Login">
      <Row>
        <Col md={{ size: 4, offset: 4 }}>
          <Card body>
            <CardBody>
              { !loading
                ? (
                  <Form>
                    {apiError && (
                      <div className="alert alert-danger" role="alert">
                        {apiError}
                      </div>
                    )}
                    <TextInput
                      label="Login"
                      name="login"
                      value={login}
                      onChange={e => setLogin(e.target.value)}
                      error = {loginError}
                    />
                    <TextInput
                      label="Hasło"
                      name="password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      isPassword={true}
                      error={pwdError}
                    />
                    <Button
                      type="submit"
                      block
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Zaloguj
                    </Button>
                  </Form>
                ) : (<Loader type="bars"/>)}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

LoginPage.propTypes = {
  history: PropTypes.object.isRequired
}

export default LoginPage;
