import { apiCallError, beginApiCall } from './apiStatusActions';
import * as api from '../../api/settingsApi';
import * as types from "./actionTypes"

export function loadSettings() {
  return dispatch => {
    dispatch(beginApiCall());
    return api
      .getSettings()
      .then(settings => {
        dispatch({ type: types.LOAD_SETTINGS_SUCCESS, settings })
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  }
}

export function saveSettings(values) {
  return dispatch => {
    dispatch(beginApiCall());
    return api
      .saveSettings(values)
      .then(settings => {
        dispatch({ type: types.UPDATE_SETTINGS_SUCCESS, settings })
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  }
}
