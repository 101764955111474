
export async function handleResponse(response) {
  if (response.status === 204) return;
  if (response.ok) return response.json();
  if (response.status === 401)
    window.location = window.location.origin + "/login";
  // if (response.status === 404)
  //   window.location = window.location.origin + "/404";

  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.json();
    throw new Error(error.message);
  }
  throw new Error("Network response was not ok.");
}

window.beforeunload = (e) => {
  console.log('Stop this');
  e.preventDefault()
  console.log('Stop this');
  e.returnValue = '';
};

export const demo = 1;

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error("API call failed. " + error);
  throw error;
}

// export const apiUrl = window.location.origin + "/api";
export const apiUrl = "http://127.0.0.1:8880/api"

export const getHeaders = () => {
  let localStore = require('store');
  let user = localStore.get('user');
  let headers = new Headers();
  if (user) {
    let auth = 'Basic ' + btoa(user.user_name + ':' + user.password);
    headers.append("Authorization", auth);
  }
  return headers;
}

export const postHeaders = () => {
  let headers = getHeaders();
  headers.append("content-type", "application/json");
  return headers;
}

export const getData = (url, demoGetFunc) => {
  if (demo)
    return Promise.resolve(demoGetFunc());

  return fetch(url, {
    method:'GET',
    headers: getHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export const saveData = (url, data, demoSaveFunc) => {
  if (demo)
    return demoApiCall(demoSaveFunc(data), 1000);

  return fetch(url + (data.id || ""), {
    method: data.id ? "PUT" : "POST",
    headers: postHeaders(),
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteData(url, id, demoDelFunc) {
  if (demo) {
    demoDelFunc(id);
    return demoApiCall(200, 1000);
  }

  return fetch(url + id, {
    method: "DELETE",
    headers: getHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export const demoApiCall = (retData, timeout) => {
  let promise = new Promise(resolve => {
    let wait = setTimeout(() => {
      clearTimeout(wait);
      resolve(retData);
    }, timeout)
  })

  return Promise.race([promise]);
}
