import React from 'react';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';

const BackButton = ({
  history
}) => {
  return <Button
    className="pull-right"
    onClick={() => history.goBack()}
    color="secondary"
    outline
  >
    <i className={"fa fa-reply"} style={{ paddingRight: 8 }}/>
    Wróć
  </Button>
}

export default withRouter(BackButton);
