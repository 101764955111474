import React from 'react'
import { Button } from 'reactstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { toAmount, toDateTime } from '../../../../common/utils';

export const ExportToExcel = ({tanks, fileName}) => {

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = (tanks, fileName) => {
    const excelData = tanks.map(tank => {
      return {
        "Id": tank.id,
        "Data": toDateTime(tank.date),
        "Pojazd": tank.order_line.order.number,
        "Płyn": tank.order_line.liquid.name,
        "Mechanik": tank.order_line.mechanic.first_name + ' ' + tank.order_line.mechanic.last_name,
        "Wydana ilość": toAmount(tank.amount)
      }
    })
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  return (
    <Button
      color="success"
      outline
      onClick={() => exportToExcel(tanks, fileName)}
      style={{ width: 100, marginRight: 10 }}
    >
      EXCEL
    </Button>
  )
}
