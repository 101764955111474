import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import ToolButton from '../../../common/ToolButton';
import BackButton from '../../../common/BackButton';

const MechanicTools = ({
  history
}) => {
  return (
    <Card>
      <CardBody>
        <ToolButton
          color={"primary"}
          handleClick={() => history.push("/mechanic")}
          icon={"plus"}
          text={"Dodaj Mechanika"}
        />
        <BackButton />
      </CardBody>
    </Card>
  )
}

export default withRouter(MechanicTools);
