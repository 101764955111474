import React, { useEffect, useState } from "react";
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { newContainer } from '../../../../redux/reducers/initialState';
import * as actions from '../../../../redux/actions/containerAction';
import { Loader } from '../../../../vibe';
import { Row, Col } from 'reactstrap';
import RefillForm from './RefillForm';
import { toast } from 'react-toastify';
import ContainerInfoCard from '../common/ContainerInfoCard';
import ContainerChartCard from '../common/ContainerChartCard';

const ContainerRefillPage = ({
  containers,
  loadContainers,
  refillContainer,
  history,
  ...props
}) => {

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({})

  const [container, setContainer] = useState({...props.container});
  const [comments, setComments] = useState('');

  useEffect(() => {
      if (containers.length === 0) {
        setLoading(true);
        loadContainers()
        setLoading(false);
      }
      else {
        setContainer({...props.container});
      }
  }, [props.container])

  const handleChangeComments = (event) => {
    const { value } = event.target;
    setComments(value);
  }

  function handleSave(event) {
    event.preventDefault();
    setSaving(true);
    refillContainer(container, comments)
      .then(() => {
        history.goBack();
        toast.success("Zbiornik został uzupełniony");
      })
      .catch(error => {
        setSaving(false);
        setErrors( { onSave: error.message });
      })
  }

  return (
   loading
    ? <Loader type="bars" />
    : (<>
       <Row>
         <Col md={{ size: 9, offset: 1 }}>
           <ContainerChartCard
             container={container}
             tankAmount={container.capacity - container.liquid_amount}
           />
         </Col>
       </Row>
      <Row>
        <Col md={{ size: 5, offset: 1 }}>
          <RefillForm
            comments={comments}
            onChangeComments={handleChangeComments}
            isSaving={saving}
            onSave={handleSave}
            errors={errors}
          />
        </Col>
        <Col md={4}>
          <ContainerInfoCard
            container={container}
            newAmount={container.capacity}
          />
        </Col>
      </Row>
    </>)
  );
}

ContainerRefillPage.propTypes = {
  container: PropTypes.object.isRequired,
  containers: PropTypes.array.isRequired,
  loadContainers: PropTypes.func.isRequired,
  refillContainer: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const containerId = ownProps.match.params.id;
  const container = containerId && state.containers.length > 0
    ? state.containers.find(c => c.id === parseInt(containerId))
    : newContainer;
  return {
    container: container,
    containers: state.containers
  }
}

const mapDispatchToProps = {
  loadContainers: actions.loadContainers,
  refillContainer: actions.refillContainer
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerRefillPage);
