import { handleResponse, handleError, apiUrl, demo, demoApiCall } from './apiUtils';
import * as db from './demo/db';

const baseUrl = apiUrl + "/warehouse/users/login";

export function loginUser(username, password) {
  if (demo)
    return demoApiCall(db.user, 3000);

  return fetch(baseUrl, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      username,
      password
    })
  })
    .then(handleResponse)
    .catch(handleError);
}

