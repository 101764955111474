import * as types from "../actions/actionTypes"
import initialState from "./initialState";

export default function tankReducer(state = initialState.tanks, action) {
  switch (action.type) {
    case types.LOAD_TANKS_SUCCESS:
      return action.tanks;
    default:
      return state;
  }
}
