import React, { useState, useEffect } from "react";
import { Loader } from '../../../../vibe';
import TankForm from './TankForm';
import { newTank } from '../../../../redux/reducers/initialState';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import * as tankActions from '../../../../redux/actions/tankAction';
import * as vehicleActions from '../../../../redux/actions/vehicleAction';
import * as liquidActions from '../../../../redux/actions/liquidAction';
import * as mechanicActions from '../../../../redux/actions/mechanicActions';

const TanksEditPage = ({
  // tanks,
  // loadTanks,
  saveTank,
  vehicles,
  loadVehicles,
  liquids,
  loadLiquids,
  mechanics,
  loadMechanics,
  history,
  ...props
}) => {

  const [tank, setTank] = useState({newTank});
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // if (tanks.length === 0) {
    //   setLoading(true);
    //   loadTanks();
    //   setLoading(false);
    // }
    // else {
    //   setTank({...props.tank});
    // }

    if (vehicles.length === 0) {
      loadVehicles();
    }
    if (liquids.length === 0) {
      loadLiquids();
    }
    if (mechanics.length === 0) {
      loadMechanics();
    }
  }, [props.tank])

  const parseValue = (type, value) => {
    switch (type) {
      case "number":
        return parseFloat(value);
      case "select":
        return parseInt(value, 10);
      default:
        return value;
    }
  }

  const handleChange = event => {
    const { name, value, type } = event.target;
    setTank(prevState => ({
      ...prevState,
      [name]: parseValue(type, value)
    }))
  }

  function randomInt(min, max) {
    return min + Math.floor((max - min) * Math.random());
  }

  const handleSave = event => {
    console.log(tank);
    const today = new Date();
    const date =
      String(randomInt(1, 28)).padStart(2, '0') + "-" +
      String(today.getMonth() + 1).padStart(2, '0') + "-" +
      today.getFullYear() + " " +
      String(randomInt(1, 23)).padStart(2, '0') + ":" +
      String(randomInt(0, 59)).padStart(2, '0')
    saveTank({
      amount: tank.amount,
      date: date,
      liquid_id: parseInt(tank.liquid_id, 10),
      user_id: parseInt(tank.user_id, 10)
    }, tank.vehicle_id)
  }

  return (
    <>
      { loading
        ? <Loader type="bars"/>
        : <TankForm
            tank={tank}
            vehicles={vehicles}
            liquids={liquids}
            mechanics={mechanics}
            onSave={handleSave}
            onChange={handleChange}
            errors={errors}
            saving={saving}
          />
      }
    </>
  );
}

TanksEditPage.propTypes = {
  tanks: PropTypes.array.isRequired,
  loadTanks: PropTypes.func.isRequired,
  saveTank: PropTypes.func.isRequired,
  tank: PropTypes.object.isRequired,
  vehicles: PropTypes.array.isRequired,
  loadVehicles: PropTypes.func.isRequired,
  liquids: PropTypes.array.isRequired,
  loadLiquids: PropTypes.func.isRequired,
  mechanics: PropTypes.array.isRequired,
  loadMechanics: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const getTankById = (tanks, tankId) => {
  return tanks.find(tank => tank.id === tankId);
}

const mapStateToProps = (state, ownProps) => {
  const tankId = ownProps.match.params.id;
  const tank =
    tankId && state.tanks.length > 0
      ? getTankById(state.tanks, parseInt(tankId))
      : newTank;
  return {
    // tank: tank,
    // tanks: state.tanks,
    vehicles: state.vehicles,
    liquids: state.liquids,
    mechanics: state.mechanics
  };
}

const mapDispatchToProps = {
  // loadTanks: tankActions.loadTanks,
  saveTank: tankActions.saveTank,
  loadVehicles: vehicleActions.loadVehicles,
  loadLiquids: liquidActions.loadLiquids,
  loadMechanics: mechanicActions.loadMechanics
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TanksEditPage);
