import React from 'react';
import { PropTypes } from 'prop-types';
import SelectInput from '../../../../common/SelectInput';
import NumericInput from '../../../../common/NumericInput';
import { Button } from 'reactstrap';

const PumpListRowEdit = ({
  pump,
  containers,
  onChange,
  onOk,
  onCancel,
  errors = {}
}) => {

  return (
    <tr>
      <td>{pump.number}</td>
      <td>
        <SelectInput
          name="container_id"
          label=""
          value={pump.container_id || ""}
          options={containers.map(container => ({
            value: container.id,
            text: container.name
          }))}
          onChange={onChange}
          error={errors.container_id}
        />
      </td>
      <td>
        <NumericInput
          label=""
          onChange={onChange}
          name="wk"
          value={pump.wk}
          error={errors.wk}
          isInt={true}
        />
      </td>
      <td>
        <NumericInput
          label=""
          onChange={onChange}
          name="bs"
          value={pump.bs}
          error={errors.bs}
          isInt={true}
        />
      </td>
      <td>
        <Button
          color="success"
          outline
          onClick={onOk}
        >
          Ok
        </Button>
        {' '}
        <Button
          color="danger"
          outline
          onClick={onCancel}
        >
          Anuluj
        </Button>
      </td>
    </tr>
  )
}

PumpListRowEdit.propTypes = {
  pump: PropTypes.object.isRequired,
  containers: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  errors: PropTypes.object
}

export default PumpListRowEdit;
