import React, { useEffect } from 'react';
import * as actions from '../../../../redux/actions/stationAction';
import { connect } from 'react-redux';
import { StationList } from './StationList';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Card, CardBody } from 'reactstrap';
import StationTools from './StationTools';

const StationsPage = ({
  stations,
  loadStations,
  deleteStation,
  history
}) => {

  useEffect(() => {
    loadStations();
  }, [])

  function handleDelete(stationNumber) {
    return  deleteStation(stationNumber)
      .then(() => toast.success("Punkt poboru został usunięty"))
  }

  return (
    <>
      <StationTools history={history} />
      <Card>
        <CardBody>
          <StationList
            stations={stations}
            onDelete={handleDelete}
          />
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = state => {
  return {
    stations: state.stations,
    loading: state.apiCallsInProgress > 0
  };
}

const mapDispatchToProps = {
  loadStations: actions.loadStations,
  deleteStation: actions.deleteStation,
}

StationsPage.propTypes = {
  stations: PropTypes.array.isRequired,
  loadStations: PropTypes.func.isRequired,
  deleteStation: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StationsPage);
