import React, { useState } from "react"
import { PropTypes } from "prop-types"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import { toast } from "react-toastify";

const DeleteButton = ({
  onDelete,
  deletingItemId
}) => {

  const [isDeleting, setIsDeleting] = useState(false);
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  function handleDelete() {
    setIsDeleting(true);
    onDelete(deletingItemId).catch( () => {
      toast.error("Nie udało się usunąć rekordu")
      setIsDeleting(false)
    });
    toggleModal();
  }

  return (
    <>
      <Button
        id={"deleteButton" + deletingItemId}
        color="danger"
        onClick={toggleModal}
        outline
        disabled={isDeleting}
      >
        <i className="fa fa-trash-o"/>
      </Button>
      <UncontrolledTooltip
        placement="top"
        target={"deleteButton" + deletingItemId}
      >
        Usuń
      </UncontrolledTooltip>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalBody>
          Czy napewno chcesz usunąć rekord?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>Usuń</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Anuluj</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

DeleteButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
  deletingItemId: PropTypes.number.isRequired
}

export default DeleteButton;
