import {
  apiUrl,
  getData,
  saveData,
  deleteData,
} from './apiUtils';
import * as demoRepo from './demo/repository';

const baseUrl = apiUrl + "/admin/containers/";

export function getContainers() {
  return getData(baseUrl, () => demoRepo.getContainers());
}

export function saveContainer(container) {
  return saveData(baseUrl, container, () => demoRepo.saveContainer(container));
}

export function deleteContainer(containerId) {
  return deleteData(baseUrl, containerId, () => demoRepo.deleteContainer(containerId));
}
