export const newLiquid = {
  id: null,
  name: "",
  dms_code: "",
  containers_count: 0
};

export const newContainer = {
  id: null,
  liquid_id: null,
  liquid: newLiquid,
  user_id: null,
  name: "",
  capacity: 200,
  liquid_amount: 200,
  available_amount: 190,
  alarm_level: 50,
  min_level: 10,
  pumps_count: 0,
  pumps: []
}
export const newUser = {
  id: null,
  username: "",
  password: "",
  first_name: "",
  last_name: "",
  role: "",
  pin_code: ""
}

export const newMechanic = {
  id: null,
  first_name: "",
  last_name: "",
  pin_code: "",
  rfid_code: ""
}

export const newStation = {
  id: null,
  name: "",
  number: 0,
  description: "",
  pumps: []
}

export const newPump = {
  id: 0,
  container_id: null,
  distributor_id: null,
  number: 1,
  wk: 100,
  bs: 0
}

export const newTank = {
  id: null,
  date: '',
  amount: 0,
  liquid_id: null,
  number: "",
  user_id: null
}

export const newVehicle = {
  id: null,
  name: "",
  rfid_code: ""
};

export default {
  courses: [],
  authors: [],
  liquids: [],
  containers: [],
  containerStates: [],
  mechanics: [],
  users: [],
  stations: [],
  tanks: [],
  vehicles: [],
  messages: [],
  dashboard: {},
  settings: {},
  orders: [],
  apiCallsInProgress: 0
};
