import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/mechanicActions';
import { newMechanic } from '../../../../redux/reducers/initialState';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import MechanicForm  from './MechanicForm';
import { Loader } from '../../../../vibe';
import { demo } from '../../../../api/apiUtils';

const MechanicEditPage = ({
  mechanics,
  settings,
  loadMechanics,
  saveMechanic,
  history,
  ...props
}) => {
  const [mechanic, setMechanic] = useState({...props.mechanic});
  const [errors, setErrors] = useState({})
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardScanning, setCardScanning] = useState(false);

  useEffect(() => {
    if (mechanics.length === 0) {
      setLoading(true);
      loadMechanics();
      setLoading(false);
    }
    else {
      setMechanic({...props.mechanic});
    }
  }, [props.mechanic]);

  const handleCardAssign = () => {
    setMechanic({...mechanic, rfid_code: ''});
    setCardScanning(true);
  }

  const handleCancelCardAssign = () => {
    setCardScanning(false);

    if (demo)
      setMechanic({...mechanic, rfid_code: Math.random().toString(36).substring(3)});
  }

  function handleChange(event) {
    const { name, value } = event.target;
    if (name === "pin_code" && !"0123456789".includes(value.slice(-1))) return;
    setMechanic(prevMec => ({
      ...prevMec,
      [name]: value
    }));
  }

  function formIsValid() {
    const errors = {};
    if (!mechanic.last_name)
      errors.last_name = "Nazwisko jest wymagane";
    if (!mechanic.first_name)
      errors.first_name = "Imię jest wymagane";
    if (!mechanic.pin_code)
      errors.pin_code = "Kod pin jest wymagany";
    if(mechanics.find(m => m.pin_code === mechanic.pin_code && m.id !== mechanic.id))
      errors.name = "Kod pin musi być unikatowa";

    if (settings.mechanic_auth_type !== "pin") {
      if (!mechanic.rfid_code)
        errors.rfid_code = "Nie przypisałeś karty";
      if (mechanics.find(l => l.rfid_code === mechanic.rfid_code && l.id !== mechanic.id))
        errors.rfid_code = "Karta jest już używana";
    }

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function handleSave(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    setSaving(true);
    saveMechanic(mechanic)
      .then(() => {
        history.push("/mechanics")
        toast.success("Dane o mechaniku zostały zapisane");
      })
      .catch(error => {
        setSaving(false);
        setErrors( { onSave: error.message });
      })
  }

  return (
    <>
      { loading
        ? <Loader type="bars"/>
        : <MechanicForm
            onSave={handleSave}
            mechanic={mechanic}
            onChange={handleChange}
            isSaving={saving}
            errors={errors}
            cardScanning={cardScanning}
            onCardAssign={handleCardAssign}
            onCancelCardAssign={handleCancelCardAssign}
            rfidEnabled={settings.mechanic_auth_type !== "pin"}
          />
      }
    </>
  );
}

function getMechanicById(mechanics, mechanicId) {
  return mechanics.find(mechanic => mechanic.id === mechanicId) || null;
}

function mapStateToProps(state, ownProps) {
  const mechanicId = ownProps.match.params.id;
  const mechanic =
    mechanicId && state.mechanics.length > 0
      ? getMechanicById(state.mechanics, parseInt(mechanicId))
      : newMechanic;
  return {
    mechanic,
    mechanics: state.mechanics,
    settings: state.settings
  }
}

const mapDispatchToProps = {
  loadMechanics: actions.loadMechanics,
  saveMechanic: actions.saveMechanic
}

MechanicEditPage.propTypes = {
  mechanics: PropTypes.array.isRequired,
  loadMechanics: PropTypes.func.isRequired,
  saveMechanic: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  mechanic: PropTypes.object.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MechanicEditPage);
