import React from "react";
import { PropTypes } from "prop-types"
import { Link } from "react-router-dom";
import { Table } from 'reactstrap';
import DeleteButton from "../../../common/DeleteButton"

export const VehicleList = ({
  vehicles,
  onDelete
}) => {

  return (
    <Table hover>
      <thead>
      <tr>
        <th>Nazwa</th>
        <th width={100}/>
      </tr>
      </thead>
      <tbody>
      { vehicles.map(vehicle => {
        return (
          <tr key={vehicle.id}>
            <td>
              <Link to={"/vehicle/" + vehicle.id}>{vehicle.name}</Link>
            </td>
            <td>
              <DeleteButton
                onDelete={onDelete}
                deletingItemId={vehicle.id}
              />
            </td>
          </tr>
        );
      })}
      </tbody>
    </Table>
  )
};

VehicleList.propTypes = {
  vehicles: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired
};
