import React from 'react';
import { PropTypes } from 'prop-types';
import GridButton from './GridButton';
import { withRouter } from 'react-router-dom';

const GridEditButton = ({
  id,
  url,
  history
}) => {
  return <GridButton
    icon={"fa-edit"}
    color={"dark"}
    onClick={() => history.push(url + "/" + id)}
    name={"editButton" + id}
    tooltip={"Edytuj"}
  />
}

GridEditButton.propTypes = {
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
}

export default withRouter(GridEditButton);
