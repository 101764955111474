import * as types from "./actionTypes"
import * as api from "../../api/orderApi"
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function loadOrdersSuccess(orders) {
  return { type: types.LOAD_ORDERS_SUCCESS, orders: orders };
}

export function createOrderSuccess(order) {
  return { type: types.CREATE_ORDER_SUCCESS, order }
}

export function createOrderLineSuccess(orderLine) {
  return { type: types.CREATE_ORDERLINE_SUCCESS, orderLine }
}

export function closeOrderSuccess(orderId) {
  return { type: types.CLOSE_ORDER_SUCCESS, orderId }
}

export function deletedOrderLineSuccess(orderLineId) {
  return { type: types.DELETE_ORDERLINE_SUCCESS, orderLineId }
}

export function loadOrders() {
  return function(dispatch) {
    dispatch(beginApiCall());
    return api
      .getOrders()
      .then(orders => {
        dispatch(loadOrdersSuccess(orders));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveOrder(order) {
  return dispatch => {
    dispatch(beginApiCall());
    let localStore = require('store');
    let user = localStore.get('user');
    order.create_user_id = user.id;
    return api
      .saveOrder({
        number: order.number,
        open_user_id: user.id
      })
      .then(() => {
        api.saveOrderLine(order)
          .then(retOrder => {
            dispatch(createOrderSuccess(retOrder))
          })
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  }
}

export function closeOrder(orderId) {
  return dispatch => {
    dispatch(beginApiCall());
    return api
      .closeOrder(orderId)
      .then(() => {
        dispatch(closeOrderSuccess(orderId))
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  }
}

export function saveOrderLine(orderLine) {
  return dispatch => {
    dispatch(beginApiCall());
    let localStore = require('store');
    let user = localStore.get('user');
    orderLine.create_user_id = user.id;
    return api
      .saveOrderLine(orderLine)
      .then(retOrder => {
        dispatch(createOrderLineSuccess(retOrder))
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  }
}

export function deleteOrderLine(orderLineId) {
  return dispatch => {
    return api
      .deleteOrderLine(orderLineId)
      .then(() => {
        dispatch(deletedOrderLineSuccess(orderLineId));
      })
  }
}
