import React from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { Line } from 'react-chartjs-2';

const TraceChart = ({
  trace
}) => {

  const chartOptions = {
    tooltips: {
      enabled: true,
      displayColors: false
    },
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          display: true,
          callback: function(value, index, values) {
            return " ";
          },
          min: 0,
          max: 1.1
        },
      }],
      xAxes: [{
        type: 'time',
        time: {
          unit: 'second',
          displayFormats: {
            second: 'HH:mm:ss'
          },
          stepSize: 5
        }
      }]
    }
  };

  const getXAxisData = (tankTrace) => {
    let dates = tankTrace.substring(1, tankTrace.length-1).split(",");
    let ddd = dates.map(d => {
      return d.split(".")[0];
    })
    let traceTimes = [...new Set(ddd)];

    const start = traceTimes[0];
    const end = traceTimes[traceTimes.length-1];
    // add one second just before started tanking
    const dateArray = [{
      x: moment(start).add(-1, 'seconds'),
      y: 0.1
    }];

    let currentTime = moment(start);
    traceTimes.map(time => {
      let nextTime = moment(time);
      let secs = currentTime.diff(nextTime, 'seconds');
      if (Math.abs(secs) > 1) {
        while (currentTime < nextTime && Math.abs(currentTime.diff(nextTime, 'seconds')) > 1) {
          currentTime = moment(currentTime).add(1, 'seconds');
          dateArray.push({
            x: currentTime,
            y: 0.1
          })
        }
      }
      dateArray.push({
        x: moment(time),
        y: 1
      })
      currentTime = moment(time);
    })

    // add one second after tanking finished
    dateArray.push({
      x: moment(end).add(1, 'seconds'),
      y: 0.1
    });

    return dateArray;
  }

  const getLineData = (tankTrace) => {
    let axisData = getXAxisData(tankTrace);
    return {
      datasets: [{
        data: axisData,
        borderColor: "#c45850",
        fill: false
      }]
    }
  }

  return <tr>
    <td colSpan={5}>
      <Line
        data={getLineData(trace)}
        width={12}
        height={1}
        legend={{ display: false }}
        options={chartOptions}
      />
      <hr style={{height: '1px', background: '#122a8e', marginBottom: 0, border: 'none'}} />
    </td>
  </tr>
}

TraceChart.propTypes = {
  trace: PropTypes.string.isRequired
}

export default TraceChart;
