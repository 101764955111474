import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, Col, Input, Row } from 'reactstrap';
import { Loader } from '../../vibe';

const CardCodeScanner = ({
  codeValue,
  codeFieldName,
  onCodeChange,
  scanning,
  onAssign,
  onCancel
}) => {
  return <>
    { !scanning
    ? (
      <Button
        type="submit"
        color="primary"
        block
        onClick={onAssign}
      >
        Przypisz kartę
      </Button>
    ) : (
      <div style={{ marginTop: 40 }}>
        <h3 style={{textAlign: "center", marginBottom: 20 }}>Przyłóż kartę do czytnika</h3>
        <Loader type="bars"/>
        <Button
          color="danger"
          outline
          block
          style={{textAlign: "center", marginTop: 20 }}
          onClick={onCancel}
        >
          Anuluj
        </Button>
        <Row style={{ opacity: 0 }}>
          <Col>
            <Input
              type="text"
              autoFocus={true}
              onChange={onCodeChange}
              value={codeValue}
              name={codeFieldName}
            />
          </Col>
          <Col>
            <Input
              type="submit"
            />
          </Col>
        </Row>
      </div>
    )
  } </>
}

CardCodeScanner.propTypes = {
  codeValue: PropTypes.string.isRequired,
  codeFieldName: PropTypes.string.isRequired,
  onCodeChange: PropTypes.func.isRequired,
  scanning: PropTypes.bool.isRequired,
  onAssign: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default CardCodeScanner;
