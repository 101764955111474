import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function containerStatesReducer(state = initialState.containers, action) {
  switch (action.type) {
    case types.LOAD_CONTAINERSTATES_SUCCESS:
      return action.containerStates;
    default:
      return state;
  };
}
