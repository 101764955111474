import React from 'react';
import { PropTypes } from 'prop-types';
import { toDateTime } from '../../common/utils';

const TdDate = ({
  date
}) => {
  return <td width={170}>{toDateTime(date)}</td>
}

TdDate.propTypes = {
  date: PropTypes.string.isRequired
}

export default TdDate;
