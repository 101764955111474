import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import * as tankActions from '../../../../redux/actions/tankAction';
import * as vehicleActions from '../../../../redux/actions/vehicleAction';
import TanksFilter from './TanksFilter';
import moment from 'moment';
import { dateToString } from '../../../../common/utils';
import TanksListView from './TanksListView';


const TanksPage = ({
  tanks,
  loadTanks
}) => {

  const initStartDate = moment().subtract(6, 'days');
  const initEndDate = moment().add(1, 'days');

  const [showTankTrace, setShowTankTrace] = useState(false);

  useEffect(() => {
    if (initStartDate && initEndDate) {
      loadTanks(dateToString(initStartDate), dateToString(initEndDate));
    }
  }, [])

  const handleFilterData = (startDate, endDate) => {
    loadTanks(dateToString(startDate), dateToString(endDate));
  }

  const handleShowTrace = () => {
    setShowTankTrace(!showTankTrace);
  }

  return (
    <>
      {/*<TankTools history={history} />*/}
      <TanksFilter
        initStart={initStartDate}
        initEnd={initEndDate}
        onFilterChanged={handleFilterData}
        onShowTrace={handleShowTrace}
        showTrace={showTankTrace}
      />
      <TanksListView
        tanks={tanks}
        showTankTrace={showTankTrace}
      />
    </>
  );
}

TanksPage.propTypes = {
  tanks: PropTypes.array.isRequired,
  loadTanks: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  tanks: state.tanks,
  vehicles: state.vehicles
})

const mapDispatchToProps = {
  loadTanks: tankActions.loadTanks,
  loadVehicles: vehicleActions.loadVehicles
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TanksPage);
