import React from 'react';
import TextInput from '../../../common/TextInput';
import { PropTypes } from 'prop-types';
import { Form, Col, Card, CardBody, Row } from 'reactstrap';
import FormButtons from '../../../common/FormButtons';
import CardCodeScanner from '../../../common/CardCodeScanner';

const VehicleForm = ({
  vehicle,
  onChange,
  onSave,
  isSaving,
  cardAssigning,
  onCardAssign,
  onCancelCardAssign,
  errors = {}
}) => {

  const handleSubmit = (e) => {
    e.preventDefault();
    onCancelCardAssign();
  }

  return (
    <>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <Card>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                {errors.onSave && (
                  <div className="alert alert-danger" role="alert">
                    {errors.onSave}
                  </div>
                )}
                <TextInput
                  autoFocus={false}
                  name="name"
                  label="Nazwa"
                  value={vehicle.name}
                  onChange={onChange}
                  error={errors.name}
                  disabled={cardAssigning}
                />
                <TextInput
                  name="rfid_code"
                  label="Numer karty"
                  value={vehicle.rfid_code}
                  onChange={onChange}
                  error={errors.rfid_code}
                  disabled={true}
                />
                <CardCodeScanner
                  onCodeChange={onChange}
                  onCancel={onCancelCardAssign}
                  onAssign={onCardAssign}
                  codeValue={vehicle.rfid_code}
                  codeFieldName="rfid_code"
                  scanning={cardAssigning}
                />
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <FormButtons
            onSave={onSave}
            isSaving={isSaving}
            disabled={cardAssigning}
          />
        </Col>
      </Row>
    </>
  );
}

VehicleForm.propTypes = {
  vehicle: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  cardAssigning: PropTypes.bool.isRequired,
  onCardAssign: PropTypes.func.isRequired,
  onCancelCardAssign: PropTypes.func.isRequired,
  errors: PropTypes.object
}

export default VehicleForm;
