import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormGroup, Input, Label } from 'reactstrap';

const SelectInput = ({
  name,
  label,
  onChange,
  defaultOption,
  value,
  error,
  options,
  useEmptyValue = false
}) => {
  let wrapperClass = "";
  if (error && error.length > 0) {
    wrapperClass = "has-error";
  }

  const [isSet, setIsSet] = useState(!!value);

  function handleChange(event) {
    setIsSet(true);
    onChange(event);
  }

  return (
    <FormGroup className={wrapperClass}>
      <Label for={name}>{label}</Label>
      <div className="field">
        {/* Note, value is set here rather than on the option - docs: https://facebook.github.io/react/docs/forms.html */}
        <Input
          type="select"
          name={name}
          value={value}
          onChange={handleChange}
        >
          {(useEmptyValue || !isSet) && <option value="">{defaultOption}</option> }
          {options.map(option => {
            return (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            );
          })}
        </Input>
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </FormGroup>
  );
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultOption: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  useEmptyValue: PropTypes.bool
};

export default SelectInput;
