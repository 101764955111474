import * as types from "./actionTypes";
import * as api from "../../api/tankApi";
import { apiCallError, beginApiCall } from './apiStatusActions';

export function loadTanksSuccess(tanks) {
  return { type: types.LOAD_TANKS_SUCCESS, tanks };
}

export function createTankSuccess(tank) {
  return { type: types.CREATE_TANK_SUCCESS, tank }
}

export function updateTankSuccess(tank) {
  return { type: types.UPDATE_TANK_SUCCESS, tank }
}

export function deletedTankSuccess(tankId) {
  return { type: types.DELETE_TANK_SUCCESS, tankId }
}

export function loadTanks(start, end) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return api
      .getTanks(start, end)
      .then(tanks => {
        dispatch(loadTanksSuccess(tanks));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}


export function saveTank(tank, vehicleId) {
  return dispatch => {
    // dispatch(beginApiCall());
    return api
      .saveTank(tank, vehicleId)
      // .then(savedLiquid => {
      //   liquid.id
      //     ? dispatch(updateLiquidSuccess(liquid))
      //     : dispatch(createLiquidSuccess(savedLiquid))
      // })
      // .catch(error => {
      //   dispatch(apiCallError(error));
      //   throw error;
      // });
  }
}

// export function deleteLiquid(liquidId) {
//   return dispatch => {
//     return api
//       .deleteLiquid(liquidId)
//       .then(() => {
//         dispatch(deletedLiquidSuccess(liquidId));
//       })
//   }
// }
