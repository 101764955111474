import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Card, CardBody, Col, Form, Row } from 'reactstrap';
import TextInput from '../../../common/TextInput';
import FormButtons from '../../../common/FormButtons';
import * as api from '../../../../api/userApi';
import { toast } from 'react-toastify';

const UserPwdPage = ({
  history
}) => {

  useEffect(() => {
    let localStore = require('store');
    let user = localStore.get('user');
    setUser(user);
  }, [])

  const [user, setUser] = useState({});
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({})

  const handleChangePassword2 = event => {
    const { value } = event.target;
    setPassword2(value);
  }

  function handleChangePassword(event) {
    const { value } = event.target;
    setPassword(value);
  }

  function formIsValid(errors) {
    errors = {};
    if (password !== password2) {
      errors.password = "Hasła nie zgadzają się";
      errors.password2 = "Hasła nie zgadzają się";
    }
    if (password.length  < 6)
      errors.password = "Hasło musi składać się przynajmniej z 6 znaków";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  const handleSave = event => {
    event.preventDefault();

    if (!formIsValid(errors)) return;
    setSaving(true);

    api.changePwd(user.id, password)
      .then(() => {
        history.goBack();
        toast.success("Hasło użytkownika zostało zmienione");
      })
      .catch(error => {
          setSaving(false);
          setErrors( { onSave: error.message });
        })
  }

  return <Row>
    <Col md={{ size: 8, offset: 2 }}>
      <Card>
        <CardBody>
          <Form>
            {errors.onSave && (
              <div className="alert alert-danger" role="alert">
                {errors.onSave}
              </div>
            )}
            <TextInput
              name="username"
              label="Login"
              value={user.user_name}
              onChange={() => {}}
              disabled={true}
            />
            <TextInput
              name="password"
              label="Nowe hasło"
              value={password}
              onChange={handleChangePassword}
              error={errors.password}
              isPassword={true}
            />
            <TextInput
              name="password2"
              label="Nowe hasło (powtórzone)"
              value={password2}
              onChange={handleChangePassword2}
              error={errors.password2}
              isPassword={true}
            />
          </Form>
        </CardBody>
      </Card>
      <FormButtons
        onSave={handleSave}
        isSaving={saving}
      />
    </Col>
  </Row>
}

UserPwdPage.propTypes = {
  history: PropTypes.object.isRequired
}

export default UserPwdPage;
