import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';

const Tools = ({
  containerId,
  history
}) => {
  return (
    <Card>
      <CardBody>
        <Button
          color="primary"
          onClick={() => history.push("/container/tank/" + containerId)}
        >
          Dolej płyn
        </Button>
        {' '}
        <Button
          color="primary"
          onClick={() => history.push("/container/refill/" + containerId)}
        >
          Uzupełnij
        </Button>
        {' '}
        <Button
          color="primary"
          onClick={() => history.push("/container/correct/" + containerId)}
        >
          Korekta stanu
        </Button>
        {' '}
        <Button
          color="primary"
          onClick={() => history.push("/container/liquid/" + containerId)}
        >
          Nowy płyn
        </Button>
        {' '}
        <Button
          color="primary"
          onClick={() => history.push("/container/edit/" + containerId)}
        >
          Edytuj
        </Button>
        {' '}
        <Button
          className="pull-right"
          color="secondary"
          outline
          onClick={() => history.goBack()}
        >
          Wróć
        </Button>
      </CardBody>
    </Card>
  )
}

export default withRouter(Tools);
