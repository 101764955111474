import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Button } from 'reactstrap';

const DateRangeSelect = ({
  initStart,
  initEnd,
  onRangeChanged
}) => {

  const [startDate, setStartDate] = useState(initStart);
  const [endDate, setEndDate] = useState(initEnd);

  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  useEffect(() => {
    setDateFrom(initStart.format('DD-MM-YYYY'));
    setDateTo(initEnd.format('DD-MM-YYYY'));
  }, [])

  const handleApply = (event, picker) => {
    setDateFrom(picker.startDate.format('DD-MM-YYYY'));
    setDateTo(picker.endDate.format('DD-MM-YYYY'));
    onRangeChanged(picker.startDate, picker.endDate);
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", margin: "auto"}}>
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onApply={handleApply}
        ranges={{
          'Dzisiaj': [moment(), moment().add(1, 'days')],
          'Ostatnie 7 Dni': [moment().subtract(6, 'days'), moment().add(1, 'days')],
          'Ostatnie 30 Dni': [moment().subtract(29, 'days'), moment().add(1, 'days')],
          'Aktualny Miesiąc': [moment().startOf('month'), moment().endOf('month')],
          'Poprzedni Miesiąc': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }}
        showCustomRangeLabel={true}
        locale={{
          "customRangeLabel": "Dowolny zakres"
        }}
      >
        <Button
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            margins: 0,
            padding: 0
          }}
        >
          <span
            className=""
            style={{
              paddingLeft: 12,
              paddingTop: 8,
              paddingBottom: 8,
            }}
          >
            {dateFrom}
          </span>
          <span
            className=""
            style={{
              paddingLeft: 8,
              paddingRight: 8,
              paddingTop: 8,
              paddingBottom: 8,
            }}
          >
            :
          </span>
          <span
            className=""
            style={{
              paddingRight: 12,
              paddingTop: 8,
              paddingBottom: 8,
            }}
          >
            {dateTo}
          </span>
          <span
            className="bg-primary"
            style={{
              color: "#ffffff",
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 8,
              paddingBottom: 8,
              borderRadius: "0px 5px 5px 0px"
            }}
          >
            <i className="fa fa-calendar"/>
          </span>
        </Button>
      </DateRangePicker>
    </div>
  )
}

DateRangeSelect.propTypes = {
  initStart: PropTypes.object.isRequired,
  initEnd: PropTypes.object.isRequired,
  onRangeChanged: PropTypes.func.isRequired
}

export default DateRangeSelect;
