import * as types from "../actions/actionTypes"
import initialState from "./initialState";

export default function liquidReducer(state = initialState.vehicles, action) {
  switch (action.type) {
    case types.LOAD_VEHICLES_SUCCESS:
      return action.vehicles;
    case types.CREATE_VEHICLE_SUCCESS:
      return [...state, {...action.vehicle}];
    case types.UPDATE_VEHICLE_SUCCESS:
      return state.map(vehicle => {
        return vehicle.id === action.vehicle.id
          ? action.vehicle
          : vehicle
      })
    case types.DELETE_VEHICLE_SUCCESS:
      return  state.filter(vehicle => vehicle.id !== action.vehicleId);
    default:
      return state;
  }
}
