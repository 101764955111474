import React from 'react';
import { Card, CardHeader, CardBody, ListGroup, ListGroupItem } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

const MessagesCard = ({
  messages
}) => {
  return (
    <Card>
      <CardHeader>
        <h4><Link to={"/messages/"}>Nowe komunikaty</Link></h4>
        <hr />
      </CardHeader>
      <CardBody>
        <div style={{
          marginLeft: 40,
          marginRight: 40,
          marginBottom: 20
        }}>
          { messages && messages.length > 0 ?
            (
              <ListGroup>
                {messages.map(message => {
                  return (
                    <ListGroupItem
                      color={message.level}
                      key={message.id}
                    >
                      <b>{message.container.name}</b>
                      {' | '}
                      {message.content}
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            ) : (
              <div className="text-center">
                <h2 className="h5 text-muted text-uppercase">Brak nowych komunikatów</h2>
              </div>
            )
          }
        </div>
      </CardBody>
    </Card>
  );
}

MessagesCard.propTypes = {
  messages: PropTypes.array
}

export default MessagesCard;
