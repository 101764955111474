import {
  apiUrl,
  getData,
  saveData,
  deleteData,
} from './apiUtils';
import * as demoRepo from './demo/repository';
const baseUrl = apiUrl + "/warehouse/vehicles/";

export function getVehicles() {
  return getData(baseUrl, () => demoRepo.getVehicles());
}

export function saveVehicle(vehicle) {
  return saveData(baseUrl, vehicle, () => demoRepo.saveVehicle(vehicle));
}

export function deleteVehicle(vehicleId) {
  return deleteData(baseUrl, vehicleId, () => demoRepo.deleteVehicle(vehicleId));
}
