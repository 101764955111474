import React from "react";
import { Route, Switch } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import './vibe/scss/styles.scss';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginPage from './login/LoginPage';

function App() {

  return (
    <>
      <Switch>
        <Route exact component={LoginPage} path="/login"/>
        <Route component={DashboardLayout} />
      </Switch>
      <ToastContainer autoClose={3000} hideProgressBar />
    </>
  );
}

export default App;
