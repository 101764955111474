import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import ContainerChart from '../../../common/ContainerChart';

const ContainerChartCard = ({
  container,
  tankAmount = 0,
  showNewAmount = true
}) => {

  const newAmount = container.liquid_amount + tankAmount;
  const isBelowAlarmLevel = container.liquid_amount <= container.alarm_level;
  const isEmpty = container.liquid_amount === container.min_level;

  return (
    <Card>
      <CardHeader>
        <h4>Stan zbiornika</h4>
        <hr />
      </CardHeader>
      <CardBody>
        <ContainerChart container={container} tankAmount={tankAmount} />
        <hr />
        <Row>
          <Col>
            <div className="h6">
              <span className="bg-danger inline-block" style={{width: 10, height: 10, marginRight: 5}}/>
              Minimalny poziom
            </div>
            <small className="text-muted">{container.min_level.toFixed(2)} l</small>
          </Col>
          {isBelowAlarmLevel
            ?  (
              !isEmpty &&
                <Col>
                  <div className="h6">
                    <span className="bg-warning inline-block" style={{width: 10, height: 10, marginRight: 5}}/>
                    Aktualna ilosc
                  </div>
                  <small className="text-muted">{container.liquid_amount.toFixed(2)} l</small>
                </Col>
            ) : (
              <>
                <Col>
                  <div className="h6">
                    <span className="bg-warning inline-block" style={{width: 10, height: 10, marginRight: 5}}/>
                    Stan alarmowy
                  </div>
                  <small className="text-muted">{container.alarm_level.toFixed(2)} l</small>
                </Col>
                <Col>
                  <div className="h6">
                    <span className="bg-primary inline-block" style={{width: 10, height: 10, marginRight: 5}}/>
                    Aktualna ilosc
                  </div>
                  <small className="text-muted">{container.liquid_amount.toFixed(2)} l</small>
                </Col>
                { showNewAmount &&
                  <Col>
                    <div className="h6">
                      <span className="bg-success inline-block" style={{ width: 10, height: 10, marginRight: 5 }}/>
                      Nowy stan
                    </div>
                    <small className="text-muted">{newAmount.toFixed(2)} l</small>
                  </Col>
                }
              </>
          )}
        </Row>
      </CardBody>
    </Card>
  )
}

export default ContainerChartCard;
