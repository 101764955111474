import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function containerReducer(state = initialState.containers, action) {
  switch (action.type) {
    case types.LOAD_CONTAINERS_SUCCESS:
      return action.containers;
    case types.CREATE_CONTAINER_SUCCESS:
      return [...state, {...action.container}];
    case types.UPDATE_CONTAINER_SUCCESS:
      return state.map(container => {
        return container.id === action.container.id
          ? action.container
          : container
      });
    case types.DELETE_CONTAINER_SUCCESS:
      return  state.filter(container => container.id !== action.containerId);
    default:
      return state;
  }
}
