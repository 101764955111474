import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Label, Input } from 'reactstrap';

const TextInput = ({
  name, 
  label, 
  onChange,
  placeholder,
  value,
  error,
  isPassword = false,
  disabled = false,
  autoFocus = false
}) => {
  let wrapperClass = "";
  if (error && error.length > 0) {
    wrapperClass = "has-error";
  }

  return (
    <FormGroup className={wrapperClass}>
      <Label for={name}>{label}</Label>
      <div className="field">
        {disabled
          ? (
            <Input
              type={isPassword ? "password" : "text"}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              disabled
              autoFocus={autoFocus}
            />
          ) : (
            <Input
              type={isPassword ? "password" : "text"}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              autoFocus={autoFocus}
            />
          )}
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </FormGroup>
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  isPassword: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool
};

export default TextInput;
