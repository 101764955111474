import React from 'react';
import { Button, Table } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { toDateTime } from '../../../common/utils';
import { Link } from 'react-router-dom';

const MessageList = ({
  messages,
  onReceiveMessage
}) => {
  let warningStyle = {
    backgroundColor:"#ffeeba",
    color: "#856404"
  }

  let dangerStyle = {
    backgroundColor:"#f5c6cb",
    color: "#721c24"
  }

  const getStyle = message => {
    let style = message.level === "warning" ? warningStyle : dangerStyle
    if (message.received)
      style = {...style, opacity: 0.4}
    return style;
  }

  return (
    <Table bordered>
      <thead>
      <tr style={{ background: "#787d80", color: "white" }}>
        <th width={180}>Data</th>
        <th>Zbiornik</th>
        <th>Płyn</th>
        <th width={300}>Komunikat</th>
        <th width={100}/>
      </tr>
      </thead>
      <tbody>
      {messages.map(message => {
        return (
          <tr key={message.id}>
            <td style={getStyle(message)}><b>{toDateTime(message.create_date)}</b></td>
            <td style={getStyle(message)}>
              <Link to={"/container/view/" + message.container.id} key={message.container.id}>
                <b>{message.container.name}</b>
              </Link>
            </td>
            <td style={getStyle(message)}><b>{message.container.liquid.name}</b></td>
            <td style={getStyle(message)}><b>{message.content}</b></td>
            <td>
              {!message.received && (
                <Button
                  color="primary"
                  outline
                  onClick={() => onReceiveMessage(message.id)}
                >
                  Odebrany
                </Button>
              )}
            </td>
          </tr>
        );
      })}
      </tbody>
    </Table>
  );
}

MessageList.propTypes = {
  messages: PropTypes.array.isRequired,
  onReceiveMessage: PropTypes.func.isRequired
}

export default MessageList;
