import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Card, CardBody, CardHeader, Table } from 'reactstrap';
import PumpListRowEdit from './PumpListRowEdit';
import { newPump } from '../../../../../redux/reducers/initialState';
import PumpListRow from './PumpListRow';
import ToolButton from '../../../../common/ToolButton';

const PumpList = ({
  station,
  containers,
  onAdd,
  onEdit,
  onDelete
}) => {

  const [pumpEdit, setPumpEdit] = useState({...newPump});
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState(0);
  const [errors, setErrors] = useState({});

  const handleAdd = () => {
    const pumpAdd = {...newPump};
    pumpAdd.number = Math.max(...station.pumps.map(o => o.number), 0) + 1;
    setPumpEdit(pumpAdd);
    setAdding(true);
  }

  const handleCancelAdd = () => {
    setErrors({});
    setAdding(false);
  }

  const handleConfirmAdd = (pumpAdded) => {
    if (!formIsValid()) return;
    setAdding(false);
    onAdd(pumpAdded)
  }

  const handleEdit = (pumpToEdit) => {
    setPumpEdit({...pumpToEdit});
    setEditing(pumpToEdit.number);
  }

  const handleCancelEdit = () => {
    setErrors({});
    setEditing(0);
  }

  const handleConfirmEdit = (pumpEdited) => {
    if (!formIsValid()) return;
    setEditing(0);
    onEdit(pumpEdited);
  }

  const handleChange = (event) => {
    const { value, name } = event.target;
    setPumpEdit(prev => ({
      ...prev,
      [name]: parseInt(value, 10)
    }));
  }

  function formIsValid() {
    const errors = {};

    if (!pumpEdit.container_id)
      errors.container_id = "Musisz wybrać zbiornik";
    if (pumpEdit.wk < 0)
      errors.wk = "Współczynnik Kalibracji musi być wartością dodatnią";
    if (pumpEdit.bs < 0)
      errors.bs = "Błąd Systemowy musi być wartością dodatnią";
    setErrors(errors);

    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  return (
    <Card>
      <CardHeader>
        <h4>Lista Nalewaków</h4>
        <hr />
      </CardHeader>
      <CardBody>
        <ToolButton
          color={"primary"}
          handleClick={handleAdd}
          icon={"plus"}
          text={"Dodaj Nalewak"}
          disabled={adding}
        />
        <Table className="table" style={{marginTop:10}}>
          <thead>
            <tr style={{ background: "#787d80", color: "white" }}>
              <th>Numer</th>
              <th>Zbiornik</th>
              <th width={120}>WK</th>
              <th width={100}>BS</th>
              <th width={180}/>
            </tr>
          </thead>
          <tbody>
            { station.pumps && station.pumps.map(pump => (
              <React.Fragment key={pump.number}>
              {pump.number === editing ?
                (
                  <PumpListRowEdit
                    containers={containers}
                    pump={pumpEdit}
                    onChange={handleChange}
                    onOk={() => handleConfirmEdit(pumpEdit)}
                    onCancel={handleCancelEdit}
                    errors={errors}
                  />
                ) : (
                  <PumpListRow
                    containers={containers}
                    onEdit={() => handleEdit(pump)}
                    onDelete={() => onDelete(pump)}
                    pump={pump}
                    disabled={adding}
                    showDelete={pump.number === Math.max(...station.pumps.map(o => o.number), 0)}
                  />
                )}
            </React.Fragment>
            ))}
            {adding &&
            <PumpListRowEdit
              containers={containers}
              pump={pumpEdit}
              onChange={handleChange}
              onOk={() => handleConfirmAdd(pumpEdit)}
              onCancel={handleCancelAdd}
              errors={errors}
            />
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}

PumpList.propTypes = {
  station: PropTypes.object.isRequired,
  containers: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default PumpList;
