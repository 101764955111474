import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { ExportToExcel } from './ExportToExcel';
import { ExportToPdf } from './ExportToPdf';
import TanksMainList from './TanksMainList';
import TanksVehicleList from './TanksVehicleList';
import TanksLiquidList from './TanksLiquidList';
import TanksMechanicList from './TanksMechanicList';

const TanksListView = ({
  tanks,
  showTankTrace
}) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  return (
    <Card>
      <CardBody>
        <div style={{display: "flex", alignContent: "stretch"}}>
          <Nav tabs style={{ flexGrow: 1 }}>
            <NavItem>
              <NavLink
                href="#"
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  toggle('1');
                }}
              >
                Ostatnie
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  toggle('2');
                }}
              >
                Wg pojazdów
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames({ active: activeTab === '3' })}
                onClick={() => {
                  toggle('3');
                }}
              >
                Wg płynów
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames({ active: activeTab === '4' })}
                onClick={() => {
                  toggle('4');
                }}
              >
                Wg mechaników
              </NavLink>
            </NavItem>
          </Nav>
          <div style={{
            backgroundColor: "#f5f6f7",
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20
          }}>
            <ExportToExcel tanks={tanks} fileName="tanks" />
            <ExportToPdf htmlElement={"tankList" + activeTab} fileName="tanks2.pdf" />
          </div>
        </div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" id={"tankList1"}>
            <TanksMainList
              tanks={tanks}
              showTankTrace={showTankTrace}
            />
          </TabPane>
          <TabPane tabId="2" id={"tankList2"}>
            <TanksVehicleList
              tanks={tanks}
              showTankTrace={showTankTrace}
            />
          </TabPane>
          <TabPane tabId="3" id={"tankList3"}>
            <TanksLiquidList
              tanks={tanks}
              showTankTrace={showTankTrace}
            />
          </TabPane>
          <TabPane tabId="4" id={"tankList4"}>
            <TanksMechanicList
              tanks={tanks}
              showTankTrace={showTankTrace}
            />
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  )
}

TanksListView.propTypes = {
  tanks: PropTypes.array.isRequired,
  showTankTrace: PropTypes.bool.isRequired
}

export default TanksListView
