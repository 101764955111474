import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from "../../../../redux/actions/liquidAction";
import { newLiquid } from "../../../../redux/reducers/initialState";
import { PropTypes } from "prop-types";
import { toast } from "react-toastify";
import LiquidForm from "./LiquidForm";
import { Loader } from '../../../../vibe';

const LiquidEditPage =
({
  liquids,
  loadLiquids,
  saveLiquid,
  history,
  ...props
}) => {

  const [liquid, setLiquid] = useState({...props.liquid});
  const [errors, setErrors] = useState({})
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (liquids.length === 0) {
      setLoading(true);
      loadLiquids();
      setLoading(false);
    }
    else {
      setLiquid({...props.liquid});
    }
  }, [props.liquid]);

  function handleChange(event) {
    const { name, value } = event.target;
     setLiquid(prevLiquid => ({
       ...prevLiquid,
       [name]: value
     }));
  }

  function formIsValid() {
    const errors = {};

    if (!liquid.name)
      errors.name = "Nazwa jest wymagana";
    if(liquids.find(l => l.name === liquid.name && l.id !== liquid.id))
      errors.name = "Nazwa musi być unikatowa";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function handleSave(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    setSaving(true);
    saveLiquid(liquid)
      .then(() => {
        history.push("/liquids")
        toast.success("Dane płynu zostały zapisane");
      })
      .catch(error => {
        setSaving(false);
        setErrors( { onSave: error.message });
      })
  }

  return (
    <>
      {loading
        ? <Loader type="bars"/>
        : <LiquidForm
            onSave={handleSave}
            liquid={liquid}
            onChange={handleChange}
            isSaving={saving}
            errors={errors}
        />
      }
    </>
  );
}

function getLiquidById(liquids, liquidId) {
  return liquids.find(liquid => liquid.id === liquidId) || null;
}

function mapStateToProps(state, ownProps) {
  const liquidId = ownProps.match.params.id;
  const liquid =
    liquidId && state.liquids.length > 0
      ? getLiquidById(state.liquids, parseInt(liquidId))
      : newLiquid;
  return {
    liquid,
    liquids: state.liquids
  }
}

const mapDispatchToProps = {
  loadLiquids: actions.loadLiquids,
  saveLiquid: actions.saveLiquid
}

LiquidEditPage.propTypes = {
  liquids: PropTypes.array.isRequired,
  loadLiquids: PropTypes.func.isRequired,
  saveLiquid: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  liquid: PropTypes.object.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiquidEditPage);
