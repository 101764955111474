import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import ToolButton from '../../../common/ToolButton';
import BackButton from '../../../common/BackButton';

const LiquidTools = ({
  history
}) => {
  return (
    <Card>
      <CardBody>
        <ToolButton
          color={"primary"}
          handleClick={() => history.push("/liquid")}
          icon={"plus"}
          text={"Dodaj Płyn"}
        />
        <BackButton />
      </CardBody>
    </Card>
  )
}

export default withRouter(LiquidTools);
