import * as types from "../actions/actionTypes"
import initialState from "./initialState";

export default function liquidReducer(state = initialState.liquids, action) {
  switch (action.type) {
    case types.LOAD_LIQUIDS_SUCCESS:
      return action.liquids;
    case types.CREATE_LIQUID_SUCCESS:
      return [...state, {...action.liquid}];
    case types.UPDATE_LIQUID_SUCCESS:
      return state.map(liquid => {
        return liquid.id === action.liquid.id 
          ? action.liquid 
          : liquid
      })
    case types.DELETE_LIQUID_SUCCESS:
      return state.filter(liquid => liquid.id !== action.liquidId);
    default:
      return state;
  }
}
