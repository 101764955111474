import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { PropTypes } from 'prop-types';
import DateRangeSelect from '../../common/DateRangeSelect';

const MessageFilter = ({
  initStart,
  initEnd,
  onFilterChanged,
  onReceiveAllMessages
}) => {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <DateRangeSelect
              initStart={initStart}
              initEnd={initEnd}
              onRangeChanged={onFilterChanged}
            />
          </Col>
          <Col>
            <div className="pull-right">
              <Button
                color="primary"
                onClick={onReceiveAllMessages}
              >
                Oznacz wszystkie jako odebrane
              </Button>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

MessageFilter.propTypes = {
  initStart: PropTypes.object.isRequired,
  initEnd: PropTypes.object.isRequired,
  onFilterChanged: PropTypes.func.isRequired,
  onReceiveAllMessages: PropTypes.func.isRequired
}

export default MessageFilter;
