import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardHeader, Input } from 'reactstrap';
import FormButtons from '../../common/FormButtons';
import { Switch } from '../../../vibe';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/settingsAction';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';

const SystemPage = ({
  settings,
  saveSettings,
  history
}) => {

  const [authVehiclesEnabled, setAuthVehiclesEnabled] = useState(false);
  const [algorithmOnes, setAlgorithmOnes] = useState(3);
  const [algorithmZeros, setAlgorithmZeros] = useState(3);
  const [orderMgmtEnabled, setOrderMgmtEnabled] = useState(false);
  const [dmsStatus, setDmsStatus] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setAuthVehiclesEnabled(settings.vehicles_enabled !== 0);
    setAlgorithmOnes(settings.algorithm_ones);
    setAlgorithmZeros(settings.algorithm_zeros);
    setOrderMgmtEnabled(settings.order_mgmt !== 0);
    setDmsStatus(settings.system_dms_status !== 0);
  }, [])

  function handleAuthVehiclesEnabledChange() {
    setAuthVehiclesEnabled(!authVehiclesEnabled);
  }

  function handleOrderMgmtChange() {
    setOrderMgmtEnabled(!orderMgmtEnabled);
  }

  function handleDmsStatusChange() {
    setDmsStatus(!dmsStatus);
  }

  function handleAlgorithmOnesChange(event) {
    let value = parseInt(event.target.value, 10);
    if (value < 1) value = 1;
    if (value > 10) value = 10;
    if (!value) value = 3;
    setAlgorithmOnes(value);
  }

  function handleAlgorithmZerosChange(event) {
    let value = parseInt(event.target.value, 10);
    if (value < 1) value = 1;
    if (value > 10) value = 10;
    if (!value) value = 3;
    setAlgorithmZeros(value);
  }

  function handleSave(event) {
    event.preventDefault();
    setSaving(true);
    saveSettings({
      algorithm_ones: algorithmOnes,
      algorithm_zeros: algorithmZeros,
      vehicles_enabled: authVehiclesEnabled === true ? 1 : 0,
      order_mgmt: orderMgmtEnabled === true ? 1 : 0,
      system_dms_status: dmsStatus === true ? 1 : 0
    })
      .then(() => {
        history.push("/home")
        toast.success("Ustawienia zostały zapisane");
      })
      .catch(error => {
        setSaving(false);
        alert(error);
      })
  }

  return <>
    <Row>
      <Col md={6}>
        <Card>
          <CardHeader>
            <h4>System</h4>
            <hr />
          </CardHeader>
          <CardBody style={{ marginLeft: 50, marginRight: 50 }}>
            <div style={{display: "flex", alignContent: "stretch", marginBottom: 10}}>
              <Switch
                enabled={orderMgmtEnabled}
                toggle={handleOrderMgmtChange}
              />
              <span style={{ marginLeft: 20 }}>
                Zarządzanie zleceniami
              </span>
            </div>
            <div style={{display: "flex", alignContent: "stretch", marginBottom: 10}}>
              <Switch
                enabled={authVehiclesEnabled}
                toggle={handleAuthVehiclesEnabledChange}
              />
              <span style={{ marginLeft: 20 }}>
                Autoryzacja pojazdów
              </span>
            </div>
            <div style={{display: "flex", alignContent: "stretch", marginBottom: 10}}>
              <Switch
                enabled={dmsStatus}
                toggle={handleDmsStatusChange}
              />
              <span style={{ marginLeft: 20 }}>
                Integracja z dms
              </span>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col md={6}>
        <Card>
          <CardHeader>
            <h4>Algorytm liczenia wydanej ilości</h4>
            <hr />
          </CardHeader>
          <CardBody style={{ marginLeft: 50, marginRight: 50 }}>
            <div style={{display: "flex", alignContent: "stretch", alignItems: "center" }}>
              <span style={{ marginRight: 20, alignItems: "center" }}>
                Liczba kolejnych "1"
              </span>
              <Input
                style={{ width: 70 }}
                type="number"
                name={"algorithmOnes"}
                className="form-control"
                value={algorithmOnes}
                onChange={handleAlgorithmOnesChange}
                step="1"
                min={1}
                max={10}
              />
            </div>
            <div style={{display: "flex", alignContent: "stretch", alignItems: "center", marginTop: 20 }}>
              <span style={{ marginRight: 20, alignItems: "center" }}>
                Liczba kolejnych "0"
              </span>
              <Input
                style={{ width: 70 }}
                type="number"
                name={"algorithmZeros"}
                className="form-control"
                value={algorithmZeros}
                onChange={handleAlgorithmZerosChange}
                step="1"
                min={1}
                max={10}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col>
        <FormButtons
          onSave={handleSave}
          isSaving={saving}
        />
      </Col>
    </Row>
  </>
}

const mapStateToProps = state => {
  return {
    settings: state.settings
  }
}

const mapDispatchToProps = {
  saveSettings: actions.saveSettings
}

SystemPage.propTypes = {
  settings: PropTypes.object.isRequired,
  saveSettings: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemPage);
