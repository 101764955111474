import * as types from "../actions/actionTypes"
import initialState from "./initialState";

export default function dashboardReducer(state = initialState.dashboard, action) {
  switch (action.type) {
    case types.LOAD_DASHBOARD_SUCCESS:
      return action.dashboard;
    case types.RECEIVE_ALL_DASHBOARD_MESSAGES_SUCCESS:
      return {...state, messages: [] };
    case types.RECEIVE_DASHBOARD_MESSAGE_SUCCESS:
      return {...state, messages: state.messages.filter(message => message.id !==action.messageId) };
    default:
      return state;
  }
}
