import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import DeleteButton from '../../../common/DeleteButton'
import { Table } from 'reactstrap';
import GridEditButton from '../../../common/GridEditButton';

const UserList = ({
  users,
  onDelete,
  mode,
  history,
  admin
}) => {

  return (
    <Table className="table">
      <thead>
      <tr style={{ background: "#787d80", color: "white" }}>
        <th>Użytkownik</th>
        <th>Login</th>
        <th>Pin</th>
        <th width={140}/>
      </tr>
      </thead>
      <tbody>
      { users.map(user => {
        return (
          <tr key={user.id}>
            <td>
              <Link to={"/user/" + mode + "/" + user.id}>{ user.last_name + " " + user.first_name }</Link>
            </td>
            <td>
              {user.username}
            </td>
            <td>
              {user.pin_code}
            </td>
            {user.username !== "sysadmin" && admin &&
              <td>
                <GridEditButton
                  id={user.id}
                  url={"/user/" + mode}
                  history={history}
                />
                &nbsp;
                <DeleteButton
                  onDelete={onDelete}
                  deletingItemId={user.id}
                />
              </td>
            }
          </tr>
        );
      })}
      </tbody>
    </Table>
  )
};

UserList.propTypes = {
  users: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  admin: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired
};

export default UserList;
