import React from 'react';
import { PropTypes } from 'prop-types';
import { Table } from 'reactstrap';
import DeleteButton from '../../../common/DeleteButton';
import { Link } from 'react-router-dom';

export const StationList = ({ stations, onDelete }) => {

  return (
    <Table hover>
      <thead>
        <tr style={{ background: "#787d80", color: "white" }}>
          <th>Numer</th>
          <th>Nazwa</th>
          <th width={100}/>
        </tr>
      </thead>
      <tbody>
        { stations.map(station => {
          return (
            <tr key={station.id}>
              <td>
                {station.number}
              </td>
              <td>
                <Link to={"/station/" + station.number}>{station.name}</Link>
              </td>
              <td>
                {station.number !== 1 && (
                  <DeleteButton
                    onDelete={onDelete}
                    deletingItemId={station.number}
                  />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  )
};

StationList.propTypes = {
  stations: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired
};
