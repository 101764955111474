import ErrorPage from './pages/404';
import LiquidsPage from './pages/liquids/LiquidList/LiquidsPage';
import ManageLiquidPage from './pages/liquids/LiquidEdit/LiquidEditPage';
import ContainersPage from './pages/containers/ContainerList/ContainersPage';
import ManageContainerPage from './pages/containers/ContainerEdit/ContainerEditPage';
import ContainerRefillPage from './pages/containers/ContainerRefill/ContainerRefillPage';
import ContainerCorrectPage from './pages/containers/ContainerCorrect/ContainerCorrectPage';
import ContainerLiquidPage from './pages/containers/ContainerLiquid/ContainerLiquidPage';
import ContainerDetailsPage from './pages/containers/ContainerDetails/ContainerDetailsPage';
import ContainerTankPage from './pages/containers/ContainerTank/ContainerTankPage';
import MechanicsPage from './pages/mechanics/MechanicList/MechanicsPage';
import MechanicEditPage from './pages/mechanics/MechanicEdit/MechanicEditPage';
import UsersPage from './pages/users/UsersList/UsersPage';
import UserEditPage from './pages/users/UserEdit/UserEditPage';
import TanksPage from './pages/tanks/TanksList/TanksPage';
import TanksEditPage from './pages/tanks/TankEdit/TankEditPage';
import StationsPage from './pages/stations/StationList/StationsPage';
import StationEditPage from './pages/stations/StationEdit/StationEditPage';
import VehiclesPage from './pages/vehicles/VehicleList/VehiclesPage';
import VehicleEditPage from './pages/vehicles/VehicleEdit/VehicleEditPage';
import HomePage from './pages/home/HomePage';
import MessagesPage from './pages/messages/MessagesPage';
import UserPwdPage from './pages/users/UserPwd/UserPwdPage';
import SystemPage from './pages/system/SystemPage';
import OrdersPage from './pages/orders/OrderList/OrdersPage';
import OrderEditPage from './pages/orders/OrderEdit/OrderEditPage';
import DmsPage from './pages/dms/DmsPage';

// See React Router documentation for details: https://reacttraining.com/react-router/web/api/Route
const pageList = [
  {
    name: 'Strona główna',
    path: '/home',
    component: HomePage,
  },
  {
    name: "Płyny",
    path: "/liquids",
    component: LiquidsPage
  },
  {
    name: "Edycja Płynu",
    path: "/liquid/:id",
    component: ManageLiquidPage
  },
  {
    name: "Nowy Płyn",
    path: "/liquid",
    component: ManageLiquidPage
  },
  {
    name: "Zbiorniki",
    path: "/containers",
    component: ContainersPage
  },
  {
    name: "Szczegóły zbiornika",
    path: "/container/view/:id",
    component: ContainerDetailsPage
  },
  {
    name: "Zbiornik",
    path: "/container/edit/:id",
    component: ManageContainerPage
  },
  {
    name: "Dolewanie płynu",
    path: "/container/tank/:id",
    component: ContainerTankPage
  },
  {
    name: "Uzupełnienie zbiornika do pełnego stanu",
    path: "/container/refill/:id",
    component: ContainerRefillPage
  },
  {
    name: "Korekta stanu zbiornika",
    path: "/container/correct/:id",
    component: ContainerCorrectPage
  },
  {
    name: "Wymiana płynu",
    path: "/container/liquid/:id",
    component: ContainerLiquidPage
  },
  {
    name: "Edycja Zbiornika",
    path: "/container/:id",
    component: ManageContainerPage
  },
  {
    name: "Nowy Zbiornik",
    path: "/container",
    component: ManageContainerPage
  },
  {
    name: "Mechanicy",
    path: "/mechanics",
    component: MechanicsPage
  },
  {
    name: "Edycja Mechanika",
    path: "/mechanic/:id",
    component: MechanicEditPage
  },
  {
    name: "Nowy Mechanik",
    path: "/mechanic",
    component: MechanicEditPage
  },
  {
    name: "Zmiana hasła",
    path: "/user/pwd/:id",
    component: UserPwdPage
  },
  {
    name: "Użytkownik",
    path: "/user/:mode/:id",
    component: UserEditPage
  },
  {
    name: "Użytkownik",
    path: "/user/:mode",
    component: UserEditPage
  },
  {
    name: "Użytkownicy",
    path: "/users/:mode",
    component: UsersPage
  },
  {
    name: "Dodawanie tankowania",
    path: "/tank",
    component: TanksEditPage
  },
  {
    name: "Tankowania",
    path: "/tanks",
    component: TanksPage
  },
  {
    name: "Edycja punktu poboru",
    path: "/station/:stationNumber",
    component: StationEditPage
  },
  {
    name: "Dodawanie punktu poboru",
    path: "/station",
    component: StationEditPage
  },
  {
    name: "Punkty poboru",
    path: "/stations",
    component: StationsPage
  },
  {
    name: "Pojazdy",
    path: "/vehicles",
    component: VehiclesPage
  },
  {
    name: "Edycja Pojazdu",
    path: "/vehicle/:id",
    component: VehicleEditPage
  },
  {
    name: "Nowy Pojazd",
    path: "/vehicle",
    component: VehicleEditPage
  },
  {
    name: "Komunikaty",
    path: "/messages",
    component: MessagesPage
  },
  {
    name: "Zlecenia",
    path: "/orders",
    component: OrdersPage
  },
  {
    name: "Edycja pozycji",
    path: "/order/:number/:id",
    component: OrderEditPage
  },
  {
    name: "Nowa pozycja",
    path: "/order/:number",
    component: OrderEditPage
  },
  {
    name: "Nowe zlecenie",
    path: "/order",
    component: OrderEditPage
  },
  {
    name: "Zlecenia",
    path: "/dms",
    component: DmsPage
  },
  {
    name: '404',
    path: '/404',
    component: ErrorPage,
  },
  {
    name: 'Ustawienia',
    path: '/system',
    component: SystemPage,
  },
];

export default pageList;
