export const toAmount = number => {
  return number.toFixed(2);
}

export const toDateTime = datetime => {
  return datetime.substring(0, 10) + ' ' + datetime.substring(11, 16)
}

export const dateToString = date => {
  return date.format('DD-MM-YYYY');
}


export const isAdmin = () => {
  let localStore = require('store');
  let user = localStore.get('user');
  if (!user)
    return false;

  return user.admin;
}
