import { handleResponse, handleError, apiUrl, postHeaders, demo, getHeaders } from './apiUtils';
import * as demoRepo from './demo/repository';
const baseUrl = apiUrl + "/warehouse/tanks/";

export function getTanks(start, end) {
  if (demo)
    return Promise.resolve(demoRepo.getTanks(start, end));

  return fetch(baseUrl + start + "/" + end, {
    method:'GET',
    headers: getHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveTank(tank, vehicleId) {
  return fetch(baseUrl + "vehicle/" + vehicleId, {
    method: "POST",
    headers: postHeaders(),
    body: JSON.stringify(tank)
  })
    .then(handleResponse)
    .catch(handleError);
}
