import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import DeleteButton from '../../../common/DeleteButton'
import { Table } from 'reactstrap';
import GridEditButton from '../../../common/GridEditButton';

const MechanicList = ({
  mechanics,
  onDelete,
  history
}) => {

  return (
    <Table hover>
      <thead>
      <tr style={{ background: "#787d80", color: "white" }}>
        <th>
          Pracownik
        </th>
        <th>
          Pin
        </th>
        <th width={140}/>
      </tr>
      </thead>
      <tbody>
      { mechanics.map(mechanic => {
        return (
          <tr key={mechanic.id}>
            <td>
              <Link to={"/mechanic/" + mechanic.id}>{ mechanic.last_name + " " + mechanic.first_name }</Link>
            </td>
            <td>
              {mechanic.pin_code}
            </td>
            <td>
              <GridEditButton
                id={mechanic.id}
                url={"/mechanic"}
                history={history}
              />
              &nbsp;
              <DeleteButton
                onDelete={onDelete}
                deletingItemId={mechanic.id}
              />
            </td>
          </tr>
        );
      })}
      </tbody>
    </Table>
  )
};

MechanicList.propTypes = {
  mechanics: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default MechanicList;
