import * as types from "./actionTypes"
import * as api from "../../api/mechanicApi"
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function loadMechanicsSuccess(mechanics) {
  return { type: types.LOAD_MECHANICS_SUCCESS, mechanics };
}

export function createMechanicSuccess(mechanic) {
  return { type: types.CREATE_MECHANIC_SUCCESS, mechanic }
}

export function updateMechanicSuccess(mechanic) {
  return { type: types.UPDATE_MECHANIC_SUCCESS, mechanic }
}

export function deletedMechanicSuccess(mechanicId) {
  return { type: types.DELETE_MECHANIC_SUCCESS, mechanicId }
}

export function loadMechanics() {
  return function(dispatch) {
    dispatch(beginApiCall());
    return api
      .getMechanics()
      .then(mechanics => {
        dispatch(loadMechanicsSuccess(mechanics));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveMechanic(mechanic) {
  return dispatch => {
    dispatch(beginApiCall());
    return api
      .saveMechanic(mechanic)
      .then(savedMechanic => {
        mechanic.id
          ? dispatch(updateMechanicSuccess(mechanic))
          : dispatch(createMechanicSuccess(savedMechanic))
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  }
}

export function deleteMechanic(mechanicId) {
  return dispatch => {
    return api
      .deleteMechanic(mechanicId)
      .then(() => {
        dispatch(deletedMechanicSuccess(mechanicId));
      })
  }
}
