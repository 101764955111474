import * as types from "./actionTypes";
import * as api from "../../api/containerStateApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadContainerStatesSuccess(containerStates) {
  return {type: types.LOAD_CONTAINERSTATES_SUCCESS, containerStates};
}

export function loadContainerStates(containerId, start, end) {
  return (dispatch) => {
    dispatch(beginApiCall());
    api
      .getContainerStates(containerId, start, end)
      .then(containerStates => {
        dispatch(loadContainerStatesSuccess(containerStates))
      })
      .catch(() => {
        dispatch(apiCallError());
      })
  };
}
