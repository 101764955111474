import {
  apiUrl,
  getData,
  saveData,
  deleteData,
} from './apiUtils';
import * as demoRepo from './demo/repository';
const baseUrl = apiUrl + "/warehouse/mechanics/";

export function getMechanics() {
  return getData(baseUrl, () => demoRepo.getMechanics());
}

export function saveMechanic(mechanic) {
  return saveData(baseUrl, mechanic, () => demoRepo.saveMechanic(mechanic));
}

export function deleteMechanic(mechanicId) {
  return deleteData(baseUrl, mechanicId, () => demoRepo.deleteMechanic(mechanicId));
}
