import React from 'react';
import { PropTypes } from 'prop-types';
import { Button } from 'reactstrap';

const ToolButton = ({
  handleClick,
  icon,
  color,
  text,
  disabled = false
}) => {
  return <Button
    color={color}
    onClick={handleClick}
    disabled={disabled}
  >
    <i className={"fa fa-" + icon} style={{ paddingRight: 8 }}/>
    {text}
  </Button>
}

ToolButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

export default ToolButton;
