import React, { useState, useEffect } from 'react';
import { Card, CardBody, Input, Label, Table } from 'reactstrap';
import { getOrders } from '../../../api/dmsApi';
const DmsPage = () => {

  const [orders, setOrders] = useState([]);
  const [filterNumber, setFilterNumber] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    getOrders()
      .then(orders =>  {
        setOrders(orders);
        setFilteredOrders(orders);
      })
  }, [])

  const handleFilter = event => {
    const { value } = event.target;
    setFilterNumber(value);
    setFilteredOrders(orders.filter(o => o.number.includes(value)));
  }

  return (
    <Card>
      <CardBody>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Label style={{ width: 160 }}>Numer zlecenia:</Label>
          <Input onChange={handleFilter} value={filterNumber}/>
        </div>
        <hr/>
        <Table>
          <thead>
            <tr style={{ background: "#787d80", color: "white" }}>
              <th width={300}>Numer w Dms</th>
              <th width={300}>Numer</th>
            </tr>
          </thead>
          <tbody>
          { filteredOrders.map(order => {
            return (
              <tr key={order.number}>
                <td>{order.dms_number}</td>
                <td>{order.number}</td>
              </tr>
            );
          })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

export default DmsPage;
