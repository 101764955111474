import React from 'react';
import { Col, Row } from 'reactstrap';
import ContainerChart from '../../../common/ContainerChart';
import { Link } from 'react-router-dom';

const ContainerListChart = ({
  container
}) => {

  const isBelowAlarmLevel = container.liquid_amount <= container.alarm_level;
  const isEmpty = container.liquid_amount === container.min_level;

  return (
    <Link to={"/container/view/" + container.id} style={{ textDecoration: 'none'}}>
      <div style={{margin: 20}}>
      <div style={{display: "flex", flexDirection: "row"}}>
        <h4>
          {container.name}
        </h4>
        <h5 className="text-muted" style={{marginLeft: 10, alignSelf: "center"}}>
          {container.liquid.name}
        </h5>
      </div>
      <ContainerChart container={container} />
      <hr />
      <Row>
        <Col>
          <div className="h6">
            <span className="bg-danger inline-block" style={{width: 10, height: 10, marginRight: 5}}/>
            {container.min_level.toFixed(2)} l
          </div>
        </Col>
          {isBelowAlarmLevel
            ?  (
              !isEmpty &&
                <Col>
                  <div className="h6">
                    <span className="bg-warning inline-block" style={{width: 10, height: 10, marginRight: 5}}/>
                    {container.liquid_amount.toFixed(2)} l
                  </div>
                </Col>
            ) : (
              <>
                <Col>
                  <div className="h6">
                    <span className="bg-warning inline-block" style={{width: 10, height: 10, marginRight: 5}}/>
                    {container.alarm_level.toFixed(2)} l
                  </div>
                </Col>
                <Col>
                  <div className="h6">
                    <span className="bg-primary inline-block" style={{width: 10, height: 10, marginRight: 5}}/>
                    {container.liquid_amount.toFixed(2)} l
                  </div>
                </Col>
              </>
            )}
      </Row>
    </div>
    </Link>
  )
}

export default ContainerListChart;
