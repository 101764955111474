import * as types from "../actions/actionTypes"
import initialState from "./initialState";

export default function orderReducer(state = initialState.orders, action) {
  switch (action.type) {
    case types.LOAD_ORDERS_SUCCESS:
      return action.orders;
    case types.CLOSE_ORDER_SUCCESS:
      return state.filter(order => order.id !== order.liquidId);
    default:
      return state;
  }
}
