import React from 'react';
import { Button, Card, CardBody, FormGroup, Input, Label } from 'reactstrap';
import NumericInput from '../../../common/NumericInput';
import CancelButton from '../../../common/CancelButton';
import { PropTypes } from 'prop-types';

const TankForm = ({
  amount,
  comments,
  onChangeAmount,
  onChangeComments,
  isSaving,
  errors,
  onSave
}) => {

  return (
    <Card>
      <CardBody>
        {errors.onSave && (
          <div className="alert alert-danger" role="alert">
            {errors.onSave}
          </div>
        )}
        <NumericInput
          label="Dolewana ilość"
          name="amount"
          value={amount}
          onChange={onChangeAmount}
          error={errors.amount}
        />
        <FormGroup>
          <Label for="comments">Komentarz</Label>
          <Input
            type="textarea"
            name="text"
            id="comments"
            value={comments}
            onChange={onChangeComments}
            style={{height: 130}}
          />
        </FormGroup>
        <Button
          color="success"
          type="submit"
          disabled={isSaving}
          onClick={onSave}
        >
          <i className="fa fa-check" />&nbsp;
          { isSaving ? "Zapisywanie..." : "Zatwierdź" }
        </Button>
        {' '}
        <CancelButton />
      </CardBody>
    </Card>
  );
}

TankForm.propTypes = {
  amount: PropTypes.number.isRequired,
  comments: PropTypes.string.isRequired,
  onChangeAmount: PropTypes.func.isRequired,
  onChangeComments: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  errors: PropTypes.object
}

export default TankForm;
