import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Header, SidebarNav, Footer, PageContent, Chat, PageAlert, Page } from '../vibe';
import Logo from '../assets/images/master-logo.png';
import avatar1 from '../assets/images/avatar1.png';
import { getNav } from '../_nav';
import routes from '../views';
import ContextProviders from '../vibe/components/utilities/ContextProviders';
import handleKeyAccessibility, { handleClickAccessibility } from '../vibe/helpers/handleTabAccessibility';
import { withRouter } from 'react-router-dom';
import HeaderNav from './HeaderNav';
import * as dashboardActions from '../redux/actions/dashboardAction';
import * as settingsActions from '../redux/actions/settingsAction';
import { connect } from 'react-redux';
import { demo } from '../api/apiUtils';
import { initialize } from '../api/demo/repository';

const MOBILE_SIZE = 992;
const DATA_REFRESH_INTERVAL = 30000;

class DashboardLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sidebarCollapsed: false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      showChat1: false,
    };
  }

  handleLogout = (event) => {
    event.preventDefault();
    this.props.history.push("/");
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: false, isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentDidUpdate(prev) {
    if (this.state.isMobile && prev.location.pathname !== this.props.location.pathname) {
      this.toggleSideCollapse();
    }
  }

  refreshData() {
    this.props.loadDashboard();
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', handleKeyAccessibility);
    document.addEventListener('click', handleClickAccessibility);
    if (demo) {
      initialize();
    }
    this.props.loadSettings();
    this.refreshData();
    this.interval = setInterval(() => this.refreshData(), DATA_REFRESH_INTERVAL);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    clearInterval(this.interval);
  }

  toggleSideCollapse = () => {
    this.setState(prevState => ({ sidebarCollapsed: !prevState.sidebarCollapsed }));
  };

  closeChat = () => {
    this.setState({ showChat1: false });
  };

  isAuthenticated = () => {
    let localStore = require('store');
    const user = localStore.get('user');

    if (!user || !user.authenticated) return false;

    return user.authenticated;
  }

  prepareNav = () => {
    let localStore = require('store');
    const user = localStore.get('user');
    const isAdmin = user ? user.admin : false;
    const menu = getNav(isAdmin, this.props.settings);
    const messageItem = menu.top.find(item => {
      return item.name === "Komunikaty";
    })

    if (!messageItem) return;

    if (this.props.dashboard && this.props.dashboard.messages && this.props.dashboard.messages.length > 0)
      messageItem.badge.text = this.props.dashboard.messages.length;
    else
      messageItem.badge.text = "";
    return menu;
  }

  render() {
    let localStore = require('store');
    const user = localStore.get('user');
    const { sidebarCollapsed } = this.state;
    const sidebarCollapsedClass = sidebarCollapsed ? 'side-menu-collapsed' : '';
    return (
      <ContextProviders>
        <div className={`app ${sidebarCollapsedClass}`}>
          <PageAlert />
          <div className="app-body">
            <SidebarNav
              nav={this.prepareNav()}
              logo={Logo}
              logoText="MASTER"
              isSidebarCollapsed={sidebarCollapsed}
              toggleSidebar={this.toggleSideCollapse}
              {...this.props}
            />
            <Page>
              <Header
                toggleSidebar={this.toggleSideCollapse}
                isSidebarCollapsed={sidebarCollapsed}
                routes={routes}
                {...this.props}
              >
                <HeaderNav onLogout={this.handleLogout}/>
              </Header>
              <PageContent>
                <Switch>
                  {routes.map((page, key) => (
                    <Route path={page.path} component={page.component} key={key} />
                  ))}
                  {this.isAuthenticated()
                    ? <Redirect from="/" to="/home"/>
                    : <Redirect from="/" to="/login"/>
                  }
                </Switch>
              </PageContent>
            </Page>
          </div>
          <Footer>
            <span>Zalogowany użytkownik: <b>{ user && user.full_name ? user.full_name : ""}</b></span>
            <span className="ml-auto hidden-xs">
              Wersja: <b>7.01</b>
            </span>
          </Footer>
          <Chat.Container>
            {this.state.showChat1 && (
              <Chat.ChatBox name="Komunikaty" status="online" image={avatar1} close={this.closeChat} />
            )}
          </Chat.Container>
        </div>
      </ContextProviders>
    );
  }
}

const mapStateToProps = state => {
  return {
    dashboard: state.dashboard,
    settings: state.settings
  }
}

const mapDispatchToProps = {
  loadDashboard: dashboardActions.loadDashboard,
  loadSettings: settingsActions.loadSettings
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardLayout));
