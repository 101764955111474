import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from "prop-types";
import * as liquidActions from '../../../../redux/actions/liquidAction';
import * as mechanicActions from '../../../../redux/actions/mechanicActions';
import * as containerActions from "../../../../redux/actions/containerAction";
import * as orderActions from "../../../../redux/actions/orderActions";
import OrderForm from './OrderForm';
import { Loader } from '../../../../vibe';
import { toast } from 'react-toastify';

const OrderEditPage = ({
  order,
  liquids,
  loadLiquids,
  mechanics,
  loadMechanics,
  containers,
  loadContainers,
  saveOrder,
  saveOrderLine,
  history
}) => {

  const [loading, setLoading] = useState(false);
  const [newOrder, setNewOrder] = useState({...order})
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});

  const isNewOrder = order.number === "";

  useEffect(() => {
    if (liquids.length === 0 || mechanics.length === 0 || containers.length === 0) {
      setLoading(true);
      loadLiquids();
      loadMechanics();
      loadContainers();
      setLoading(false);
    }
  }, []);

  const getInputValue = (type, name, value) => {

    if (type === "number")
      return parseFloat(value);

    if (name === "liquid_id")
      return parseInt(value, 10);
    if (name === "mechanic_id")
      return value === "" ? 0 : parseInt(value, 10);

    return value;
  }

  const handleChange = event => {
    const { name, value, type } = event.target;
    setNewOrder(prevState => ({
      ...prevState,
      [name]: getInputValue(type, name, value)
    }))
  }

  const formIsValid = () => {
    const errors = {};

    if (!newOrder.number)
      errors.number = "Numer jest wymagany";
    if (!newOrder.liquid_id)
      errors.liquid_id = "Płyn jest wymagany";
    if (newOrder.amount <= 0)
      errors.amount = "Ilość musi byc wieksza od zera";

    if (!containers
      .filter(c => c.liquid.id === newOrder.liquid_id)
      .find(c2 => c2.available_amount >= newOrder.amount)){
      errors.amount = "Brak wystarczającej ilości w zbiorniku";
    }

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  const handleSave = event => {
    event.preventDefault();

    if (!formIsValid()) return;

    setSaving(true);
    if (isNewOrder) {
      saveOrder(newOrder)
        .then(() => {
          history.push("/orders")
          toast.success("Zlecenie zostało dodane");
        })
        .catch(error => {
          setSaving(false);
          setErrors({ onSave: error.message });
        })
    }
    else {
      saveOrderLine(newOrder)
        .then(() => {
          history.push("/orders")
          toast.success("Pozycja została dodana do zlecenia");
        })
        .catch(error => {
          setSaving(false);
          setErrors({ onSave: error.message });
        })
    }
  }

  return <>
    {loading
      ? <Loader type="bars"/>
      : <OrderForm
          isNewOrder={isNewOrder}
          order={newOrder}
          liquids={liquids}
          mechanics={mechanics}
          onChange={handleChange}
          onSave={handleSave}
          isSaving={saving}
          errors={errors}
        />
    }
  </>
}

const mapStateToProps = (state, ownProps) => {

  const findOrder = () => {
    const number = ownProps.match.params.number;
    if (!number) return null;
    return state.orders.find(o => o.number === number);
  }

  const findOrderLine = (order) => {
    const lineId = ownProps.match.params.id;
    if (!lineId) return null;
    return order.order_lines.find(l => l.id === parseInt(lineId, 10));
  }

  const getFilteredLiquids = order => {
    let takenLiquids = order.order_lines.map(ol => {
      return ol.liquid.id;
    });
    return state.liquids.filter(l => !takenLiquids.includes(l.id));
  }

  const prepareData = () => {
    let order = findOrder();
    if (order) {
      let result = {
        number: order.number,
        liquid_id: 0,
        mechanic_id: 0,
        create_user_id: 0,
        amount: 0.0,
        order_line_id: 0
      };
      let liquids = getFilteredLiquids(order)
      let line = findOrderLine(order);
      if (line) {
        liquids.push(state.liquids.find(liquid => liquid.id === line.liquid.id));
        result.liquid_id = line.liquid.id;
        result.mechanic_id = line.mechanic.id;
        result.amount = parseFloat(line.amount);
        result.order_line_id = line.id;
      }
      return {
        order: result,
        liquids: liquids,
        mechanics: state.mechanics,
        containers: state.containers
      }
    } else
      return {
        order: {
          number: "",
          liquid_id: 0,
          mechanic_id: 0,
          create_user_id: 0,
          amount: 0.0,
          order_line_id: 0
        },
        liquids: state.liquids,
        mechanics: state.mechanics,
        containers: state.containers
      }
  }

  return prepareData();
}

const mapDispatchToProps = {
  loadLiquids: liquidActions.loadLiquids,
  loadMechanics: mechanicActions.loadMechanics,
  loadContainers: containerActions.loadContainers,
  saveOrder: orderActions.saveOrder,
  saveOrderLine: orderActions.saveOrderLine
}

OrderEditPage.propTypes = {
  order: PropTypes.object.isRequired,
  liquids: PropTypes.array.isRequired,
  loadLiquids: PropTypes.func.isRequired,
  mechanics: PropTypes.array.isRequired,
  loadMechanics: PropTypes.func.isRequired,
  containers: PropTypes.array.isRequired,
  loadContainers: PropTypes.func.isRequired,
  saveOrder: PropTypes.func.isRequired,
  saveOrderLine: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderEditPage);
