import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { PropTypes } from 'prop-types';
import DateRangeSelect from '../../../common/DateRangeSelect';

const TanksFilter = ({
  initStart,
  initEnd,
  onFilterChanged,
  onShowTrace,
  showTrace
}) => {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <DateRangeSelect
              initStart={initStart}
              initEnd={initEnd}
              onRangeChanged={onFilterChanged}
            />
          </Col>
          <Col>
            <div className="pull-right">
              {!showTrace ? (
                <Button
                  color="primary"
                  outline
                  onClick={onShowTrace}
                >
                  Pokaż wykresy
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={onShowTrace}
                >
                  Ukryj wykresy
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

TanksFilter.propTypes = {
  initStart: PropTypes.object.isRequired,
  initEnd: PropTypes.object.isRequired,
  onFilterChanged: PropTypes.func.isRequired,
  onShowTrace: PropTypes.func.isRequired,
  showTrace: PropTypes.bool.isRequired
}

export default TanksFilter;
