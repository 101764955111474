import React from 'react';
import TextInput from '../../../common/TextInput';
import { PropTypes } from 'prop-types';
import { Card, CardBody, Col, Form, Row } from 'reactstrap';
import FormButtons from '../../../common/FormButtons';
import CardCodeScanner from '../../../common/CardCodeScanner';

const MechanicForm = ({
  mechanic,
  onChange,
  onSave,
  isSaving,
  cardScanning,
  rfidEnabled,
  onCardAssign,
  onCancelCardAssign,
  errors = {}
}) => {

  const handleSubmit = (e) => {
    e.preventDefault();
    onCancelCardAssign();
  }

  return (
    <>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <Card>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                {errors.onSave && (
                  <div className="alert alert-danger" role="alert">
                    {errors.onSave}
                  </div>
                )}
                <TextInput
                  autoFocus={true}
                  name="first_name"
                  label="Imie"
                  value={mechanic.first_name}
                  onChange={onChange}
                  error={errors.first_name}
                  disabled={cardScanning}
                />
                <TextInput
                  name="last_name"
                  label="Nazwisko"
                  value={mechanic.last_name}
                  onChange={onChange}
                  error={errors.last_name}
                  disabled={cardScanning}
                />
                <TextInput
                  name="pin_code"
                  label="Pin"
                  value={mechanic.pin_code}
                  onChange={onChange}
                  error={errors.pin_code}
                  disabled={cardScanning}
                />
                {rfidEnabled
                  ? <>
                      <TextInput
                        name="rfid_code"
                        label="Numer karty"
                        value={mechanic.rfid_code}
                        onChange={onChange}
                        error={errors.rfid_code}
                        disabled={true}
                      />
                      <CardCodeScanner
                        onCodeChange={onChange}
                        onCancel={onCancelCardAssign}
                        onAssign={onCardAssign}
                        codeValue={mechanic.rfid_code}
                        codeFieldName="rfid_code"
                        scanning={cardScanning}
                      />
                    </>
                  : <></>
                }
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <FormButtons
            onSave={onSave}
            isSaving={isSaving}
            disabled={cardScanning}
          />
        </Col>
      </Row>
    </>
  );
}

MechanicForm.propTypes = {
  mechanic: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  cardScanning: PropTypes.bool.isRequired,
  onCardAssign: PropTypes.func.isRequired,
  onCancelCardAssign: PropTypes.func.isRequired,
  errors: PropTypes.object
}

export default MechanicForm;
