import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import * as actions from '../../../../redux/actions/vehicleAction';
import { Button, Card, CardBody } from 'reactstrap';
import { VehicleList } from './VehicleList';
import { toast } from 'react-toastify';
import VehicleTools from './VehicleTools';

const VehiclesPage = ({
  vehicles,
  loadVehicles,
  deleteVehicle,
  history
}) => {

  useEffect(() => {
    loadVehicles();
  }, [])

  function handleDelete(vehicleId) {
    return deleteVehicle(vehicleId)
      .then(() => toast.success("Pojazd został usunięty"))
  }

  return (
    <>
      <VehicleTools history={history}/>
      <Card>
        <CardBody>
          <VehicleList
            vehicles={vehicles}
            onDelete={handleDelete}
          />
        </CardBody>
      </Card>
    </>
  );
}

VehiclesPage.propTypes = {
  vehicles: PropTypes.array.isRequired,
  loadVehicles: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  vehicles: state.vehicles
})

const mapDispatchToProps = {
  loadVehicles: actions.loadVehicles,
  deleteVehicle: actions.deleteVehicle
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehiclesPage)
