import * as types from './actionTypes';
import { apiCallError, beginApiCall } from './apiStatusActions';
import * as api from '../../api/messageApi';

export function loadMessagesSuccess(messages) {
  return { type: types.LOAD_MESSAGES_SUCCESS, messages };
}

export function receiveAllMessagesSuccess(messages) {
  return { type: types.RECEIVE_ALL_MESSAGES_SUCCESS, messages };
}

export function receiveMessageSuccess(messageId) {
  return { type: types.RECEIVE_MESSAGE_SUCCESS, messageId };
}

export function receiveAllDashboardMessagesSuccess() {
  return { type: types.RECEIVE_ALL_DASHBOARD_MESSAGES_SUCCESS };
}

export function receiveDashboardMessageSuccess(messageId) {
  return { type: types.RECEIVE_DASHBOARD_MESSAGE_SUCCESS, messageId };
}


export function loadMessages(start, end) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return api
      .getMessages(start, end)
      .then(messages => {
        dispatch(loadMessagesSuccess(messages));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function receiveAllMessages() {
  return dispatch => {
    dispatch(beginApiCall());
    return api
      .receiveAllMessages()
      .then(() => {
        dispatch(receiveAllMessagesSuccess())
        dispatch(receiveAllDashboardMessagesSuccess());
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  }
}

export function receiveMessage(messageId) {
  return dispatch => {
    dispatch(beginApiCall());
    return api
      .receiveMessage(messageId)
      .then(() => {
        dispatch(receiveMessageSuccess(messageId))
        dispatch(receiveDashboardMessageSuccess(messageId))
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  }
}

