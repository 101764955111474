import { handleResponse, handleError, apiUrl, postHeaders, getData } from './apiUtils';
const baseUrl = apiUrl + "/admin/settings/";

export function getSettings() {
  return getData(baseUrl, () => { return { order_mgmt: 1} } );
}

export function saveSettings(settings) {
  return fetch(baseUrl, {
    method: "POST",
    headers: postHeaders(),
    body: JSON.stringify(settings)
  })
    .then(handleResponse)
    .catch(handleError);
}
