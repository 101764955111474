import React from "react";
import { PropTypes } from "prop-types";
import SelectInput from "../../../common/SelectInput";
import TextInput from "../../../common/TextInput";
import { Card, CardBody, Col, Form, Row } from 'reactstrap';
import NumericInput from "../../../common/NumericInput";
import FormButtons from '../../../common/FormButtons';

const ContainerForm = ({
  container,
  liquids,
  onChange,
  onSave,
  isSaving,
  isEditMode,
  errors = {}
}) => {
  return (
    <>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <Card>
            <CardBody>
              <Form>
                {errors.onSave && (
                  <div className="alert alert-danger" role="alert">
                    {errors.onSave}
                  </div>
                )}
                { !isEditMode && (
                  <SelectInput
                    name="liquid_id"
                    label="Płyn"
                    value={container.liquid_id || ""}
                    options={liquids.map(liquid => ({
                      value: liquid.id,
                      text: liquid.name
                    }))}
                    onChange={onChange}
                    error={errors.liquid_id}
                  />
                )}
                <TextInput
                  label="Nazwa"
                  name="name"
                  value={container.name}
                  onChange={onChange}
                  error={errors.name}
                />
                <NumericInput
                  label="Pojemność"
                  name="capacity"
                  value={container.capacity}
                  onChange={onChange}
                  error={errors.capacity}
                />
                { !isEditMode && (
                  <NumericInput
                    label="Ilość"
                    name="liquid_amount"
                    value={container.liquid_amount}
                    onChange={onChange}
                    error={errors.liquid_amount}
                  />
                )}
                <NumericInput
                  label="Poziom alarmowy"
                  name="alarm_level"
                  value={container.alarm_level}
                  onChange={onChange}
                  error={errors.alarm_level}
                />
                <NumericInput
                  label="Minimalny poziom"
                  name="min_level"
                  value={container.min_level}
                  onChange={onChange}
                  error={errors.min_level}
                />
              </Form>
            </CardBody>
          </Card>
          <FormButtons
            onSave={onSave}
            isSaving={isSaving}
          />
        </Col>
      </Row>
    </>
  )
}

ContainerForm.propTypes = {
  container: PropTypes.object.isRequired,
  liquids: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  errors: PropTypes.object
}

export default ContainerForm;
