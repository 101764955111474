import React from "react";
import { PropTypes } from "prop-types";
import { Card, CardBody, Col, Form, Row } from 'reactstrap';
import FormButtons from '../../../common/FormButtons';
import TextInput from '../../../common/TextInput';
import SelectInput from '../../../common/SelectInput';
import NumericInput from '../../../common/NumericInput';

const OrderForm = ({
  isNewOrder,
  order,
  liquids,
  mechanics,
  onChange,
  onSave,
  isSaving,
  errors = {}
}) => {
  return (
    <>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <Card>
            <CardBody>
              <Form>
                {errors.onSave && (
                  <div className="alert alert-danger" role="alert">
                    {errors.onSave}
                  </div>
                )}
                <TextInput
                  label="Numer"
                  name="number"
                  value={order.number}
                  onChange={onChange}
                  error={errors.number}
                  disabled={!isNewOrder}
                />
                <SelectInput
                  name="liquid_id"
                  label="Płyn"
                  value={order.liquid_id || ""}
                  options={liquids.map(liquid => ({
                    value: liquid.id,
                    text: liquid.name
                  }))}
                  onChange={onChange}
                  error={errors.liquid_id}
                />
                <SelectInput
                  name="mechanic_id"
                  label="Mechanik"
                  defaultOption="<dowolny>"
                  useEmptyValue={true}
                  value={order.mechanic_id || ""}
                  options={mechanics.map(mech => ({
                    value: mech.id,
                    text: mech.first_name + " " + mech.last_name
                  }))}
                  onChange={onChange}
                  error={errors.mechanic_id}
                />
                <NumericInput
                  label="Ilość"
                  name="amount"
                  value={order.amount}
                  onChange={onChange}
                  error={errors.amount}
                />
              </Form>
            </CardBody>
          </Card>
          <FormButtons
            onSave={onSave}
            isSaving={isSaving}
          />
        </Col>
      </Row>
    </>
  );
}

OrderForm.propTypes = {
  isNewOrder: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired,
  liquids: PropTypes.array.isRequired,
  mechanics: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  errors: PropTypes.object
}

export default OrderForm;

