import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import { Table } from 'reactstrap';
import DeleteButton from "../../../common/DeleteButton";
import { withRouter } from 'react-router-dom';
import GridEditButton from '../../../common/GridEditButton';

const LiquidList = ({
  liquids,
  onDelete,
  history
}) => {

  return (
    <Table hover>
      <thead>
        <tr style={{ background: "#787d80", color: "white" }}>
          <th>Nazwa</th>
          <th width={140}/>
        </tr>
      </thead>
      <tbody>
        { liquids.map(liquid => {
          return (
            <tr key={liquid.id}>
              <td>
                <Link to={"/liquid/" + liquid.id}>{liquid.name}</Link>
              </td>
              <td>
                <GridEditButton
                  id={liquid.id}
                  url={"/liquid"}
                  history={history}
                />
                &nbsp;
                <DeleteButton
                  onDelete={onDelete}
                  deletingItemId={liquid.id}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  )
};

LiquidList.propTypes = {
  liquids: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(LiquidList);
