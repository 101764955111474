import { combineReducers } from "redux";
import liquids from "./liquidReducer";
import containers from "./containerReducer";
import containerStates from "./containerStatesReducer";
import apiCallsInProgress from "./apiStatusReducer";
import mechanics from "./mechanicReducer";
import users from "./userReducer";
import stations from './stationReducer';
import tanks from './tankReducer';
import settings from './settingsReducer';
import vehicles from './vehicleReducer';
import messages from './messageReducer';
import dashboard from './dashboardReducer';
import orders from './orderReducer';

const rootReducer = combineReducers({
  liquids,
  containers,
  containerStates,
  mechanics,
  users,
  stations,
  tanks,
  vehicles,
  messages,
  dashboard,
  settings,
  orders,
  apiCallsInProgress
});

export default rootReducer;
