import React from 'react';
import { PropTypes } from 'prop-types';
import ContainerChart from '../../../common/ContainerChart';

const ContainerHomeChart = ({
  container
}) => {
  return (
    <div style={{
      marginBottom: 20
    }}>
      <div style={{display: "flex", flexDirection: "row"}}>
        <h5>{container.name}</h5>
        <h6 className="text-muted" style={{marginLeft: 10, alignSelf: "center"}}>{container.liquid.name}</h6>
      </div>
      <ContainerChart container={container} />
    </div>
  );
}

ContainerHomeChart.propTypes = {
  container: PropTypes.object.isRequired
}

export default ContainerHomeChart;
