import { handleResponse, handleError, apiUrl, getHeaders, postHeaders } from './apiUtils';
const baseUrl = apiUrl + "/admin/distributors/";

export function getStations() {
  return fetch(baseUrl, {
    method:'GET',
    headers: getHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveStation(station) {
  return fetch(baseUrl + (station.id || ""), {
    method: station.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
    headers: postHeaders(),
    body: JSON.stringify(station)
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteStation(stationId) {
  return fetch(baseUrl + stationId, {
    method: "DELETE",
    headers: getHeaders()
  })
    .then(handleResponse)
    .catch(handleError);
}
