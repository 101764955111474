import React from 'react';
import { Button } from 'reactstrap';
import * as html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';

export const ExportToPdf = ({
  htmlElement,
  fileName
}) => {

  const handlePdf = (htmlElement, filename) => {
    const element = document.getElementById(htmlElement);

    html2canvas(element)
      .then((canvas) => {
        let wid = 0;
        let hgt = 0;
        let img = canvas.toDataURL("image/png", wid = canvas.width, hgt = canvas.height);
        let hratio = hgt/wid
        let doc = new jsPDF('p','pt','a4');
        let width = doc.internal.pageSize.width - 40;
        let height = width * hratio
        doc.addImage(img,'JPEG',20,20, width, height);
        doc.save(filename);
      });
  };

  return (
    <Button
      color="danger"
      outline
      onClick={() => handlePdf(htmlElement, fileName)}
      style={{ width: 100, marginRight: 10 }}
    >
      PDF
    </Button>
  )
}
