import * as types from "../actions/actionTypes"
import initialState from "./initialState";
import update from 'immutability-helper';

export default function liquidReducer(state = initialState.stations, action) {
  switch (action.type) {
    case types.LOAD_STATIONS_SUCCESS:
      return action.stations;
    case types.CREATE_STATION_SUCCESS:
      return [...state, {...action.station}];
    case types.UPDATE_STATION_SUCCESS:
      return state.map(station => {
        return station.id === action.station.id
          ? action.station
          : station
      })
    case types.DELETE_STATION_SUCCESS:
      return state.filter(station => station.number !== action.stationNumber);
    case types.ADD_PUMP:
      const st2 = update(state.find(s => s.number === action.stationNumber), {
        pumps: {$push: [action.pump]}
      });
      return state.map(station => {
        return station.number === st2.number
          ? st2
          : station
      })
    case types.UPDATE_PUMP:
      const st3 = {...state.find(s => s.number === action.stationNumber)};
      const tmpPumps = [...st3.pumps];
      st3.pumps = tmpPumps.map(pump => {
        return pump.number === action.pump.number
          ? action.pump
          : pump
      })
      return state.map(station => {
        return station.number === st3.number
          ? st3
          : station
      });
    default:
      return state;
  }
}
