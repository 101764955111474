import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import * as actions from "../../../../redux/actions/mechanicActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MechanicList from "./MechanicList";
import { Card, CardBody } from 'reactstrap';
import MechanicTools from './MechanicTools';

const MechanicsPage = ({
  mechanics,
  loadMechanics,
  deleteMechanic,
  history
}) => {

  useEffect(() => {
    loadMechanics();
  }, [])

  function handleDelete(liquidId) {
    return deleteMechanic(liquidId)
      .then(() => toast.success("Mechanik został usunięty"))
  }

  return (
    <>
      <MechanicTools />
      <Card>
        <CardBody>
          <MechanicList
            mechanics={mechanics}
            onDelete={handleDelete}
            history={history}
          />
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = state => ({
  mechanics: state.mechanics
})

const mapDispatchToProps = {
  loadMechanics: actions.loadMechanics,
  deleteMechanic: actions.deleteMechanic
}

MechanicsPage.propTypes = {
  mechanics: PropTypes.array.isRequired,
  loadMechanics: PropTypes.func.isRequired,
  deleteMechanic: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MechanicsPage);
