import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/userAction';
import { newUser } from '../../../../redux/reducers/initialState';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { Loader } from '../../../../vibe';
import UserForm from "./UserForm";

const UserEditPage =
({
  users,
  loadUsers,
  saveUser,
  history,
  match,
  ...props
}) => {

  const isEditMode = !!match.params.id;

  const [user, setUser] = useState({...props.user});
  const [errors, setErrors] = useState({})
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (users.length === 0) {
      setLoading(true);
      loadUsers();
      setLoading(false);
    }
    else {
      setUser({...props.user});
    }
  }, [props.user]);

  function handleChange(event) {
    const { name, value } = event.target;
    if (name === "pin_code" && !"0123456789".includes(value.slice(-1))) return;
    setUser(prevMec => ({
      ...prevMec,
      [name]: value
    }));
  }

  function formIsValid(errors) {
    if (!user.last_name)
      errors.last_name = "Nazwisko jest wymagane";
    if (!user.first_name)
      errors.first_name = "Imię jest wymagane";
    if(user.pin_code !== "" && users.find(m => m.pin_code === user.pin_code && m.id !== user.id))
      errors.pin_code = "Kod pin musi być unikatowy";
    if (!user.username)
      errors.username = "Login jest wymagany";
    if(users.find(m => m.username === user.username && m.id !== user.id))
      errors.username = "Login nie może się powtarzać";
    if (user.password.length  < 6)
      errors.password = "Hasło musi składać się przynajmniej z 6 znaków";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function handleSave(event, errors) {
    if (!formIsValid(errors)) return;
    setIsSaving(true);
    user.role = match.params.mode === "admin" ? "Admin" : "Warehouse";
    saveUser(user)
      .then(() => {
        history.push("/users/" + match.params.mode)
        toast.success("Dane o użytkowniku zostały zapisane.");
      })
      .catch(error => {
        setIsSaving(false);
        setErrors( { onSave: error.message });
      })
  }

  return (
    <>
      { loading
        ? <Loader type="bars"/>
        : <UserForm
            onSave={handleSave}
            user={user}
            onChange={handleChange}
            isSaving={isSaving}
            errors={errors}
            isEditMode={isEditMode}
        />
      }
    </>
  );
}

function getUserById(users, userId) {
  return users.find(user => user.id === userId) || null;
}

function mapStateToProps(state, ownProps) {
  const userId = ownProps.match.params.id;
  const user =
    userId && state.users.length > 0
      ? getUserById(state.users, parseInt(userId))
      : newUser;
  return {
    user,
    users: state.users
  }
}

const mapDispatchToProps = {
  loadUsers: actions.loadUsers,
  saveUser: actions.saveUser
}

UserEditPage.propTypes = {
  users: PropTypes.array.isRequired,
  loadUsers: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserEditPage);
