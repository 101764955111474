import React from 'react';
import { Table } from 'reactstrap';
import { PropTypes } from 'prop-types';
import TdDate from '../../../common/TdDate';
import TdAmount from '../../../common/TdAmount';
import TraceChart from './TraceChart';

const TanksMainList = ({
  tanks,
  showTankTrace
}) => {
  return (
    <>
      <Table hover>
        <thead>
          <tr style={{ background: "#787d80", color: "white" }}>
            <th width={170}>Data</th>
            <th>Pojazd</th>
            <th>Płyn</th>
            <th>Mechanik</th>
            <th>Ilość</th>
          </tr>
        </thead>
        <tbody>
        {tanks.map(tank => {
          return (
            <React.Fragment key={tank.id}>
              <tr>
                <TdDate date={tank.date} />
                <td>{tank.order_line.order.number}</td>
                <td>{tank.order_line.liquid.name}</td>
                <td>{tank.order_line.mechanic.first_name + ' ' + tank.order_line.mechanic.last_name}</td>
                <TdAmount amount={tank.amount} />
              </tr>
              { showTankTrace &&
                <TraceChart trace={tank.trace} />
              }
            </React.Fragment>
        )})}
        </tbody>
      </Table>
    </>
  );
}

TanksMainList.propTypes = {
  tanks: PropTypes.array.isRequired,
  showTankTrace: PropTypes.bool.isRequired
}

export default TanksMainList;
