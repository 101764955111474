import { handleResponse, handleError, apiUrl, getHeaders, postHeaders, demo, getData } from './apiUtils';
import * as demoRepo from './demo/repository';
const baseUrl = apiUrl + "/warehouse/container_states/";

export function getContainerStates(containerId, start, end) {
  return getData(
    baseUrl + containerId + "/" + start + "/" + end,
    () => demoRepo.getContainerStates(containerId, start, end)
  );
}

export function postContainerStates(containerId, data) {
  if (demo)
    return Promise.resolve(demoRepo.addContainerState(containerId, data));

  return fetch(baseUrl + containerId, {
    method: "POST",
    headers: postHeaders(),
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .catch(handleError);
}
