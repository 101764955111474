import React from 'react';
import { Card, CardHeader, CardBody, Table } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { toAmount, toDateTime } from '../../../../common/utils';

const TanksCard = ({
  tanks
}) => {
  return (
    <Card>
      <CardHeader>
        <h4><Link to={"/tanks/"}>Ostatnie tankowania</Link></h4>
        <hr />
      </CardHeader>
      <CardBody>
        <div style={{
          marginLeft: 40,
          marginRight: 40
        }}>
          <Table className="table">
            <thead>
            <tr style={{ background: "#787d80", color: "white" }}>
              <th>Data</th>
              <th>Pojazd</th>
              <th>Płyn</th>
              <th>Mechanik</th>
              <th>Ilość wydana</th>
            </tr>
            </thead>
            <tbody>
            {tanks && tanks.map(tank => {
              return (
                <tr key={ tank.id }>
                  <td>{ toDateTime(tank.date) }</td>
                  <td>{ tank.order_line.order.number }</td>
                  <td>{ tank.order_line.liquid.name }</td>
                  <td>{ tank.order_line.mechanic.first_name + ' ' + tank.order_line.mechanic.last_name }</td>
                  <td>{ toAmount(tank.amount) }</td>
                </tr>
              )})}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
}

TanksCard.propTypes = {
  tanks: PropTypes.array
}

export default TanksCard;
