import React from 'react';
import { Button, Card, CardBody, FormGroup, Input, Label } from 'reactstrap';
import CancelButton from '../../../common/CancelButton';
import { PropTypes } from 'prop-types';
import SelectInput from '../../../common/SelectInput';

const ChangeLiquidForm = ({
  liquids,
  liquidId,
  comments,
  onChangeLiquid,
  onChangeComments,
  isSaving,
  errors,
  onSave
}) => {

  return (
    <Card>
      <CardBody>
        {errors.onSave && (
          <div className="alert alert-danger" role="alert">
            {errors.onSave}
          </div>
        )}
        <SelectInput
          name="liquidId"
          label="Nowy płyn"
          value={liquidId || ""}
          options={liquids.map(liquid => ({
            value: liquid.id,
            text: liquid.name
          }))}
          onChange={onChangeLiquid}
          error={errors.liquidId}
        />
        <FormGroup>
          <Label for="comments">Komentarz</Label>
          <Input
            type="textarea"
            name="text"
            id="comments"
            value={comments}
            onChange={onChangeComments}
            style={{height: 100}}
          />
        </FormGroup>
        <Button
          color="success"
          type="submit"
          disabled={isSaving}
          onClick={onSave}
        >
          <i className="fa fa-check" />&nbsp;
          { isSaving ? "Zapisywanie..." : "Zatwierdź" }
        </Button>
        {' '}
        <CancelButton />
      </CardBody>
    </Card>
  );
}

ChangeLiquidForm.propTypes = {
  liquids: PropTypes.array.isRequired,
  liquidId: PropTypes.number,
  comments: PropTypes.string.isRequired,
  onChangeLiquid: PropTypes.func.isRequired,
  onChangeComments: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  errors: PropTypes.object
}

export default ChangeLiquidForm;
