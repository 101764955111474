import React from "react";
import { PropTypes } from "prop-types";
import DeleteButton from "../../../common/DeleteButton";
import { withRouter } from "react-router-dom";
import { Table } from 'reactstrap';
import ContainerListChart from './ContainerListChart';
import GridEditButton from '../../../common/GridEditButton';

const ContainerList = ({
  containers,
  onDelete,
  history,
  admin
}) => {
  return (
    <Table hover>
      <thead>
        <tr style={{ background: "#787d80", color: "white" }}>
          <th>Zbiornik</th>
          <th style={{ width: 140 }}/>
        </tr>
      </thead>
      <tbody>
        {containers.map(container => {
        return (
          <tr key={container.id}>
            <td>
              <div style={{margin: 20}}>
                <ContainerListChart container={container}/>
              </div>
            </td>
            <td>
              <GridEditButton
                id={container.id}
                url={"/container/view"}
                history={history}
              />
              &nbsp;
              <DeleteButton
                onDelete={onDelete}
                deletingItemId={container.id}
              />
            </td>
          </tr>
        )
      })}
      </tbody>
    </Table>
  )
}

ContainerList.propTypes = {
  containers: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired
};

export default  withRouter(ContainerList);
