import React from 'react';
import { Progress } from 'reactstrap';
import { PropTypes } from 'prop-types';

const ContainerChart = ({
  container,
  tankAmount = 0,
}) => {

  const isBelowAlarmLevel = container.liquid_amount <= container.alarm_level;
  const maxTankAmount = container.capacity - container.liquid_amount;
  const isEmpty = container.liquid_amount === container.min_level;

  return (
    <Progress multi>
      {isEmpty
        ? <Progress bar animated color="danger" value={container.min_level} max={container.capacity}/>
        : <Progress bar color="danger" value={container.min_level} max={container.capacity}/>
      }

      {isBelowAlarmLevel
        ? <Progress bar animated color="warning" value={container.liquid_amount - container.min_level} max={container.capacity}/>
        : (
          <>
            <Progress bar color="warning" value={container.alarm_level - container.min_level} max={container.capacity}/>
            <Progress bar color="primary" value={container.liquid_amount - container.alarm_level} max={container.capacity}/>
          </>
        )
      }
      {tankAmount <= maxTankAmount
        ? <Progress bar animated color="success" value={tankAmount} max={container.capacity}/>
        : <Progress bar animated color="danger" value={maxTankAmount} max={container.capacity}/>
      }
    </Progress>
  )
}

ContainerChart.propTypes = {
  container: PropTypes.object.isRequired,
  tankAmount: PropTypes.number
}

export default ContainerChart;
