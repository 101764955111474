import React from "react"
import PropTypes from "prop-types";
import { FormGroup, Label, Input } from 'reactstrap';

const NumericInput = ({
  name,
  label, 
  onChange,
  value,
  error,
  disabled = false,
  isInt = false,
}) => {

  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  function handleChange(event) {
    const numbers = event.target.value.split(".");
    if (numbers.length > 1)
      if(numbers[1].length > 2) return;
    if (!event.target.value) event.target.value = "0"
    if (event.target.value !== "0" && event.target.value[0] === "0")
      event.target.value = event.target.value.substr(1);
    onChange(event);
  }

  return (
    <FormGroup className={wrapperClass}>
      <Label for={name}>{label}</Label>
      <div>
        <Input
          type="number"
          name={name}
          className="form-control"
          value={value}
          onChange={handleChange}
          step={isInt ? "1" : "0.01"}
          disabled={disabled}
        />
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </FormGroup>
  );
}

NumericInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  isInt: PropTypes.bool
};

export default NumericInput;
