export const getNav = (admin, settings) => {
  const users = [
    {
      name: 'Magazynierzy',
      url: '/users/warehouse'
    },
    {
      name: 'Mechanicy',
      url: '/mechanics'
    }
  ];

  let mainMenu = [
    {
      divider: true,
    },
    {
      name: 'Strona Główna',
      url: '/home',
      icon: 'Home',
    },
    {
      divider: true,
    },
    {
      name: 'Tankowania',
      icon: 'Filter',
      url: '/tanks'
    },
    {
      name: 'Zbiorniki',
      url: '/containers',
      icon: 'Database'
    },
    {
      name: 'Komunikaty',
      url: '/messages',
      icon: 'Mail',
      badge: {
        variant: 'success',
        text: '3',
      },
    }
  ];

  if (settings.order_mgmt)
    mainMenu.push({
      name: 'Zlecenia',
      url: '/orders',
      icon: 'Clipboard'
    });

  if (settings.system_dms_enabled && settings.system_dms_status)
    mainMenu.push({
      name: 'Zlecenia',
      url: '/dms',
      icon: 'Clipboard'
    });

  mainMenu = [...mainMenu,
    {
      divider: true,
    },
    {
      name: 'Użytkownicy',
      icon: 'Users',
      children: users
    },
    {
      name: 'Płyny',
      url: '/liquids',
      icon: 'Droplet'
    }
  ]

  if (settings.vehicles_enabled)
    mainMenu.push({
      name: 'Pojazdy',
      url: '/vehicles',
      icon: 'Truck'
    });

  if (admin) {
    users.push({
      name: 'Administratorzy',
      url: '/users/admin'
    });
    mainMenu.push({
      divider: true,
    })
    mainMenu.push({
        name: 'Ustawienia',
        icon: 'Settings',
        children: [
        {
          name: 'Punkty poboru',
          url: '/stations'
        },
        {
          name: 'System',
          url: '/system'
        }
      ]
    });
  }

  return {
    top: mainMenu,
    bottom: [
      {
        name: 'Informacje',
        url: '/dashboard',
        icon: 'Info',
      },
    ],
  }
};
