import * as types from "../actions/actionTypes"
import initialState from "./initialState";

export default function messageReducer(state = initialState.messages, action) {
  switch (action.type) {
    case types.LOAD_MESSAGES_SUCCESS:
      return action.messages;
    case types.RECEIVE_ALL_MESSAGES_SUCCESS:
      return state.map(message => {
        return {...message, received: 1 }
      })
    case types.RECEIVE_MESSAGE_SUCCESS:
      return state.map(message => {
        return message.id === action.messageId
          ? {...message, received: 1 }
          : message
      })
    default:
      return state;
  }
}
