import React from 'react';
import TextInput from '../../../common/TextInput';
import { PropTypes } from 'prop-types';
import { Form, Card, CardBody, CardHeader } from 'reactstrap';
import NumericInput from '../../../common/NumericInput';

const StationForm = ({
  station,
  onChange,
  errors = {}
}) => {

  return (
    <Card>
      <CardHeader>
        <h4>Szczegóły punktu poboru</h4>
        <hr />
      </CardHeader>
      <CardBody>
        <Form>
          {errors.onSave && (
            <div className="alert alert-danger" role="alert">
              {errors.onSave}
            </div>
          )}
          <NumericInput
            label="Numer"
            onChange={() => {}}
            name="number"
            value={station.number}
            disabled={true}
          />
          <TextInput
            name="name"
            label="Nazwa"
            value={station.name}
            onChange={onChange}
            error={errors.name}
          />
        </Form>
      </CardBody>
    </Card>
  );
}

StationForm.propTypes = {
  station: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
}

export default StationForm;
