import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, Card, CardBody } from 'reactstrap';
import CancelButton from './CancelButton';

const FormButtons = ({
  onSave,
  isSaving,
  disabled
}) => {
  return (
    <Card>
      <CardBody>
        <Button
          color="success"
          type="submit"
          disabled={isSaving || disabled}
          onClick={onSave}
        >
          <i className="fa fa-check" />&nbsp;&nbsp;
          { isSaving ? "Zapisywanie..." : "Zapisz" }
        </Button>
        {' '}
        <CancelButton disabled={disabled}/>
      </CardBody>
    </Card>
  );
}

FormButtons.propTypes = {
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  disabled: PropTypes.bool
}

export default FormButtons;
