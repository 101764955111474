import React, { useEffect } from "react";
import ContainerList from "./ContainerList";
import { connect } from "react-redux";
import * as containerActions from "../../../../redux/actions/containerAction";
import * as liquidActions from "../../../../redux/actions/liquidAction";
import { PropTypes } from "prop-types";
import { toast } from "react-toastify";
import { Card, CardBody } from 'reactstrap';
import ContainerTools from './ContainerTools';
import { isAdmin } from '../../../../common/utils';

const ContainersPage = ({
  containers,
  liquids,
  loadContainers,
  deleteContainer,
  loadLiquids,
  history
}) => {
  
  useEffect(() => {
    loadContainers();
    loadLiquids();
  }, [])

  function handleDelete(containerId) {
    return  deleteContainer(containerId)
      .then(() => toast.success("Zbiornik został usunięty"))
  }

  return (
    <>
      <ContainerTools history={history} />
      <Card>
        <CardBody>
          <ContainerList
            containers={containers}
            liquids={liquids}
            onDelete={handleDelete}
            history={history}
            admin={isAdmin()}
          />
        </CardBody>
      </Card>
    </>
  );
}

ContainersPage.propTypes = {
  containers: PropTypes.array.isRequired,
  liquids: PropTypes.array.isRequired,
  loadContainers: PropTypes.func.isRequired,
  deleteContainer: PropTypes.func.isRequired,
  loadLiquids: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    containers: state.containers,
    liquids: state.liquids
  };
}

const mapDispatchToProps = {
  deleteContainer: containerActions.deleteContainer,
  loadContainers: containerActions.loadContainers,
  loadLiquids: liquidActions.loadLiquids
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainersPage);
