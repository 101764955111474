import React, { useEffect, useState } from "react";
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { newContainer } from '../../../../redux/reducers/initialState';
import * as actions from '../../../../redux/actions/containerAction';
import { Loader } from '../../../../vibe';
import { Row, Col } from 'reactstrap';
import TankForm from './TankForm';
import ContainerChartCard from '../common/ContainerChartCard';
import { toast } from 'react-toastify';
import ContainerInfoCard from '../common/ContainerInfoCard';

const ContainerTankPage = ({
  containers,
  loadContainers,
  tankContainer,
  history,
  ...props
}) => {

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({})

  const [container, setContainer] = useState({...props.container});

  const [tankAmount, setTankAmount] = useState(0);
  const [comments, setComments] = useState('');

  const [newAmount, setNewAmount] = useState(0);

  useEffect(() => {
      if (containers.length === 0) {
        setLoading(true);
        loadContainers()
        setLoading(false);
      }
      else {
        setContainer({...props.container});
        setNewAmount(props.container.liquid_amount);
      }
  }, [props.container])

  const handleChangeAmount = (event) => {
    const { value } = event.target;
    setTankAmount(parseFloat(value));
    setNewAmount(container.liquid_amount + parseFloat(value));
  }

  const handleChangeComments = (event) => {
    const { value } = event.target;
    setComments(value);
  }

  function formIsValid() {
    const errors = {};
    if (newAmount > container.capacity)
      errors.amount = "Dolewana ilość jest zbyt duża";
    if (tankAmount === 0)
      errors.amount = "Dolewana ilość nie może być zerowa";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function handleSave(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    setSaving(true);
    tankContainer(container, tankAmount, comments)
      .then(() => {
        history.goBack();
        toast.success("Dolanie płynu zakończyło się powodzeniem");
      })
      .catch(error => {
        setSaving(false);
        setErrors( { onSave: error.message });
      })
  }

  return (
   loading
    ? <Loader type="bars" />
    : (<>
        <Row>
          <Col md={{ size: 9, offset: 1 }}>
            <ContainerChartCard
              container={container}
              tankAmount={tankAmount}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 5, offset: 1 }}>
            <TankForm
              amount={tankAmount}
              comments={comments}
              onChangeAmount={handleChangeAmount}
              onChangeComments={handleChangeComments}
              isSaving={false}
              onSave={handleSave}
              errors={errors}
            />
          </Col>
          <Col md={4}>
            <ContainerInfoCard
              container={container}
              newAmount={newAmount}
            />
          </Col>
        </Row>
    </>)
  );
}

ContainerTankPage.propTypes = {
  container: PropTypes.object.isRequired,
  containers: PropTypes.array.isRequired,
  loadContainers: PropTypes.func.isRequired,
  tankContainer: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const containerId = ownProps.match.params.id;
  const container = containerId && state.containers.length > 0
    ? state.containers.find(c => c.id === parseInt(containerId))
    : newContainer;
  return {
    container: container,
    containers: state.containers
  }
}

const mapDispatchToProps = {
  loadContainers: actions.loadContainers,
  tankContainer: actions.tankContainer
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContainerTankPage);
