import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import * as messageActions from '../../../redux/actions/messageAction';
import moment from 'moment';
import { dateToString } from '../../../common/utils';
import { Card, CardBody } from 'reactstrap';
import MessageList from './MessageList';
import MessageFilter from './MessageFilter';

const MessagesPage = ({
  messages,
  loadMessages,
  receiveAllMessages,
  receiveMessage
}) => {
  const initStartDate = moment().subtract(6, 'days');
  const initEndDate = moment().add(1, 'days');

  useEffect(() => {
    if (initStartDate && initEndDate) {
      loadMessages(dateToString(initStartDate), dateToString(initEndDate));
    }
  }, [])

  const handleFilterData = (startDate, endDate) => {
    loadMessages(dateToString(startDate), dateToString(endDate));
  }

  const handleReceiveAllMessages = () => {
    receiveAllMessages();
  }

  const handleReceiveMessage = messageId => {
    receiveMessage(messageId);
  }

  return (
    <>
      <MessageFilter
        initStart={initStartDate}
        initEnd={initEndDate}
        onFilterChanged={handleFilterData}
        onReceiveAllMessages={handleReceiveAllMessages}
      />
      <Card>
        <CardBody>
          <MessageList
            messages={messages}
            onReceiveMessage={handleReceiveMessage}
          />
        </CardBody>
      </Card>
    </>
  );
}

MessagesPage.propTypes = {
  messages: PropTypes.array.isRequired,
  loadMessages: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  messages: state.messages
})

const mapDispatchToProps = {
  loadMessages: messageActions.loadMessages,
  receiveAllMessages: messageActions.receiveAllMessages,
  receiveMessage: messageActions.receiveMessage
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesPage);

