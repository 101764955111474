import React from "react"
import { PropTypes } from "prop-types"
import { Button, UncontrolledTooltip } from 'reactstrap';

const GridButton = ({
  name,
  color,
  icon,
  tooltip,
  onClick
}) => {
  return (
    <>
      <Button
        id={name}
        color={color || "primary"}
        onClick={onClick}
        outline
        style={{width: 40}}
      >
        <i className={"fa " + icon}/>
      </Button>
      <UncontrolledTooltip
        placement="top"
        target={name}
      >
        {tooltip}
      </UncontrolledTooltip >
    </>
  );
}

GridButton.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  tooltip: PropTypes.string
}

export default GridButton;
