import React from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { PropTypes } from 'prop-types';
import ContainerHomeChart from './ContainerHomeChart';
import { Link } from 'react-router-dom';

const ContainersCard = ({
  containers
}) => {
  return (
    <Card>
      <CardHeader>
        <h4><Link to={"/containers/"}>Stan zbiorników</Link></h4>
        <hr/>
      </CardHeader>
      <CardBody>
        <div style={{
          marginLeft: 40,
          marginRight: 40
        }}>
          { containers && containers.map(container => {
            return (
              <Link to={"/container/view/" + container.id} key={container.id}  style={{ textDecoration: 'none'}}>
                <ContainerHomeChart
                  container={container}
                />
              </Link>
            )
          })}
          <hr />
          <Row>
            <Col>
              <div className="h6">
                <span className="bg-danger inline-block" style={{width: 10, height: 10, marginRight: 5}}/>
                Minimalny poziom
              </div>
            </Col>
            <Col>
              <div className="h6">
                <span className="bg-warning inline-block" style={{width: 10, height: 10, marginRight: 5}}/>
                Poziom alarmowy
              </div>
            </Col>
            <Col>
              <div className="h6">
                <span className="bg-primary inline-block" style={{width: 10, height: 10, marginRight: 5}}/>
                Aktualna ilość
              </div>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
}

ContainersCard.propTypes = {
  containers: PropTypes.array
}

export default ContainersCard;
