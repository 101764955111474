import React from 'react';
import TextInput from '../../../common/TextInput';
import { PropTypes } from 'prop-types';
import { Form, Col, Card, CardBody, Row } from 'reactstrap';
import FormButtons from '../../../common/FormButtons';

const LiquidForm = ({
  liquid,
  onChange,
  onSave,
  isSaving,
  errors = {}
}) => {

  return (
    <Row>
      <Col md={{ size: 8, offset: 2 }}>
        <Card>
          <CardBody>
            <Form>
              {errors.onSave && (
                <div className="alert alert-danger" role="alert">
                  {errors.onSave}
                </div>
              )}
              <TextInput
                name="name"
                label="Nazwa"
                value={liquid.name}
                onChange={onChange}
                error={errors.name}
              />
            </Form>
          </CardBody>
        </Card>
        <FormButtons
          onSave={onSave}
          isSaving={isSaving}
        />
      </Col>
    </Row>
  );
}

LiquidForm.propTypes = {
  liquid: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  errors: PropTypes.object
}

export default LiquidForm;
